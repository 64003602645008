import _ from 'lodash';
import { loadingCycle } from './page';
import api from '../../utils/service';

const SET_AUTH_LOADING = 'redux/auth/SET_AUTH_LOADING';
const GET_AUTH_SUCCESS = 'redux/auth/GET_AUTH_SUCCESS';
const GET_AUTH_FAIL = 'redux/auth/GET_AUTH_FAIL';
const DELETE_AUTH = 'redux/auth/DELETE_AUTH';

const initialState = {
  loaded: false,
  initialized: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_AUTH_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        initialized: action.isLoading ? true : state.initialized,
      };
    case GET_AUTH_SUCCESS:
      return {
        ...state,
        user: _.get(action.data, 'user'),
        error: null,
        initialized: true,
      };
    case GET_AUTH_FAIL:
      return {
        ...state,
        user: null,
        error: action.error,
        initialized: true,
      };
    case DELETE_AUTH:
      return {
        ...state,
        user: null,
        error: null,
      };
    default:
      return state;
  }
}

export const setAuthLoading = ({ isLoading }) => dispatch =>
  dispatch({
    type: SET_AUTH_LOADING,
    isLoading,
  });

export function saveAuthDataToStore(error, data) {
  return dispatch => {
    if (!error) {
      dispatch(getAuthSuccess(data));
    } else {
      dispatch(getAuthFail(error));
    }
  };
}

export function resetAuthState() {
  return dispatch => {
    dispatch(deleteAuth);
  };
}

export function getAuthSuccess(data) {
  return {
    type: GET_AUTH_SUCCESS,
    data,
  };
}

export function getAuthFail(error) {
  return {
    type: GET_AUTH_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function deleteAuth() {
  return {
    type: DELETE_AUTH,
  };
}

export const updateCurrentUserProfile = (data, SubmissionErrorClass) =>
  loadingCycle(api.patch('/users/profile', data)).catch(error => {
    const data = _.get(error, 'response.data');
    if (!data) throw error;

    if (!data.validationErrors || !SubmissionErrorClass) throw error;

    throw new SubmissionErrorClass(data.validationErrors);
  });

export const updateAuthUser = user => dispatch =>
  loadingCycle(api.put('/auth', user)).then(() => reloadCurrentUserData(dispatch));

export const changePassword = ({ password }) => dispatch =>
  loadingCycle(api.put('/auth/password', { password })).then(() => reloadCurrentUserData(dispatch));

export const loadCurrentUserData = async () => {
  const {
    data: { user },
  } = await api.get('/auth');

  return {
    user,
  };
};

export const reloadCurrentUserData = async dispatch => {
  try {
    const { user } = await loadingCycle(loadCurrentUserData());
    saveAuthDataToStore(undefined, {
      user,
    })(dispatch);
  } catch (error) {
    saveAuthDataToStore(error)(dispatch);
    throw error;
  }
};
