import React from 'react';
import { Moment } from 'moment';

export default function renderDateWithLimits({
  dateLimiter,
}: {
  dateLimiter: (date: Moment) => boolean;
}): (props: { onClick(): void; style: any }, currentDate: Moment, selectedDate: Moment) => {} {
  return (props, currentDate): JSX.Element => {
    const isAllowed = dateLimiter(currentDate);
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <td
        {...props}
        onClick={isAllowed ? props.onClick : undefined}
        style={isAllowed ? props.style : { color: 'grey', cursor: 'no-drop' }}
      >
        {currentDate.date()}
      </td>
    );
  };
}
