import React from 'react';
import moment, { Moment } from 'moment';
import * as Yup from 'yup';
import { Formik, Field, FormikProps } from 'formik';
import ErrorBoundary from '../../../components/common/ErrorBoundary';
import ValidationInput from '../../../components/forms/inputs/ValidationInput';
import ValidationDatePicker from '../../../components/forms/inputs/ValidationDatePicker';
import renderDateWithLimits from '../../../components/forms/inputs/renderDateWithLimits';

/* eslint-disable no-template-curly-in-string */
export const newDealerContextSchema = Yup.object()
  .shape({
    currentProgram: Yup.string()
      .defined('Please provide the current program')
      .label('Current Program'),
    bondPolicyNumber: Yup.string()
      .nullable()
      .notRequired()
      .label('Bond Policy Number'),
    bondInsuranceCompanyName: Yup.string()
      .label('Bond Insurance Company Name')
      .when('bondPolicyNumber', {
        is: (bondPolicyNumber?: string | null) => !!bondPolicyNumber?.trim(),
        then: schema => schema.required(),
        otherwise: schema => schema.nullable().notRequired(),
      }),
    bondExpiration: Yup.date()
      .label('Bond Expiration')
      .when('bondPolicyNumber', {
        is: (bondPolicyNumber?: string | null) => !!bondPolicyNumber?.trim(),
        then: schema => schema.required(),
        otherwise: schema => schema.nullable().notRequired(),
      }),
    accountSource: Yup.string()
      .defined('Source is required')
      .label('Source'),
    stateLicenseNumber: Yup.string()
      .defined('State License number is required')
      .label('State License number'),
    stateDealerLicenseEffectiveDate: Yup.date()
      .label('State dealer license effective date')
      .max(moment(), '${path} must be before today')
      .defined('Effective date must be provided'),
    stateDealerLicenseExpirationDate: Yup.date()
      .label('State dealer license expiration date')
      .min(moment(), '${path} must be after today')
      .max(moment().add({ years: 3 }))
      .defined('Expiration date must be provided'),
    businessLicenseNumber: Yup.string()
      .defined('Business license must be provided')
      .label('Business license number'),
    issuingEntity: Yup.string()
      .defined('License issuing entity must be provided')
      .label('Issuing entity'),
    appointmentDate: Yup.date()
      .label('Appointment date')
      .min(moment().subtract(30, 'days'), 'Date must be within 30 days')
      .max(moment().endOf('day'), 'Date must be today or within 30 days')
      .defined('Appointment Date must be provided'),
  })
  .defined();

export interface NewDealerContextValues extends Yup.Asserts<typeof newDealerContextSchema> {}

export default function NewDealerContext({
  initialValues,
  onSubmit,
  children,
}: {
  initialValues?: NewDealerContextValues;
  onSubmit(values: NewDealerContextValues): void;
  children: (props: FormikProps<NewDealerContextValues>) => React.ReactElement;
}) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={newDealerContextSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, ...props }) => (
        <form onSubmit={handleSubmit}>
          <ErrorBoundary>
            <Field
              name="currentProgram"
              component={ValidationInput}
              type="text"
              required
              label="Competitors"
            />
            <Field
              name="bondPolicyNumber"
              component={ValidationInput}
              type="text"
              label="Bond Policy Number"
            />
            <Field
              name="bondInsuranceCompanyName"
              component={ValidationInput}
              type="text"
              label="Bond Insurance Company Name"
            />
            <Field
              name="bondExpiration"
              component={ValidationDatePicker}
              label="Bond Expiration Date"
            />
            <Field
              name="accountSource"
              component={ValidationInput}
              type="text"
              required
              label="How did we get the account?"
            />
            <Field
              name="stateLicenseNumber"
              component={ValidationInput}
              type="text"
              required
              label="State Dealer License Number"
            />

            <Field
              name="stateDealerLicenseEffectiveDate"
              component={ValidationDatePicker}
              required
              label="State Dealer License Effective Date"
              renderDay={renderDateWithLimits({
                dateLimiter: currentDate => {
                  const today = moment();
                  return currentDate.isBefore(today, 'days');
                },
              })}
            />

            <Field
              name="stateDealerLicenseExpirationDate"
              component={ValidationDatePicker}
              required
              label="State Dealer License Expiration Date"
              renderDay={renderDateWithLimits({
                dateLimiter: currentDate => {
                  const today = moment();
                  return (
                    currentDate.isAfter(today) && currentDate.isBefore(today.add({ years: 3 }))
                  );
                },
              })}
            />
            <Field
              name="businessLicenseNumber"
              component={ValidationInput}
              type="text"
              required
              label="City or County Business License"
            />
            <Field
              name="issuingEntity"
              component={ValidationInput}
              type="text"
              required
              label="Issuing Entity for Business License"
            />
            <Field
              name="appointmentDate"
              label="PRco Appointment Date"
              type="date"
              required
              component={ValidationDatePicker}
              pickerProps={{
                renderDay: renderDateWithLimits({
                  dateLimiter: (currentDate: Moment): boolean => {
                    const today = moment();
                    const diff = currentDate.diff(today, 'days');
                    return (diff >= -30 && diff < 0) || currentDate.isSame(today, 'day');
                  },
                }),
              }}
            />
            {children({ handleSubmit, ...props })}
          </ErrorBoundary>
        </form>
      )}
    </Formik>
  );
}
