import React, { useContext } from 'react';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Button from 'react-bootstrap/lib/Button';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import { LinkContainer } from 'react-router-bootstrap';
import { AuthContext } from '../components/Auth';

export function Home(props) {
  const { user, isLoading } = props;
  const { login } = useContext(AuthContext);

  if (isLoading) {
    return <ProgressBar now={100} active={true} />;
  }
  if (!user) {
    return (
      <div>
        <Helmet title="Sales Portal" />

        <Grid>
          <Row>
            <Col md={6} xs={12} mdOffset={3}>
              <Button onClick={login} bsStyle="primary" bsSize="large" block>
                Login
              </Button>
            </Col>
            {
              // @deprecated TODO delete when Auth0 migration is over.
              // TODO if it's not 2021, it's time to delete it.
            }
            <Col md={6} xs={12} mdOffset={3} style={{ marginTop: '2em', textAlign: 'right' }}>
              <LinkContainer to="/MigrateFrom219ApiToAuth0">
                <Button bsStyle="link">Migrate account</Button>
              </LinkContainer>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

  const prcoAdmin = _.get(user, 'salesRepRel.prcoAdmin');

  return (
    <div>
      <Helmet title="Home" />

      <Grid>
        <Row>
          <Col md={8} xs={12} mdOffset={2}>
            <LinkContainer to="/active-dealers">
              <Button
                bsStyle="primary"
                bsSize="large"
                block
                className="home-container-link-container"
              >
                Active Dealers
              </Button>
            </LinkContainer>
          </Col>
        </Row>
        <Row>
          <Col md={8} xs={12} mdOffset={2}>
            <LinkContainer to="/new-dealer">
              <Button
                bsStyle="primary"
                bsSize="large"
                block
                className="home-container-link-container"
              >
                New Dealer
              </Button>
            </LinkContainer>
          </Col>
        </Row>
        <Row>
          <Col md={8} xs={12} mdOffset={2}>
            <LinkContainer to="/profile">
              <Button
                bsStyle="default"
                bsSize="large"
                block
                className="home-container-link-container"
              >
                Manage My Profile
              </Button>
            </LinkContainer>
          </Col>
        </Row>
        {prcoAdmin && (
          <React.Fragment>
            <Row>
              <Col md={8} xs={12} mdOffset={2}>
                <LinkContainer to="/admin/users">
                  <Button
                    bsStyle="default"
                    bsSize="large"
                    block
                    className="home-container-link-container"
                  >
                    Manage Users
                  </Button>
                </LinkContainer>
              </Col>
            </Row>
            <Row>
              <Col md={8} xs={12} mdOffset={2}>
                <LinkContainer to="/admin/form-templates">
                  <Button
                    bsStyle="default"
                    bsSize="large"
                    block
                    className="home-container-link-container"
                  >
                    Manage Form Templates
                  </Button>
                </LinkContainer>
              </Col>
            </Row>
            <Row>
              <Col md={8} xs={12} mdOffset={2}>
                <LinkContainer to="/admin/issued-contracts">
                  <Button
                    bsStyle="default"
                    bsSize="large"
                    block
                    className="home-container-link-container"
                  >
                    Manage Issued Contracts
                  </Button>
                </LinkContainer>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Grid>
    </div>
  );
}

export default connect(state => ({
  user: state.auth.user,
  isLoading: state.auth.isLoading,
}))(Home);
