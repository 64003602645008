import React from 'react';
import { HelpBlock } from 'react-bootstrap';
import { ErrorMessage, FormikErrors, FormikTouched } from 'formik';

export default function InputError({ fieldName }: { fieldName: string }) {
  return (
    <ErrorMessage
      name={fieldName}
      render={(errorMessage: string) => {
        if (typeof errorMessage !== 'string') {
          return JSON.stringify(errorMessage);
        }
        return (
          <>
            <span className="glyphicon glyphicon-remove form-control-feedback" />
            <HelpBlock>{errorMessage}</HelpBlock>
          </>
        );
      }}
    />
  );
}

export function getFieldValidationState({
  fieldError,
  fieldTouched,
}: {
  fieldError: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  fieldTouched: boolean | FormikTouched<any> | FormikTouched<any>[];
}): 'error' | 'success' | 'warning' | null {
  if (fieldError && fieldTouched) {
    return 'error';
  }
  // user his submit, but did not touch the inputs
  if (fieldError) {
    return 'warning';
  }
  if (fieldTouched) {
    return 'success';
  }
  return null;
}
