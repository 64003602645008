import cloneDeep from 'lodash/cloneDeep';
import { splitStringsIntoArray } from '../../../utils/string';
import { RateType } from '../../../containers/forms/constants';
import * as dealerService from '../dealerProducts';
import { UpgradeDealer } from '../../../containers/forms/UpgradeDealer/types';
import { NewDealerProductsValues } from '../../../containers/forms/NewDealer/NewDealerProducts';

type DealerInput = UpgradeDealer | NewDealerProductsValues;
export type FinalDealerInput = DealerInput & {
  incentivePayeeVSC?: string[];
  incentivePayeeGAP?: string[];
  incentivePayeeTWP?: string[];
};

const convertStringToArray = (value: string): string[] =>
  Array.isArray(value) ? value : splitStringsIntoArray([value]);

export function useConvertedValues(dealer: DealerInput): FinalDealerInput {
  // some fields are comma separated strings, we want to split those into arrays
  return {
    ...dealer,
    incentivePayeeVSC: convertStringToArray(dealer.incentivePayeeVSC),
    incentivePayeeGAP: convertStringToArray(dealer.incentivePayeeGAP),
    incentivePayeeTWP: convertStringToArray(dealer.incentivePayeeTWP),
  };
}

const clearIncentiveFields = (cleanedDealer): void => {
  delete cleanedDealer.incentiveAmountVSC;
  delete cleanedDealer.incentivePayeeVSC;
  delete cleanedDealer.incentiveAmountGAP;
  delete cleanedDealer.incentivePayeeGAP;
  delete cleanedDealer.incentiveAmountTWP;
  delete cleanedDealer.incentivePayeeTWP;
  delete cleanedDealer.productionIncentivePayType;
  delete cleanedDealer.specialRateTypeNotes;
  delete cleanedDealer.productionIncentiveProducts;
  delete cleanedDealer.dealerW9Forms;
};

export function trimUnusedFormFields(dealer: FinalDealerInput): FinalDealerInput {
  // Clone original data
  const cleanedDealer = cloneDeep(dealer);

  const allProductSelections = [
    ...(cleanedDealer.appointedProducts ?? []),
    ...(cleanedDealer.retailPackProducts ?? []),
    ...(cleanedDealer.productionIncentiveProducts ?? []),
    ...(cleanedDealer.ncbAddendumProducts ?? []),
  ];

  // this field is temporary - and used to fill the other payee fields;
  delete cleanedDealer.individualIncentivePayee;

  if (!dealerService.hasVSCProduct(allProductSelections)) {
    delete cleanedDealer.retailPackAmountVSC;
    delete cleanedDealer.incentiveAmountVSC;
    delete cleanedDealer.incentivePayeeVSC;
  }

  if (!dealerService.hasGAPProduct(allProductSelections)) {
    delete cleanedDealer.isBHPH;
    delete cleanedDealer.retailPackAmountGAP;
    delete cleanedDealer.incentiveAmountGAP;
    delete cleanedDealer.incentivePayeeGAP;
  }

  if (!dealerService.hasTWPProduct(allProductSelections)) {
    delete cleanedDealer.retailPackAmountTWP;
  }

  if (!cleanedDealer.retailPack) {
    delete cleanedDealer.retailPackProducts;
    delete cleanedDealer.retailPackAmountVSC;
    delete cleanedDealer.retailPackAmountGAP;
    delete cleanedDealer.retailPackAmountTWP;
  }

  if (!cleanedDealer.productionIncentive) {
    clearIncentiveFields(cleanedDealer);
  }

  if (!cleanedDealer.includeNCBAddendum) {
    delete cleanedDealer.ncbAddendumProducts;
  }

  if (cleanedDealer.rateType === RateType.STANDARD) {
    clearIncentiveFields(cleanedDealer);
    delete cleanedDealer.productionIncentive;
  }

  return cleanedDealer;
}

export default function useTrimUnusedDealerFields(rawDealer: DealerInput): DealerInput {
  return trimUnusedFormFields(rawDealer);
}
