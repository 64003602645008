import React from 'react';

export default function NotFound() {
  return (
    <div className="container">
      <h1>No page found!</h1>
      <p>There is no page at this address!</p>
    </div>
  );
}
