import React, { InputHTMLAttributes } from 'react';
import { FormGroup, ControlLabel, FormControl, Col, Row } from 'react-bootstrap';
import { FieldProps } from 'formik';
import InputError, { getFieldValidationState } from './InputError';

export type InputProps<V, Values> = {
  label: string;
} & FieldProps<V, Values> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'children'>;

function FormikFieldGroupInput<V, Values>({
  label,
  hideLabel,
  field,
  form: { errors, touched },
  meta,
  ...inputProps
}: { hideLabel?: boolean } & InputProps<V, Values>) {
  const { name } = field;
  const fieldError = errors[name];
  const fieldTouched = touched[name];
  return (
    <FormGroup
      controlId={name}
      validationState={getFieldValidationState({ fieldError, fieldTouched })}
    >
      {hideLabel ? null : <ControlLabel>{label}</ControlLabel>}
      {/*  @ts-expect-error */}
      <FormControl {...field} {...inputProps} />
      <InputError fieldName={name} />
    </FormGroup>
  );

}

function FormikInput<V, Values>({ label, field, ...inputProps }: InputProps<V, Values>) {
  return (
    <Row>
      <Col xs={3}>
        <label htmlFor={field.name}>{label}</label>
      </Col>
      <Col xs={9}>
        <FormikFieldGroupInput hideLabel label={label} field={field} {...inputProps} />
      </Col>
    </Row>
  );
}

export default function FormikValidationInput<V, Values>({
  topLabel = false,
  ...props
}: InputProps<V, Values> & { topLabel?: boolean }) {
  if (topLabel) {
    return <FormikFieldGroupInput {...props} />;
  }
  return <FormikInput {...props} />;
}
