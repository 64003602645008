import React, { useState, useCallback, useMemo } from 'react';
import {
  FormControl,
  FormGroup,
  ControlLabel,
  InputGroup,
  HelpBlock,
  Button,
  Alert,
  ProgressBar,
} from 'react-bootstrap';
import NotificationModal from '../components/common/NotificationModal';
import api from '../utils/service';

// @deprecated TODO delete when Auth0 migration is over.
// TODO if it's not 2021, it's time to delete it.

// eslint-disable-next-line max-len, no-control-regex
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const MigrateFrom219ApiToAuth0 = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState(undefined);
  const [newConfirmPassword, setNewConfirmPassword] = useState(undefined);
  const [lastError, setLastError] = useState();
  const [inProgress, setInProgress] = useState(false);
  const [followFairCriteria, setFollowFairCriteria] = useState(true);
  const [showEmailWarning, setShowEmailWarning] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);

  const emailError = EMAIL_REGEX.test(email) ? undefined : 'Invalid email';

  const newPasswordError =
    newPassword === undefined
      ? undefined
      : (() => {
          if (newPassword.length < 8) return 'New password should be at least 8 symbols';
          return undefined;
        })();

  const newConfirmPasswordError =
    newConfirmPassword === undefined
      ? undefined
      : (() => {
          if (newConfirmPassword !== newPassword)
            return "Confirm password doesn't match new password";
          return undefined;
        })();

  const keepingPassword = newPassword === undefined;

  const handleKeepPasswordToggle = useCallback(() => {
    setNewPassword(newPassword === undefined ? '' : undefined);
    setNewConfirmPassword(newConfirmPassword === undefined ? '' : undefined);
  }, [newPassword, newConfirmPassword]);

  const handleEmailChange = useCallback(({ currentTarget: { value } }) => {
    setEmail(value);
  }, []);

  const handlePasswordChange = useCallback(({ currentTarget: { value } }) => {
    setPassword(value);
  }, []);

  const handleNewPasswordChange = useCallback(({ currentTarget: { value } }) => {
    setNewPassword(value);
  }, []);

  const handleConfirmNewPasswordChange = useCallback(({ currentTarget: { value } }) => {
    setNewConfirmPassword(value);
  }, []);

  const checkFairCriteria = message => !(message || '').includes('PasswordStrengthError');

  const handleEmailWarningConfirm = () => {
    window.location.href = '/';
  };

  const handleMigrate = useMemo(
    () =>
      !emailError && !newPasswordError && !newConfirmPasswordError
        ? async () => {
            setInProgress(true);
            setLastError(undefined);
            try {
              await api.post('/auth/MigrateFrom219ApiToAuth0', {
                email,
                password,
                newPassword,
              });
              // display a popup in order to notify users to read their email
              setShowEmailWarning(true);
            } catch (error) {
              const { response: { data: { message = '' } = {} } = {} } = error;
              // check if the password follow fair criteria
              if (!checkFairCriteria(message || error.message)) {
                // disable input and hide 'keep current' option
                setFollowFairCriteria(false);
                setLastError(
                  'Our new password policy: at least 8 characters including a lower-case letter, an upper-case letter, and a number.',
                );
              } else {
                setLastError(message || error.message);
              }
            }
            setInProgress(false);
          }
        : undefined,
    [emailError, newPasswordError, newConfirmPasswordError, email, password, newPassword],
  );

  return (
    <>
      <form style={{ maxWidth: '40em', margin: '2em auto' }}>
        <FormGroup validationState={emailError ? 'error' : undefined}>
          <ControlLabel>Email</ControlLabel>
          <FormControl
            value={email}
            onChange={handleEmailChange}
            name="email"
            readOnly={inProgress}
          />
          {emailError && <HelpBlock>{emailError}</HelpBlock>}
        </FormGroup>
        <FormGroup>
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={password}
            type="password"
            name="password"
            onChange={handlePasswordChange}
            disabled={!followFairCriteria}
            readOnly={inProgress}
          />
        </FormGroup>
        {keepingPassword && followFairCriteria && (
          <FormGroup>
            <Button bsStyle="link" onClick={handleKeepPasswordToggle}>
              Also set new password
            </Button>
          </FormGroup>
        )}
        {!followFairCriteria && <Alert bsStyle="warning">{lastError}</Alert>}
        {(!keepingPassword || !followFairCriteria) && (
          <>
            <FormGroup validationState={newPasswordError ? 'error' : undefined}>
              <ControlLabel>New password</ControlLabel>
              <InputGroup>
                <FormControl
                  value={newPassword}
                  type={showNewPassword ? 'text' : 'password'}
                  onChange={handleNewPasswordChange}
                  readOnly={inProgress}
                />
                <InputGroup.Button onClick={() => setShowNewPassword(!showNewPassword)}>
                  <Button title={showNewPassword ? 'hide' : 'unhide'}>
                    {showNewPassword ? (
                      <span className="glyphicon glyphicon-eye-close"></span>
                    ) : (
                      <span className="glyphicon glyphicon-eye-open"></span>
                    )}
                  </Button>
                </InputGroup.Button>
                {followFairCriteria && (
                  <InputGroup.Button>
                    <Button onClick={handleKeepPasswordToggle}>Keep current</Button>
                  </InputGroup.Button>
                )}
              </InputGroup>
              {newPasswordError && <HelpBlock>{newPasswordError}</HelpBlock>}
            </FormGroup>
            <FormGroup validationState={newConfirmPasswordError ? 'error' : undefined}>
              <ControlLabel>Confirm your password</ControlLabel>
              <InputGroup>
                <FormControl
                  value={newConfirmPassword}
                  type={showNewConfirmPassword ? 'text' : 'password'}
                  onChange={handleConfirmNewPasswordChange}
                  readOnly={inProgress}
                />
                <InputGroup.Button
                  onClick={() => setShowNewConfirmPassword(!showNewConfirmPassword)}
                >
                  <Button title={showNewConfirmPassword ? 'hide' : 'unhide'}>
                    {showNewConfirmPassword ? (
                      <span className="glyphicon glyphicon-eye-close"></span>
                    ) : (
                      <span className="glyphicon glyphicon-eye-open"></span>
                    )}
                  </Button>
                </InputGroup.Button>
              </InputGroup>
              {newConfirmPasswordError && <HelpBlock>{newConfirmPasswordError}</HelpBlock>}
            </FormGroup>
          </>
        )}
        <Button
          onClick={handleMigrate}
          style={{ margin: '1em auto' }}
          bsSize="large"
          disabled={!handleMigrate || inProgress}
          bsStyle="primary"
        >
          Migrate
        </Button>
        {inProgress && <ProgressBar now={100} active={true} />}
        {lastError && followFairCriteria && <Alert bsStyle="danger">{lastError}</Alert>}
      </form>
      <NotificationModal
        show={showEmailWarning}
        title={`Email Verification Required`}
        confirmText={'Got It'}
        message={
          <div>
            Please check your inbox at <b>{email}</b>, you will have a verification message from
            Auth0, our new authentication partner. Verify your account, and you will be able to
            login at this page.
          </div>
        }
        onConfirm={() => {
          setShowEmailWarning(false);
          handleEmailWarningConfirm();
        }}
      />
    </>
  );
};
