import React from 'react';
import { omit } from 'lodash';
import { Field, Formik, FormikProps, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Grid, Row } from 'react-bootstrap';
import ErrorBoundary from '../../../components/common/ErrorBoundary';
import ValidationSelect from '../../../components/forms/inputs/ValidationSelect';
import ProductionIncentive, {
  productionIncentiveValidations,
} from '../DealerFormFieldGroups/ProductionIncentive';
import AppointedProducts, {
  appointedProductValidators,
} from '../DealerFormFieldGroups/AppointedProducts';
import { RateType, rateTypeOptions } from '../constants';
import ValidationTextarea from '../../../components/forms/inputs/ValidationTextarea';
import * as dealerService from '../../../services/dealerService';
import PENIntegration, { penIntegrationValidators } from '../DealerFormFieldGroups/PENIntegration';

export const newDealerProductsSchema = Yup.object()
  .shape({
    ...appointedProductValidators,
    // individualIncentivePayee is an internal field - we dont want to expose validation for it outside this form
    ...omit(productionIncentiveValidations, 'individualIncentivePayee'),
    ...penIntegrationValidators,
    rateType: Yup.mixed<RateType>()
      .oneOf(Object.values(RateType))
      .defined('Please select rate type'),
    notes: Yup.string().notRequired(),
  })
  .defined();

const newDealerProductsSchemaInternal = Yup.object()
  .shape({
    ...appointedProductValidators,
    ...productionIncentiveValidations,
    ...penIntegrationValidators,
    rateType: Yup.mixed<RateType>()
      .oneOf(Object.values(RateType))
      .defined('Please select rate type'),
    notes: Yup.string().notRequired(),
  })
  .defined();

export interface NewDealerProductsValues extends Yup.Asserts<typeof newDealerProductsSchema> {}

const useShowFields = (): {
    showFields: Set<'specialRates' >;
} => {
  const { values } = useFormikContext<NewDealerProductsValues>();

    const showFields = new Set<'specialRates'>();

  if (values.rateType === RateType.SPECIAL) {
    showFields.add('specialRates');
  }

  return { showFields };
};

function ProductFields({
  onSubmit,
  children,
}: {
  onSubmit: (values: NewDealerProductsValues) => void;
  children: React.ReactElement;
}): React.ReactElement {
  const { values } = useFormikContext<NewDealerProductsValues>();
  const { showFields } = useShowFields();
  const onAppointedProductsChanged = dealerService.hooks.useOnNewDealerAppointedProductsChanged();
  const supplementalProductOptions = dealerService.hooks.useSupplementalProductOptions();

  return (
    <form onSubmit={onSubmit}>
      <ErrorBoundary>
        <AppointedProducts
          appointedProductOptions={[
            dealerService.DealerProduct.VSC,
            dealerService.DealerProduct.GAP,
            dealerService.DealerProduct.TWP,
          ]}
          supplementalProductOptions={supplementalProductOptions}
          onAppointedProductsChanged={onAppointedProductsChanged}
        />

        <Grid fluid>
          <Row>
            <Field
              name="rateType"
              component={ValidationSelect}
              options={rateTypeOptions}
              label="Will the dealer be using standard rate or special rates?"
              placeholder="Please choose an option"
            />
          </Row>
        </Grid>

        {showFields.has('specialRates') && (
          <>
            <hr />
            <legend>Special Rates</legend>
            <PENIntegration />
            <ProductionIncentive productionIncentiveProductsOptions={values.appointedProducts} />
          </>
        )}
        <hr />
        <Field
          name="notes"
          component={ValidationTextarea}
          type="text"
          label="Notes"
          placeholder="Additional information about the dealer."
        />
        {children}
      </ErrorBoundary>
    </form>
  );
}

export default function NewDealerProducts({
  initialValues,
  onSubmit,
  children,
}: {
  initialValues?: NewDealerProductsValues;
  onSubmit(values: NewDealerProductsValues): void;
  children: (props: FormikProps<NewDealerProductsValues>) => React.ReactElement;
}) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={newDealerProductsSchemaInternal}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, ...props }) => (
        <ProductFields onSubmit={handleSubmit}>
          {children({ handleSubmit, ...props })}
        </ProductFields>
      )}
    </Formik>
  );
}
