import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import qs from 'querystring';
import { pick } from 'lodash';
import { useApi } from '../../utils/service';
import { defaultColumnMapper } from '../../utils';

/**
 * @typedef QueryParams {{
 *   searchText?: string
 *   salesRepMongoId?: string,
 *   pendingOnly?: boolean,
 * }}
 */
const DEFAULT_QUERY = { pendingOnly: true };
const useGetContracts = () => {
  const api = useApi();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [queryParams, setQueryParams] = React.useState(DEFAULT_QUERY);

  const fetch = React.useCallback(
    /**
     *
     * @param query {QueryParams}
     * @return {Promise<void>}
     */
    async query => {
      try {
        setQueryParams(query);
        setData(null);
        const response = await api.get(`/issued-contracts?${qs.stringify(query)}`);
        if (response.status === 204) {
          return setData(() => ({
            contracts: [],
          }));
        }
        /**
         * data: {{ contracts: IssuedContract[] }}
         */
        setData(() => response.data);
        setError(() => null);
      } catch (error) {
        setData(() => null);
        setError(() => error);
      }
    },
    [api],
  );

  React.useEffect(() => {
    fetch(DEFAULT_QUERY);
  }, [fetch]);
  return { data, error, queryParams, fetch };
};

const useFormattedData = ({ data }) => {
  if (data == null || data.contracts.length === 0) {
    return { data, columns: null };
  }
  const [firstRow] = data.contracts || [];
  const columns = Object.keys(
    pick(
      firstRow,
      'name',
      'packageStatus',
      'requestType',
      'salesRepName',
      'salesRepId',
      // 'oneSpanId',
      'created',
      'updated',
    ),
  );
  return { contracts: data.contracts, columns };
};

const useAdditionalColumns = ({ onClick }) => {
  const actionColumn = defaultColumnMapper({
    column: 'actions',
    formatter: (cell, row) => (
      <Button
        bsStyle="primary"
        disabled={row.packageStatus.includes('ARCHIVED')}
        onClick={() => onClick(row)}
      >
        Process Contract
      </Button>
    ),
  });
  return [actionColumn];
};

const renderNoContent = query => {
  const { pendingOnly, searchText, salesRepMongoId } = query || {};
  if (pendingOnly) {
    return 'No pending contracts';
  }
  if (searchText != null && searchText !== '') {
    return `No contracts matching query ${searchText}`;
  }

  if (salesRepMongoId != null) {
    return 'No contracts for selected sales rep.';
  }
};

const Content = ({ error, contracts, columns, additionalColumns, queryParams }) => {
  if (error != null) {
    return (
      <Alert className="alert-danger">
        Unable to fetch Pending contracts, reason {error.toString()}
      </Alert>
    );
  }
  if (contracts == null || columns == null || contracts.length === 0) {
    return <Alert className="alert-success">{renderNoContent(queryParams)}</Alert>;
  }

  return (
    <BootstrapTable
      keyField="_id"
      hover
      striped
      condensed
      bordered={false}
      data={contracts}
      columns={additionalColumns.concat(
        columns.map(column =>
          defaultColumnMapper({
            column,
          }),
        ),
      )}
      filter={filterFactory()}
      pagination={paginationFactory({ totalSize: contracts.length })}
    />
  );
};

export default function IssuedContractsTable({ onClick, renderHeader }) {
  const { data, error, queryParams, fetch } = useGetContracts();
  const { contracts, columns } = useFormattedData({ data });
  const additionalColumns = useAdditionalColumns({ onClick });

  return (
    <>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        {renderHeader && renderHeader({ onRefresh: fetch })}
        <Content
          contracts={contracts}
          queryParams={queryParams}
          columns={columns}
          additionalColumns={additionalColumns}
          error={error}
        />
      </div>
    </>
  );
}
