import React, { useState } from 'react';
import { kebabCase, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Row, Col, Button } from 'react-bootstrap';
import api from '../../../utils/service';

const ACCEPTED_TEMPLATE_MIME_TYPES = ['application/pdf'];

const getDropZoneStyleAndMessage = ({
  isDragActive,
  requiresMoreForms,
  signerNames = [],
  acceptedForms = [],
  errorMessage,
}) => {
  if (isDragActive) {
    return {
      style: {
        border: '1px dashed green',
      },
      message: 'Drop Here',
    };
  }

  if (errorMessage) {
    return {
      style: { border: '1px dashed red' },
      message: errorMessage,
    };
  }

  if (requiresMoreForms) {
    return {
      style: { border: '1px dashed darkgreen' },
      message: `${acceptedForms.length}/${signerNames.length} form(s) added, You must provide 1 w9 form for every signer name`,
    };
  }

  if (acceptedForms.length > 0) {
    return {
      style: { border: '1px solid green' },
      message: `${acceptedForms.length}/${signerNames.length} form(s) added`,
    };
  }

  return {
    style: { border: '1px solid lightgrey' },
    message: `${signerNames.length} W9 forms required - Drag & Drop Or click to add`,
  };
};

function DealerW9FormUploader({
  value = [],
  dealershipName,
  signerNames,
  onUpload,
  disabled,
  error = null,
}) {
  const [acceptedForms, setAcceptedForms] = useState(value);
  const [errorMessage, setErrorMessage] = useState(error);

  const requiresMoreForms =
    acceptedForms.length === 0 ? undefined : acceptedForms.length !== signerNames.length;

  const handleDrop = acceptedFiles => {
    const combinedForms = uniqBy(acceptedForms.concat(acceptedFiles), 'name');
    setAcceptedForms(combinedForms);
  };

  const handleUploadForms = async () => {
    const fileForm = new FormData();
    acceptedForms.forEach(file => {
      fileForm.append('files', file);
    });

    const formattedName = kebabCase(dealershipName);
    /**
     * @type {AxiosResponse<{ files: S3FileResult[] }>}
     */
    const uploaded = await api
      .put(`/forms/w9/${formattedName}`, fileForm, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .catch(error => {
        const { response } = error;
        setErrorMessage(
          `Unable to upload files, reason: ${(response && response.message) || error.toString()}`,
        );
        return null;
      });

    if (uploaded != null && onUpload) {
      onUpload(uploaded.data.files);
    }
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Dropzone
            onDrop={handleDrop}
            accept={ACCEPTED_TEMPLATE_MIME_TYPES}
            disabled={disabled}
            id="file-drop"
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              const { style, message } = getDropZoneStyleAndMessage({
                isDragActive,
                requiresMoreForms,
                signerNames,
                acceptedForms,
                errorMessage,
              });
              return (
                <div
                  style={{
                    height: '100%',
                    borderRadius: 3,
                    cursor: 'pointer',
                    ...style,
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 8,
                      flexFlow: 'wrap',
                    }}
                  >
                    {message}
                  </div>
                </div>
              );
            }}
          </Dropzone>
        </Col>
      </Row>
      <Row style={{ marginTop: 8 }}>
        <Col xs={6}>
          <Button
            onClick={handleUploadForms}
            block
            disabled={acceptedForms.length !== signerNames.length}
            bsStyle="success"
          >
            Upload W9s
          </Button>
        </Col>
        <Col xs={6}>
          <Button onClick={() => setAcceptedForms([])} block bsStyle="danger">
            Clear Files
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <span style={{ color: 'grey', fontSize: 10 }}>Add files, then click Upload.</span>
        </Col>
      </Row>
    </>
  );
}

DealerW9FormUploader.propTypes = {
  value: PropTypes.array,
  dealershipName: PropTypes.string.isRequired,
  signerNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DealerW9FormUploader.defaultProps = {
  value: [],
  signerNames: [],
  dealershipName: null,
};

export default DealerW9FormUploader;
