import React from 'react';
import { connect } from 'react-redux';
import { FormControl, Button } from 'react-bootstrap';
import {
  storeTemplateSearchText,
  toggleUploadTemplateModal,
  getTemplates,
} from '../../../redux/modules/formTemplateAdmin';

const toState = ({ formTemplateAdmin }) => ({
  value: formTemplateAdmin.templateSearchText,
});

const TemplateSearchForm = ({
  value,
  getTemplates,
  storeTemplateSearchText,
  toggleUploadTemplateModal,
}) => (
  <form
    style={{ display: 'flex', marginBottom: 8, justifyContent: 'space-evenly', flexWrap: 'wrap' }}
    onSubmit={e => {
      e.preventDefault();
      getTemplates({ text: value });
    }}
  >
    <div style={{ flexGrow: 2 }}>
      <FormControl
        type="text"
        value={value}
        placeholder="Search for previous templates"
        onChange={({ target }) => storeTemplateSearchText(target.value)}
      />
    </div>
    {'  '}
    <div style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, justifyContent: 'space-evenly' }}>
      <Button style={{ flexGrow: 1, margin: '0px 8px' }} bsStyle="primary" type="submit">
        {value ? 'Submit Search' : 'Latest Templates'}
      </Button>
      <Button
        style={{ flexGrow: 1 }}
        bsStyle="primary"
        onClick={() => toggleUploadTemplateModal(true)}
      >
        Upload New
      </Button>
    </div>
  </form>
);

export default connect(toState, {
  getTemplates,
  storeTemplateSearchText,
  toggleUploadTemplateModal,
})(TemplateSearchForm);
