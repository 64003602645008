import { useFormikContext } from 'formik';
import { dealerProductArraysAreEqual, hasVSCProduct } from '../dealerProducts';
import { DealerDetails, DealerProduct, SupplementalProduct } from '../types';
import { UpgradeDealerFormInputs } from '../../../containers/forms/UpgradeDealer/types';
import useCurrentlyActiveProducts, { CurrentlyActiveProduct } from './useCurrentlyActiveProducts';

function getFilteredSelectedProducts(
  selectedSpecialProducts: DealerProduct[],
  newlySelectedAppointedProducts: DealerProduct[],
  currentlyAppointedProducts: CurrentlyActiveProduct,
) {
  if (selectedSpecialProducts.length > 0) {
    const filteredByAppointedProducts = selectedSpecialProducts.filter(
      // note: product must either be selected for upgrade as an appointedProduct,
      //  or currently active to remain selected
      p => newlySelectedAppointedProducts.includes(p) || currentlyAppointedProducts[p],
    );
    if (!dealerProductArraysAreEqual(selectedSpecialProducts, filteredByAppointedProducts)) {
      // we've changed the selected products, return the change
      return filteredByAppointedProducts;
    }
  }
  return null;
}

/**
 * determines if we need to clear old values from the form, assumes upgradeEligibleProducts
 * is resolved with the current appointed products and selected appointed products
 */
export default function useOnUpgradeDealerAppointedProductsChanged(
  dealerDetails: DealerDetails,
): (newlySelectedAppointedProducts: DealerProduct[]) => void {
  const { values, setFieldValue } = useFormikContext<UpgradeDealerFormInputs>();
  const currentlyActiveProducts = useCurrentlyActiveProducts(dealerDetails);

  function setProductsFieldValue(
    field: 'retailPackProducts' | 'productionIncentiveProducts' | 'ncbAddendumProducts',
    value: DealerProduct[],
  ) {
    setFieldValue(field, value);
  }

  function setSupplementalProductsFieldValue(value: SupplementalProduct[]) {
    setFieldValue('supplementalProducts', value);
  }

  function removeVscSludgeFromSupplementalProducts() {
    setSupplementalProductsFieldValue(
      // remove product based supplemental products
      values?.supplementalProducts.filter(sp => sp !== SupplementalProduct.VSC_SLUDGE),
    );
  }

  // note: reps are only allowed to add products that are related to the appointed products,
  // if they change we need to clear them out to avoid allowing a signup for an unapproved product
  return function onAppointedProductsChanged(newlySelectedAppointedProducts): void {
    if (
      !hasVSCProduct(newlySelectedAppointedProducts) &&
      !currentlyActiveProducts.APPOINTED.VSC &&
      values?.supplementalProducts?.includes(SupplementalProduct.VSC_SLUDGE)
    ) {
      removeVscSludgeFromSupplementalProducts();
    }

    if (values?.retailPackProducts?.length > 0) {
      const filteredByAppointedProducts = getFilteredSelectedProducts(
        values?.retailPackProducts ?? [],
        newlySelectedAppointedProducts,
        currentlyActiveProducts.APPOINTED,
      );
      if (filteredByAppointedProducts != null) {
        // a product was removed from appointedProducts selection, remove it
        setProductsFieldValue('retailPackProducts', filteredByAppointedProducts);
      }
    }

    if (values?.productionIncentiveProducts?.length > 0) {
      const filteredByAppointedProducts = getFilteredSelectedProducts(
        values?.productionIncentiveProducts ?? [],
        newlySelectedAppointedProducts,
        currentlyActiveProducts.APPOINTED,
      );
      if (filteredByAppointedProducts != null) {
        // a product was removed from appointedProducts selection, remove it
        setProductsFieldValue('productionIncentiveProducts', filteredByAppointedProducts);
      }
    }

    if (values?.ncbAddendumProducts?.length > 0) {
      const filteredByAppointedProducts = getFilteredSelectedProducts(
        values?.ncbAddendumProducts ?? [],
        newlySelectedAppointedProducts,
        currentlyActiveProducts.APPOINTED,
      );
      if (filteredByAppointedProducts != null) {
        // a product was removed from appointedProducts selection, remove it
        setProductsFieldValue('ncbAddendumProducts', filteredByAppointedProducts);
      }
    }

    if (
      !dealerProductArraysAreEqual(newlySelectedAppointedProducts, [
        DealerProduct.GAP,
        DealerProduct.VSC,
      ])
    ) {
      if (values.isGapPlus && !currentlyActiveProducts.GAP_PLUS) {
        setFieldValue('isGapPlus', false);
      }
      if (values.isBHPH && !currentlyActiveProducts.BHPH) {
        setFieldValue('isBHPH', false);
      }
    }
  };
}
