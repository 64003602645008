import React, { ChangeEvent } from 'react';
import { uniq } from 'lodash';
import { useFormikContext, Field, FieldArray } from 'formik';
import { Alert, Grid } from 'react-bootstrap';
import * as Yup from 'yup';
import {
  ProductionIncentiveSplitType,
  productionIncentiveSplitOptions,
  yesNoOptions,
} from '../constants';
import DealerW9Field from './DealerW9Form';
import { splitStringsIntoArray } from '../../../utils/string';
import * as dealerService from '../../../services/dealerService';
import ValidationInput from '../../../components/forms/inputs/ValidationInput';
import ValidationTextarea from '../../../components/forms/inputs/ValidationTextarea';
import ValidationMultiCheckbox from '../../../components/forms/inputs/ValidationMultiCheckbox';
import ValidationSelect from '../../../components/forms/inputs/ValidationSelect';
import { UpgradeDealerProductValues } from '../UpgradeDealer/UpgradeDealerProducts';
import { NewDealerProductsValues } from '../NewDealer/NewDealerProducts';
import useDealerContext from '../../forms/DealerContext';
import {
  products,
  s3FormSchema,
  validateIncentivePayee,
  validateW9Forms,
} from '../validationSchemas';

export const productionIncentiveValidations: Record<
  | 'productionIncentive'
  | 'productionIncentiveProducts'
  | 'productionIncentivePayType'
  | 'incentiveAmountVSC'
  | 'incentivePayeeVSC'
  | 'incentiveAmountGAP'
  | 'incentivePayeeGAP'
  | 'incentiveAmountTWP'
  | 'incentivePayeeTWP'
  | 'individualIncentivePayee'
  | 'dealerW9Forms'
  | 'specialRateTypeNotes',
  Yup.BaseSchema
> = {
  productionIncentive: Yup.boolean().label('Production Incentive'),
  productionIncentiveProducts: products()
    .label('Production incentive products')
    .notRequired()
    .when('productionIncentive', {
      is: true,
      then: products()
        .min(1)
        .required('You must select an incentive product'),
    }),
  productionIncentivePayType: Yup.mixed<ProductionIncentiveSplitType>()
    .oneOf(Object.values(ProductionIncentiveSplitType))
    .notRequired()
    .label('Production incentive pay type')
    .when('productionIncentive', {
      is: true,
      then: Yup.mixed()
        .oneOf(Object.values(ProductionIncentiveSplitType))
        .defined('Please selected an incentive type'),
    }),

  incentiveAmountVSC: Yup.number()
    .label('Incentive amount VSC')
    .notRequired()
    .when('productionIncentiveProducts', {
      is: dealerService.hasVSCProduct,
      then: Yup.number()
        .min(0)
        .defined('Please provide an amount'),
    }),
  incentivePayeeVSC: Yup.string()
    .label('Incentive payee VSC')
    .notRequired()
    .nullable()
    .when('productionIncentiveProducts', {
      is: dealerService.hasVSCProduct,
      then: validateIncentivePayee,
    }),
  incentiveAmountGAP: Yup.number()
    .label('Incentive amount GAP')
    .notRequired()
    .when('productionIncentiveProducts', {
      is: dealerService.hasGAPProduct,
      then: Yup.number()
        .min(0)
        .defined('Please provide an amount'),
    }),
  incentivePayeeGAP: Yup.string()
    .label('Incentive payee GAP')
    .notRequired()
    .nullable()
    .when('productionIncentiveProducts', {
      is: dealerService.hasGAPProduct,
      then: validateIncentivePayee,
    }),
  incentiveAmountTWP: Yup.number()
    .label('Incentive amount TWP')
    .notRequired()
    .when('productionIncentiveProducts', {
      is: dealerService.hasTWPProduct,
      then: Yup.number()
        .min(0)
        .defined('Please provide an amount'),
    }),
  incentivePayeeTWP: Yup.string()
    .label('Incentive payee TWP')
    .notRequired()
    .nullable()
    .when('productionIncentiveProducts', {
      is: dealerService.hasTWPProduct,
      then: validateIncentivePayee,
    }),
  individualIncentivePayee: Yup.string()
    .label('Individual Incentive Payee')
    .notRequired()
    .nullable()
    .when(['productionIncentivePayType', 'productionIncentiveProducts'], {
      is: (payType, productionIncentiveProducts) =>
        payType != null &&
        payType !== ProductionIncentiveSplitType.DEALER_ONLY &&
        productionIncentiveProducts.length > 1,
      then: validateIncentivePayee,
    }),

  dealerW9Forms: Yup.array()
    .label('Dealer w9 forms')
    .of(s3FormSchema)
    .notRequired()
    .when(['productionIncentivePayType'], {
      is: payType => payType != null && payType !== ProductionIncentiveSplitType.DEALER_ONLY,
      then: validateW9Forms,
    }),

  specialRateTypeNotes: Yup.string()
    .label('Special rate type notes')
    .notRequired()
    .when('dealerW9Forms', {
      is: w9Names => w9Names?.length > 0,
      then: Yup.string().required(
        `You must provide an amount breakdown for each special rate recipient`,
      ),
    }),
};

export type ProductionIncentiveFields =
  | 'specialRateFields'
  | 'rateType'
  | 'includeNCBAddendum'
  | 'ncbAddendumProducts'
  | 'productionIncentive'
  | 'productionIncentiveProducts'
  | 'productionIncentivePayType'
  | 'incentiveAmountVSC'
  | 'incentivePayeeVSC'
  | 'incentiveAmountGAP'
  | 'incentivePayeeGAP'
  | 'incentiveAmountTWP'
  | 'incentivePayeeTWP'
  | 'individualIncentivePayee'
  | 'dealerW9Forms'
  | 'specialRateTypeNotes';

function useSetPayeeNames<FormValues = NewDealerProductsValues | UpgradeDealerProductValues>() {
  const { setFieldValue, values } = useFormikContext<
    NewDealerProductsValues | UpgradeDealerProductValues
  >();

  return (payeeNames: string | null, products: dealerService.DealerProduct[]) => {
    // prevent recursive render - check if values are already set
    if (dealerService.hasVSCProduct(products) && values.incentivePayeeVSC !== payeeNames) {
      setFieldValue('incentivePayeeVSC', payeeNames);
    }
    if (dealerService.hasGAPProduct(products) && values.incentivePayeeGAP !== payeeNames) {
      setFieldValue('incentivePayeeGAP', payeeNames);
    }
    if (dealerService.hasTWPProduct(products) && values.incentivePayeeTWP !== payeeNames) {
      setFieldValue('incentivePayeeTWP', payeeNames);
    }
  };
}

function useW9SignerNames<FormValues = NewDealerProductsValues | UpgradeDealerProductValues>(): {
  w9SignerNames: string[];
  dealershipName?: string;
} {
  const { values } = useFormikContext<NewDealerProductsValues | UpgradeDealerProductValues>();
  const { dealerDetails, dealershipName } = useDealerContext();

  const { incentivePayeeVSC, incentivePayeeGAP, incentivePayeeTWP } = values;

  const w9SignerNames = uniq(
    splitStringsIntoArray([incentivePayeeVSC, incentivePayeeGAP, incentivePayeeTWP]).filter(
      // ignore dealership name - it does not need a w9
      name => name !== dealershipName,
    ),
  );
  return { w9SignerNames, dealershipName: dealershipName ?? dealerDetails?.dlCompany };
}

function useFormOnChangeHandlers<
  FormValues = NewDealerProductsValues | UpgradeDealerProductValues
>(): {
  onIncentivePayTypeChange(value: ProductionIncentiveSplitType): void;
  onIndividualPayeeChange(value: ChangeEvent): void;
  onProductionIncentiveProductsChange(products: dealerService.DealerProduct[]): void;
} {
  const setPayeeNames = useSetPayeeNames();
  const { values, setFieldValue } = useFormikContext<
    NewDealerProductsValues | UpgradeDealerProductValues
  >();
  const { w9SignerNames, dealershipName } = useW9SignerNames();

  const {
    productionIncentiveProducts,
    productionIncentivePayType: previousProductionIncentivePayType,
  } = values;

  const setValue = (fieldName: string, value: any | any[]): void => {
    const previousValue = values[fieldName];

    if (Array.isArray(value)) {
      if (JSON.stringify(value) === JSON.stringify(previousValue)) {
        return;
      }
      if (previousValue?.length === 0 && value.length === 0) {
        return;
      }
      setFieldValue(fieldName, value);
      return;
    }

    if (previousValue !== value) {
      setFieldValue(fieldName, value);
    }
  };

  function onIncentivePayTypeChange(productionIncentivePayType) {
    if (productionIncentivePayType === previousProductionIncentivePayType) {
      return;
    }

    if (productionIncentivePayType === ProductionIncentiveSplitType.DEALER_ONLY) {
      setPayeeNames(dealershipName, productionIncentiveProducts);
      // clear out w9 forms
      setValue('dealerW9Forms', []);
    }

    if (productionIncentivePayType === ProductionIncentiveSplitType.INDIVIDUAL_ONLY) {
      setPayeeNames('', productionIncentiveProducts);
    }

    if (productionIncentivePayType === ProductionIncentiveSplitType.SPLIT_DEALER_INDIVIDUAL) {
      setPayeeNames(
        uniq([dealershipName, ...w9SignerNames]).join(', '),
        productionIncentiveProducts,
      );
    }

    if (productionIncentivePayType === ProductionIncentiveSplitType.SPLIT_INDIVIDUALS) {
      setPayeeNames(w9SignerNames?.join(',') ?? '', productionIncentiveProducts);
    }
  }

  function onIndividualPayeeChange(e: ChangeEvent<HTMLInputElement>): void {
    const individualIncentivePayeeName = e.target.value;
    // we've hijacked the formik onChange, but we don't need this value
    setValue('individualIncentivePayee', individualIncentivePayeeName);

    if (
      values.productionIncentivePayType === ProductionIncentiveSplitType.INDIVIDUAL_ONLY &&
      individualIncentivePayeeName != null
    ) {
      // we use the VSC input to set the others
      // payee must be the same for both GAP, TWP, and VSC if INDIVIDUAL_ONLY
      setPayeeNames(individualIncentivePayeeName, values.productionIncentiveProducts);
    }
  }

  function onProductionIncentiveProductsChange(products: dealerService.DealerProduct[]): void {
    if (
      values.productionIncentivePayType === ProductionIncentiveSplitType.INDIVIDUAL_ONLY &&
      values.incentivePayeeVSC != null
    ) {
      // payee must be the same for all products
      setPayeeNames(values.incentivePayeeVSC, products);
    }

    if (
      values.productionIncentivePayType === ProductionIncentiveSplitType.DEALER_ONLY &&
      dealershipName != null
    ) {
      // payee must be the same for all products
      setPayeeNames(dealershipName, products);
    }
  }

  return { onIncentivePayTypeChange, onIndividualPayeeChange, onProductionIncentiveProductsChange };
}

function useDisabledFields<FormValues = NewDealerProductsValues | UpgradeDealerProductValues>(): {
  productionIncentiveProducts: boolean;
  productionIncentivePayType: boolean;
  incentivePayeeVSC: boolean;
  incentivePayeeGAP: boolean;
  incentivePayeeTWP: boolean;
} {
  const { values } = useFormikContext<NewDealerProductsValues | UpgradeDealerProductValues>();
  const productionIncentiveNotSelected = !values.productionIncentive;

  const disablePayee = (productSelected: boolean): boolean => {
    return (
      productionIncentiveNotSelected ||
      values.productionIncentivePayType === ProductionIncentiveSplitType.DEALER_ONLY ||
      (productSelected &&
        values.productionIncentivePayType === ProductionIncentiveSplitType.INDIVIDUAL_ONLY)
    );
  };

  return {
    productionIncentiveProducts: productionIncentiveNotSelected,
    productionIncentivePayType:
      productionIncentiveNotSelected ||
      values.productionIncentiveProducts == null ||
      values.productionIncentiveProducts.length === 0,
    incentivePayeeVSC: disablePayee(
      dealerService.hasVSCProduct(values.productionIncentiveProducts) &&
        values.productionIncentiveProducts?.length > 1,
    ),
    incentivePayeeGAP: disablePayee(
      dealerService.hasGAPProduct(values.productionIncentiveProducts) &&
        values.productionIncentiveProducts?.length > 1,
    ),
    incentivePayeeTWP: disablePayee(
      dealerService.hasTWPProduct(values.productionIncentiveProducts) &&
        values.productionIncentiveProducts?.length > 1,
    ),
  };
}

const useShowFields = (): {
  showFields: Set<ProductionIncentiveFields>;
} => {
  const { values } = useFormikContext<NewDealerProductsValues | UpgradeDealerProductValues>();
  const showFields = new Set<ProductionIncentiveFields>();

  if (values.productionIncentive != null && values.productionIncentive) {
    showFields.add('productionIncentive');
    if (values.productionIncentive && values.productionIncentivePayType != null) {
      if (dealerService.hasVSCProduct(values.productionIncentiveProducts)) {
        showFields.add('incentiveAmountVSC');
        showFields.add('incentivePayeeVSC');
      }

      if (dealerService.hasGAPProduct(values.productionIncentiveProducts)) {
        showFields.add('incentiveAmountGAP');
        showFields.add('incentivePayeeGAP');
      }

      if (dealerService.hasTWPProduct(values.productionIncentiveProducts)) {
        showFields.add('incentiveAmountTWP');
        showFields.add('incentivePayeeTWP');
      }

      if (
        [
          ProductionIncentiveSplitType.INDIVIDUAL_ONLY,
          ProductionIncentiveSplitType.SPLIT_DEALER_INDIVIDUAL,
          ProductionIncentiveSplitType.SPLIT_INDIVIDUALS,
        ].includes(values.productionIncentivePayType)
      ) {
        showFields.add('dealerW9Forms');
      }

      if (
        ProductionIncentiveSplitType.INDIVIDUAL_ONLY === values.productionIncentivePayType &&
        values.productionIncentiveProducts?.length > 1
      ) {
        showFields.add('individualIncentivePayee');
      }
    }
  }

  return { showFields };
};

export default function ProductionIncentive<
  FormValues = NewDealerProductsValues | UpgradeDealerProductValues
>({
  productionIncentiveProductsOptions,
}: {
  productionIncentiveProductsOptions: dealerService.DealerProduct[];
}) {
  const { showFields } = useShowFields();
  const { values } = useFormikContext<NewDealerProductsValues | UpgradeDealerProductValues>();
  const { w9SignerNames, dealershipName } = useW9SignerNames<FormValues>();
  const disabledFields = useDisabledFields<FormValues>();
  const {
    onIncentivePayTypeChange,
    onIndividualPayeeChange,
    onProductionIncentiveProductsChange,
  } = useFormOnChangeHandlers<FormValues>();

  if (!showFields?.has('productionIncentive')) {
    return (
      <Field
        name="productionIncentive"
        component={ValidationSelect}
        options={yesNoOptions}
        label="Does the dealer want to have a production incentive?"
        placeholder="Please choose an option"
      />
    );
  }

  return (
    <Grid fluid>
      {dealershipName == null && (
        <Alert className="alert-danger">
          Dealership name must be provided for Production Incentives, please go back and provide a
          dealership name before continuing.
        </Alert>
      )}
      <Field
        name="productionIncentive"
        component={ValidationSelect}
        options={yesNoOptions}
        label="Does the dealer want to have a production incentive?"
        placeholder="Please choose an option"
      />

      <FieldArray
        name="productionIncentiveProducts"
        render={props => (
          // @ts-expect-error TS2322
          <ValidationMultiCheckbox
            {...props}
            options={productionIncentiveProductsOptions}
            disabled={disabledFields.productionIncentiveProducts}
            label="What products will the production incentive be appointed for?"
            onChange={onProductionIncentiveProductsChange}
          />
        )}
      />
      <Field
        name="productionIncentivePayType"
        component={ValidationSelect}
        options={productionIncentiveSplitOptions}
        disabled={disabledFields.productionIncentivePayType}
        label="What type of production incentive?"
        placeholder="Please choose an option"
        onChange={onIncentivePayTypeChange}
      />

      {showFields?.has('individualIncentivePayee') && (
        <Field
          name="individualIncentivePayee"
          component={ValidationInput}
          type="text"
          label="Who is the individual payee?"
          placeholder={'Provide recipients First/Last name, separate multiples by comma'}
          onChange={onIndividualPayeeChange}
        />
      )}

      {showFields?.has('incentiveAmountVSC') && (
        <Field
          name="incentiveAmountVSC"
          component={ValidationInput}
          type="number"
          min={0}
          label="What is the VSC incentive amount?"
        />
      )}
      {showFields?.has('incentivePayeeVSC') && (
        <Field
          name="incentivePayeeVSC"
          component={ValidationInput}
          type="text"
          disabled={disabledFields.incentivePayeeVSC}
          label="Who is the VSC incentive payee?"
          placeholder={'Provide recipients First/Last name, separate multiples by comma'}
        />
      )}

      {showFields?.has('incentiveAmountGAP') && (
        <Field
          name="incentiveAmountGAP"
          component={ValidationInput}
          type="number"
          min={0}
          label="What is the GAP incentive amount?"
        />
      )}
      {showFields?.has('incentivePayeeGAP') && (
        <Field
          name="incentivePayeeGAP"
          component={ValidationInput}
          type="text"
          disabled={disabledFields.incentivePayeeGAP}
          label="Who is the GAP incentive payee?"
          placeholder={'Provide recipients First/Last name, separate multiples by comma'}
        />
      )}

      {showFields?.has('incentiveAmountTWP') && (
        <Field
          name="incentiveAmountTWP"
          component={ValidationInput}
          type="number"
          min={0}
          label="What is the TWP incentive amount?"
        />
      )}
      {showFields?.has('incentivePayeeTWP') && (
        <Field
          name="incentivePayeeTWP"
          component={ValidationInput}
          type="text"
          disabled={disabledFields.incentivePayeeTWP}
          label="Who is the TWP incentive payee?"
          placeholder={'Provide recipients First/Last name, separate multiples by comma'}
        />
      )}

      {showFields?.has('dealerW9Forms') && (
        <FieldArray
          name="dealerW9Forms"
          render={props => (
            // @ts-expect-error TS2322
            <DealerW9Field
              label="Dealer W9 Forms"
              defaultValue={[]}
              signerNames={w9SignerNames}
              dealershipName={dealershipName}
              {...props}
            />
          )}
        />
      )}

      <Field
        name="specialRateTypeNotes"
        component={ValidationTextarea}
        type="text"
        label="Special Rate Details"
        placeholder={
          [
            ProductionIncentiveSplitType.SPLIT_DEALER_INDIVIDUAL,
            ProductionIncentiveSplitType.SPLIT_INDIVIDUALS,
          ].includes(values.productionIncentivePayType)
            ? 'Please define how the incentive(s) amount(s) above should be split between the business or individuals'
            : 'Notes about your special rates request'
        }
      />
    </Grid>
  );
}
