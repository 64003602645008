import { Field, useFormikContext } from 'formik';
import { yesNoOptions } from '../constants';
import ValidationSelect from '../../../components/forms/inputs/ValidationSelect';
import ValidationInput from '../../../components/forms/inputs/ValidationInput';
import React from 'react';
import * as Yup from 'yup';
import { UpgradeDealerProductValues } from '../UpgradeDealer/UpgradeDealerProducts';

export const penIntegrationValidators = {
  hasPENIntegration: Yup.boolean()
    .defined('Please select if dealer has PEN Integration')
    .label('PEN Integration'),
  penIntegrationSystem: Yup.string()
    .label('PEN Integration System')
    .when('hasPENIntegration', {
      is: true,
      then: Yup.string().required('Please provide the PEN system name'),
    }),
};

const useShowFields = (): {
  showFields: Set<'penIntegrationSystem'>;
} => {
  const { values } = useFormikContext<UpgradeDealerProductValues>();

  const showFields = new Set<'penIntegrationSystem'>();

  if (values.hasPENIntegration) {
    showFields.add('penIntegrationSystem');
  }

  return { showFields };
};

export default function PENIntegration() {
  const { showFields } = useShowFields();

  return (
    <>
      <Field
        name="hasPENIntegration"
        label="Is the dealer using a menu or other platform that is integrated with PEN?"
        placeholder="Please choose an option"
        options={yesNoOptions}
        component={ValidationSelect}
      />
      {showFields.has('penIntegrationSystem') && (
        <Field
          name="penIntegrationSystem"
          label="What is the name of the PEN Integrated System?"
          placeholder="Enter platform using PEN"
          component={ValidationInput}
        />
      )}
    </>
  );
}
