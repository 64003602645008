import React, { InputHTMLAttributes } from 'react';
import { FieldArrayRenderProps } from 'formik';
import { Badge, Col, FormGroup } from 'react-bootstrap';
import DealerW9FormUploader from './DealerW9FormUploader';
import InputError, { getFieldValidationState } from '../../../components/forms/inputs/InputError';

export type DealerW9FieldInput = {
  label: string;
  dealershipName: string;
  signerNames: string[];
} & FieldArrayRenderProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'children'>;

export default function DealerW9Field({
  form: { errors, touched, setFieldTouched, values },
  name,
  push,
  // remove,
  dealershipName,
  signerNames,
  disabled,
  label,
}: DealerW9FieldInput): React.ReactElement {
  const value = values[name];
  const defaultValue = Array.isArray(value) ? value : [];
  const fieldError = errors[name];
  const fieldTouched = touched[name];

  const handleUpload = files => {
    files.forEach(file => push(file));
    setFieldTouched(name, true);
  };

  return (
    <FormGroup
      controlId={name}
      validationState={getFieldValidationState({ fieldError, fieldTouched })}
    >
      <Col xs={3}>
        <label htmlFor={name}>{label}</label>
      </Col>
      <Col xs={9}>
        <div>
          {signerNames.map((name, index) => (
            <Badge key={`${name}-${index}`} style={{ margin: 4 }}>
              {name}
            </Badge>
          ))}
        </div>
        <DealerW9FormUploader
          // prevent a bad value from being injected by redux-form
          value={defaultValue}
          dealershipName={dealershipName}
          signerNames={signerNames}
          onUpload={handleUpload}
          disabled={disabled}
        />
        <InputError fieldName={name} />
      </Col>
    </FormGroup>
  );
}
