import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Alert } from 'react-bootstrap';
import { partial, get } from 'lodash';
import { TemplateUpload, FieldListSelection, TemplateMetaDataForm } from './steps';
import {
  toggleUploadTemplateModal,
  saveTemplateAndMetaData,
  getTemplateExampleFile,
  acceptedFiles,
  disableSave,
  clearSavedTemplateAndValidationInfo,
} from '../../redux/modules/formTemplateAdmin';
import TemplateWizardBreadcrumbs from './TemplateWizardBreadcrumbs';

const mapStateToProps = ({ form, formTemplateAdmin }) => ({
  showUploadTemplateModal: formTemplateAdmin.showUploadTemplateModal,
  acceptedFiles: acceptedFiles({ formTemplateAdmin }),
  disableSave: disableSave({ form, formTemplateAdmin }),
  templateExampleFileName: formTemplateAdmin.templateValidationReturn,
  savedTemplate: formTemplateAdmin.savedTemplate,
});

const TEMPLATE_WIZARD_PAGES = [
  { page: 'upload', component: TemplateUpload },
  { page: 'template-data', component: TemplateMetaDataForm },
  { page: 'field-selection', component: FieldListSelection },
];

class TemplateWizard extends PureComponent {
  /*
   * steps:
   * 1. react-dropzone - drop file
   * 2. select fields and preferences
   * 3. send file
   * 4. view results
   * 5. conduct additional actions
   *
   */
  state = {
    page: 'upload',
  };

  handlePageClick = page => {
    this.setState({ page });
  };

  render() {
    const { page: activePage } = this.state;
    const {
      showUploadTemplateModal,
      toggleUploadTemplateModal,
      saveTemplateAndMetaData,
      disableSave,
      templateExampleFileName,
      getTemplateExampleFile,
      savedTemplate,
      clearSavedTemplateAndValidationInfo,
    } = this.props;

    const closeActions = (
      <React.Fragment>
        <Button
          bsStyle="danger"
          onClick={() => {
            toggleUploadTemplateModal(false);
            if (savedTemplate && savedTemplate.id) {
              this.handlePageClick('upload');
            }
          }}
        >
          Close
        </Button>
        <Button
          bsStyle="danger"
          onClick={() => {
            toggleUploadTemplateModal(false);
            clearSavedTemplateAndValidationInfo();
            this.handlePageClick('upload');
          }}
        >
          Close & Clear
        </Button>
      </React.Fragment>
    );

    return (
      <Modal
        bsSize="large"
        show={showUploadTemplateModal}
        onHide={() => toggleUploadTemplateModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>E-Sign Admin Template Wizard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TemplateWizardBreadcrumbs
            items={TEMPLATE_WIZARD_PAGES}
            activePage={activePage}
            onClick={this.handlePageClick}
          />
          {TEMPLATE_WIZARD_PAGES.map(({ page, component: Component }, idx) => {
            const prevPage = get(TEMPLATE_WIZARD_PAGES, `${idx - 1 > -1 ? idx - 1 : 0}.page`);
            const nextPage = get(
              TEMPLATE_WIZARD_PAGES,
              `${
                idx + 1 <= TEMPLATE_WIZARD_PAGES.length ? idx + 1 : TEMPLATE_WIZARD_PAGES.length
              }.page`,
            );
            const goForward = partial(this.handlePageClick, nextPage);
            const formProps = page === 'template-data' ? { onSubmit: goForward } : {};
            return (
              page === activePage && (
                <Component
                  key={page}
                  goBack={partial(this.handlePageClick, prevPage)}
                  goForward={partial(this.handlePageClick, nextPage)}
                  {...formProps}
                />
              )
            );
          })}
        </Modal.Body>
        <Modal.Footer style={{ textAlign: 'left' }}>
          {templateExampleFileName ? (
            <Alert className="alert-success">
              <p>Your template is valid, click "Save Template" to finish the process.</p>
              <p>
                {savedTemplate &&
                  savedTemplate.id &&
                  `Template (${savedTemplate.fileName}) was saved.`}
              </p>
              <p>
                {closeActions}
                <Button
                  bsStyle="primary"
                  title={`Download example template with name ${templateExampleFileName}`}
                  onClick={() => getTemplateExampleFile(templateExampleFileName)}
                >
                  Download Example
                </Button>
                <Button
                  disabled={disableSave}
                  bsStyle="primary"
                  title="You must Upload and QC (see field-selection to submit) your template before saving"
                  onClick={saveTemplateAndMetaData}
                >
                  Save Template
                </Button>
              </p>
            </Alert>
          ) : (
            closeActions
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, {
  toggleUploadTemplateModal,
  saveTemplateAndMetaData,
  getTemplateExampleFile,
  clearSavedTemplateAndValidationInfo,
})(TemplateWizard);
