import React from 'react';
import { Field } from 'formik';
import ValidationInput from '../../../components/forms/inputs/ValidationInput';
import FirstLastNameInputs from './FirstLastNameInputs';

interface Props {
  disableContactEmail?: boolean;
}

export default function DocumentSigner({ disableContactEmail = false }: Props) {
  return (
    <>
      <FirstLastNameInputs
        label="Document Signer"
        firstNameProps={{ name: 'documentSignerFirstName', required: true }}
        lastNameProps={{ name: 'documentSignerLastName', required: true }}
      />
      <Field
        required
        name="documentSignersPositionTitle"
        label="Signer's Position Title"
        placeholder="Manager, GM, F&I, etc."
        component={ValidationInput}
      />

      <Field
        required
        name="dealerContactEmail"
        disabled={disableContactEmail}
        label="Contact Email for Statements and Correspondence"
        placeholder="Document signer's email"
        component={ValidationInput}
      />
    </>
  );
}
