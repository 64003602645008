import { getCityStateZipFromDetails } from '../dealerDetails';
import { DealerDetails, DealerProduct, DealerDetailsFormInputs } from '../types';
import { EMPTY_DATE } from '../constants';

const checkIfNewProduct = (
  product: DealerProduct,
  currentProductEffectiveDate: string,
  appointedProducts: DealerProduct[],
): boolean => currentProductEffectiveDate === EMPTY_DATE && appointedProducts.includes(product);

export default function useDealerDetailsAsFormInputs(
  dealerDetails: DealerDetails,
  appointedProducts: DealerProduct[],
): DealerDetailsFormInputs {
  const { city, state, zip } = getCityStateZipFromDetails(dealerDetails.dlCityStzip);
  const vscIsNewProduct = checkIfNewProduct(
    DealerProduct.VSC,
    dealerDetails.vscEff,
    appointedProducts,
  );
  const gapIsNewProduct = checkIfNewProduct(
    DealerProduct.GAP,
    dealerDetails.gapEff,
    appointedProducts,
  );
  const twpIsNewProduct = checkIfNewProduct(
    DealerProduct.TWP,
    dealerDetails.twpEff,
    appointedProducts,
  );

  const [ownerFirstName, ...ownerRestNames] = dealerDetails.dlOwner.trim().split(' ');
  return {
    prcoDealerId: dealerDetails.dlid,
    dealershipName: dealerDetails.dlCompany,
    ownerFirstName,
    ownerLastName: ownerRestNames.join(' '),
    dealerContactEmail: dealerDetails.dlEmail,
    streetAddress: dealerDetails.dlAddress,
    city,
    state,
    zip: parseInt(zip, 10),
    telephone: dealerDetails.dlPhone,
    hasNewProduct: vscIsNewProduct || gapIsNewProduct || twpIsNewProduct,
  };
}
