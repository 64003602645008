import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { load } from 'redux-load-api';
import _ from 'lodash';
import store from './redux/store';
import { AuthProvider, makeRouterRedirects } from './components/Auth';
import { clearAllMessages, clearAllErrors } from './redux/modules';
import { clearForms } from './redux/modules/form';
import { infoDismiss } from './redux/modules/info';
import { notifClearAll } from './redux/modules/notifs';
import App from './containers/App';
import { ProfilePage, ProfileCompletePopup } from './containers/Profile';
import ActiveDealers from './containers/active-dealers/ActiveDealers';
import DealerStatements from './containers/active-dealers/DealerStatements';
import NewDealerView from './containers/NewDealerView';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import OperationComplete from './containers/OperationComplete';
import UserAdmin from './containers/UserAdmin';
import FormTemplateAdmin from './containers/form-template-admin/FormTemplateAdmin';
import IssuedContracts from './containers/issued-contracts/IssuedContracts';

import 'react-datetime/css/react-datetime.css'; // eslint-disable-line import/first
import './index.css';

// @deprecated TODO delete when Auth0 migration is over.
// TODO if it's not 2021, it's time to delete it.
import { MigrateFrom219ApiToAuth0 } from './containers/MigrateFrom219ApiToAuth0';

// Enhanced history
const history = syncHistoryWithStore(browserHistory, store);

const { userAuth, prcoAdminAuth } = makeRouterRedirects();

const onRouteChange = () => {
  store.dispatch(infoDismiss());
  store.dispatch(notifClearAll());
  store.dispatch(clearForms());
  store.dispatch(clearAllMessages());
  store.dispatch(clearAllErrors());

  window.scrollTo(0, 0);
  if (_.isFunction(_.get(window, 'parent.postMessage'))) {
    window.parent.postMessage('SCROLL_TOP', '*');
  }
};

load([App]);

// TODO: Use dash case for routes
ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <ProfileCompletePopup />
      <Router history={history}>
        <Route path="/" component={App} onChange={onRouteChange}>
          {/* Unauthenticated Routes */}
          <IndexRoute component={Home} />
          <Route path="operationComplete" component={OperationComplete} />
          {
            // @deprecated TODO delete when Auth0 migration is over.
            // TODO if it's not 2021, it's time to delete it.
          }
          <Route path="MigrateFrom219ApiToAuth0" component={MigrateFrom219ApiToAuth0} />

          {/* Authenticated Routes */}
          <Route path="profile" component={userAuth(ProfilePage)} />
          <Route path="active-dealers" component={userAuth(ActiveDealers)} />
          <Route path="new-dealer" component={userAuth(NewDealerView)} />
          <Route path="dealers/:dealerId/statements" component={userAuth(DealerStatements)} />

          {/* Admin Routes */}
          <Route path="admin/users" component={prcoAdminAuth(UserAdmin)} />
          <Route path="admin/form-templates" component={prcoAdminAuth(FormTemplateAdmin)} />
          <Route path="admin/issued-contracts" component={prcoAdminAuth(IssuedContracts)} />

          {/* Catch all route */}
          <Route path="*" component={NotFound} status={404} />
        </Route>
      </Router>
    </AuthProvider>
  </Provider>,
  document.getElementById('root'),
);
