import { NewDealerInformationValues } from './NewDealerInformation';
import { NewDealerContactValues } from './NewDealerContact';
import { NewDealerContextValues } from './NewDealerContext';
import { NewDealerProductsValues } from './NewDealerProducts';

export enum NewDealerPage {
  DEALER_INFORMATION = 'Dealer Information',
  DEALER_CONTACT = 'Dealer Contact',
  DEALER_CONTEXT = 'Dealer Context',
  DEALER_PRODUCTS = 'Dealer Products',
  DEALER_CHECK = 'Dealer Check',
}
export type NewDealerFormInputsByPage = Record<
  NewDealerPage.DEALER_INFORMATION,
  NewDealerInformationValues
> &
  Record<NewDealerPage.DEALER_CONTACT, NewDealerContactValues> &
  Record<NewDealerPage.DEALER_CONTEXT, NewDealerContextValues> &
  Record<NewDealerPage.DEALER_PRODUCTS, NewDealerProductsValues>;

export type NewDealerFormInputs = NewDealerInformationValues &
  NewDealerContactValues &
  NewDealerContextValues &
  NewDealerProductsValues;
