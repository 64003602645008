const LOAD_QUERY_PARAMS = 'redux/queryParams/LOAD_QUERY_PARAMS';

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_QUERY_PARAMS:
      return {
        ...state,
        loaded: true,
      };
    default:
      return state;
  }
}

export function loadQueryParams() {
  return {
    type: LOAD_QUERY_PARAMS,
  };
}
