import { get, has, unionBy, isEmpty, first, some, every, partial } from 'lodash';
import customSelector from '../../utils/selector';
import api from '../../utils/service';
import { openDownload } from '../../utils/download';
import { isActuallyEmpty } from '../../utils/object';
import { startLoading, stopLoading } from './page';

export const DISPLAY_TEMPLATE_ERROR = 'DISPLAY_TEMPLATE_ERROR';
export const displayTemplateError = payload => ({
  type: DISPLAY_TEMPLATE_ERROR,
  payload,
});

export const TOGGLE_UPLOAD_TEMPLATE_MODAL = 'TOGGLE_UPLOAD_TEMPLATE_MODAL';
export const toggleUploadTemplateModal = payload => ({
  type: TOGGLE_UPLOAD_TEMPLATE_MODAL,
  payload,
});

export const STORE_SELECTED_TEMPLATE_FILE_DATA = 'STORE_SELECTED_TEMPLATE_FILE_DATA';
export const storeSelectedTemplateFileData = payload => ({
  type: STORE_SELECTED_TEMPLATE_FILE_DATA,
  payload,
});

export const STORE_TEMPLATE_UPLOADS = 'STORE_TEMPLATE_UPLOADS';
export const storeTemplateUploads = (accepted, rejected) => dispatch => {
  if (accepted.length === 1) {
    dispatch(storeSelectedTemplateFileData(first(accepted)));
  }
  dispatch({ type: STORE_TEMPLATE_UPLOADS, payload: { accepted, rejected } });
};

export const GET_DEFAULT_FIELD_LIST_FOR_TEMPLATE = 'GET_DEFAULT_FIELD_LIST_FOR_TEMPLATE';
export const SET_SELECTED_FIELDS = 'SET_SELECTED_FIELDS';
export const getDefaultFieldListForTemplate = payload => async dispatch => {
  try {
    const { data } = await api.get('/actions/templates/fields', { params: payload });
    dispatch({ type: GET_DEFAULT_FIELD_LIST_FOR_TEMPLATE, payload: data.formFields || [] });
    dispatch({
      type: SET_SELECTED_FIELDS,
      payload: (data.formFields || []).map(({ fieldName }) => fieldName),
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    dispatch(displayTemplateError(err.toString()));
  }
};

export const TOGGLE_ALL_FIELDS_ARE_EXPECTED = 'TOGGLE_ALL_FIELDS_ARE_EXPECTED';
export const toggleAllFieldsAreExpected = payload => ({
  type: TOGGLE_ALL_FIELDS_ARE_EXPECTED,
  payload,
});

export const TOGGLE_ALL_FIELDS_ARE_REQUIRED = 'TOGGLE_ALL_FIELDS_ARE_REQUIRED';
export const toggleAllFieldsAreRequired = payload => ({
  type: TOGGLE_ALL_FIELDS_ARE_REQUIRED,
  payload,
});

export const STORE_SELECTED_TEMPLATE_FIELD = 'STORE_SELECTED_TEMPLATE_FIELD';
export const storeSelectedTemplateField = ({ fieldName }) => ({
  type: STORE_SELECTED_TEMPLATE_FIELD,
  payload: fieldName,
});

export const LOADING_TEMPLATES = 'LOADING_TEMPLATES';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const getTemplates = params => async dispatch => {
  try {
    /* { text, sort, order, fields } */
    dispatch(startLoading());
    const { data } = await api.get('/actions/templates/search/', { params });
    dispatch({ type: GET_TEMPLATES, payload: data.records });
    dispatch(stopLoading());
  } catch (err) {
    dispatch(displayTemplateError(err.toString()));
    dispatch(stopLoading());
  }
};

export const deleteTemplate = id => async dispatch => {
  try {
    dispatch(startLoading());
    await api.delete(`/templates/${id}`);
    const { data } = await api.get('/actions/templates/search/');
    dispatch({ type: GET_TEMPLATES, payload: data.records });
    dispatch(stopLoading());
  } catch (err) {
    dispatch(displayTemplateError(err.toString()));
    dispatch(stopLoading());
  }
};

export const SAVE_TEMPLATE_AND_META_DATA = 'SAVE_TEMPLATE_AND_META_DATA';
export const saveTemplateAndMetaData = () => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const {
      formTemplateAdmin,
      form: { templateMetaData = {} },
    } = getState() || {};
    const { values } = templateMetaData;
    const { templateFileData } = formTemplateAdmin || {};
    const fileForm = new FormData();
    fileForm.append('templateFile', templateFileData);
    fileForm.append('metaData', JSON.stringify(values));
    const { data } = await api.put('/actions/templates/save', fileForm);
    dispatch(stopLoading());
    dispatch({ type: SAVE_TEMPLATE_AND_META_DATA, payload: data });
    dispatch(getTemplates());
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    dispatch(displayTemplateError(err.toString()));
    dispatch(stopLoading());
  }
};

export const POST_TEMPLATE_FOR_FIELD_VALIDATION = 'POST_TEMPLATE_FOR_FIELD_VALIDATION';
export const postTemplateForFieldValidation = () => async (dispatch, getState) => {
  try {
    const { formTemplateAdmin } = getState() || {};
    const {
      templateFileData,
      selectedFields,
      allTemplateFieldsAreExpected,
      allTemplateFieldsAreRequired,
    } = formTemplateAdmin || {};
    const fileForm = new FormData();
    fileForm.append('templateFile', templateFileData);
    fileForm.append(
      'metaData',
      JSON.stringify({
        fields: selectedFields,
        allFieldsExpected: allTemplateFieldsAreExpected,
        allFieldsRequired: allTemplateFieldsAreRequired,
      }),
    );
    const { data } = await api.post('/actions/templates/validate', fileForm);
    const { message, valid, fileName } = data || {};
    if (valid) {
      return dispatch({ type: POST_TEMPLATE_FOR_FIELD_VALIDATION, payload: fileName });
    }
    dispatch(displayTemplateError(`Template is not valid: ${message}`));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    const { response } = err || {};
    if (response && response.data) {
      return dispatch(
        displayTemplateError(
          response.data.message || `Error during template validation: ${err.toString()}`,
        ),
      );
    }
    dispatch(displayTemplateError(err.toString()));
  }
};

export const getTemplateExampleFile = templateExampleFileName => async (/* dispatch */) => {
  const response = await api.get(`/actions/templates/validated/${templateExampleFileName}`, {
    responseType: 'blob',
  });
  openDownload(response.data, templateExampleFileName);
};

// export const VIEW_TEMPLATE = 'VIEW_TEMPLATE';
export const viewTemplate = (id, name) => async dispatch => {
  try {
    const response = await api.get(`/actions/templates/view/${id}`, { responseType: 'blob' });
    openDownload(response.data, name);
  } catch (err) {
    dispatch(displayTemplateError(err.toString()));
  }
};

export const STORE_TEMPLATE_SEARCH_TEXT = 'STORE_TEMPLATE_SEARCH_TEXT';
export const storeTemplateSearchText = payload => ({
  type: STORE_TEMPLATE_SEARCH_TEXT,
  payload,
});

export const CLEAR_SAVED_TEMPLATE_AND_VALIDATION_INFO =
  'formTemplateAdmin/CLEAR_SAVED_TEMPLATE_AND_VALIDATION_INFO';
export const clearSavedTemplateAndValidationInfo = () => ({
  type: CLEAR_SAVED_TEMPLATE_AND_VALIDATION_INFO,
});

const files = ({ formTemplateAdmin }) => formTemplateAdmin.files;
const validatedFileName = ({ formTemplateAdmin }) => formTemplateAdmin.templateValidationReturn;
const metaDataValues = ({ form }) => get(form, 'templateMetaData.values', {});

export const acceptedFiles = customSelector(files, (f = {}) => f.accepted);

export const disableUpload = customSelector(files, metaDataValues, (f = {}, md) => {
  const noFiles = isEmpty(f.accepted);
  const missingFields = !every(['templateType'], partial(has, md));
  const missingValues = some(md, isActuallyEmpty);
  return noFiles || missingFields || missingValues;
});

export const disableSave = customSelector(
  files,
  metaDataValues,
  validatedFileName,
  (f = {}, md, vfn) => {
    const noFiles = isEmpty(f.accepted);
    const missingFields = !every(['templateType'], partial(has, md));
    const missingValues = some(md, isActuallyEmpty);
    return !vfn || noFiles || missingFields || missingValues;
  },
);
const initialState = {
  showUploadTemplateModal: false,
  allTemplateFieldsAreExpected: true,
  allTemplateFieldsAreRequired: false,
  selectedFields: [],
  templateFileData: {},
  templateList: [],
  templateValidationReturn: null,
  savedTemplate: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TEMPLATES:
      return {
        ...state,
        templateList: payload,
      };
    case TOGGLE_UPLOAD_TEMPLATE_MODAL:
      return {
        ...state,
        showUploadTemplateModal: payload,
      };
    case STORE_SELECTED_TEMPLATE_FILE_DATA:
      return {
        ...state,
        templateFileData: payload,
      };
    case STORE_TEMPLATE_UPLOADS:
      return {
        ...state,
        files: payload,
      };
    case GET_DEFAULT_FIELD_LIST_FOR_TEMPLATE:
      return {
        ...state,
        fields: payload,
      };
    case TOGGLE_ALL_FIELDS_ARE_EXPECTED:
      return {
        ...state,
        allTemplateFieldsAreExpected: payload,
      };
    case TOGGLE_ALL_FIELDS_ARE_REQUIRED:
      return {
        ...state,
        allTemplateFieldsAreRequired: payload,
      };
    case SET_SELECTED_FIELDS:
      return {
        ...state,
        selectedFields: payload,
      };
    case STORE_SELECTED_TEMPLATE_FIELD:
      return {
        ...state,
        selectedFields: state.selectedFields.includes(payload)
          ? state.selectedFields.filter(i => i !== payload)
          : unionBy([payload], state.selectedFields),
      };
    case POST_TEMPLATE_FOR_FIELD_VALIDATION:
      return {
        ...state,
        templateValidationReturn: payload,
        error: false,
      };
    case SAVE_TEMPLATE_AND_META_DATA:
      return {
        ...state,
        savedTemplate: payload,
      };
    case DISPLAY_TEMPLATE_ERROR:
      return {
        ...state,
        error: payload,
      };
    case STORE_TEMPLATE_SEARCH_TEXT:
      return {
        ...state,
        templateSearchText: payload,
      };
    case CLEAR_SAVED_TEMPLATE_AND_VALIDATION_INFO:
      return {
        ...state,
        files: {},
        templateValidationReturn: null,
        savedTemplate: null,
      };
    default:
      return state;
  }
};
