import _ from 'lodash';
import api from '../../utils/service';

export function verifyEmail(email) {
  return api
    .get('/actions/emails/verify-email', {
      params: {
        email,
      },
    })
    .then(({ data }) => {
      const verified = _.get(data, 'verified');
      if (!verified) {
        const message = _.get(data, 'message');
        throw new Error(message);
      }
    })
    .catch(error => {
      const message = _.get(error, 'response.data.message', _.get(error, 'message'));
      throw new Error(message);
    });
}
