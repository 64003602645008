import _ from 'lodash';
import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as form } from 'redux-form';
import auth from './auth';
import deployment from './deployment';
import info from './info';
import notifs from './notifs';
import page from './page';
import queryParams from './queryParams';
import user from './user';
import data from './data';
import statements from './statements';
import formTemplateAdmin from './formTemplateAdmin';

const CLEAR_ALL_MESSAGES = 'redux/root/CLEAR_ALL_MESSAGES';
const CLEAR_ALL_ERRORS = 'redux/root/CLEAR_ALL_ERRORS';

const appReducer = combineReducers({
  auth,
  deployment,
  form,
  info,
  notifs,
  page,
  queryParams,
  routing,
  user,
  data,
  statements,
  formTemplateAdmin,
});

export function clearAllMessages() {
  return dispatch => dispatch({ type: CLEAR_ALL_MESSAGES });
}

export function clearAllErrors() {
  return dispatch => dispatch({ type: CLEAR_ALL_ERRORS });
}

function rootReducer(state, action) {
  switch (action.type) {
    case CLEAR_ALL_MESSAGES:
      return appReducer(
        _.mapValues({ ...state }, subState => ({
          ...subState,
          message: null,
        })),
        action,
      );
    case CLEAR_ALL_ERRORS:
      return appReducer(
        _.mapValues({ ...state }, subState => ({
          ...subState,
          error: null,
        })),
        action,
      );
    default:
      return appReducer(state, action);
  }
}

export default rootReducer;
