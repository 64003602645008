import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Modal, Button } from 'react-bootstrap';
import { toggleDealerDetailsModal } from '../../redux/modules/statements';
import DealerDetailCurrentProducts from './DealerDetailCurrentProducts';
import UpgradeDealerForm from '../forms/UpgradeDealer/UpgradeDealerForm';
import UpdateDealerForm from '../forms/UpdateDealer/UpdateDealerForm';
import * as dealerService from '../../services/dealerService';
import {
  createDealerProductUpgradeRequest,
  isDealerHasNoContractEffective,
} from '../../redux/modules/dealer';
import NotificationModal from '../../components/common/NotificationModal';

const toState = ({ statements }) => ({
  dealerDetails: statements.showDealerDetailsModal,
});

const ModalInner = ({ dealerDetails, onSubmitConfirmation, createDealerProductUpgradeRequest }) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [upgradeDealer, setUpgradeDealer] = React.useState();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const upgradeEligibleProducts = dealerService.hooks.useEligibleUpgradeProducts(dealerDetails);

  const hasNoUpgrades = Object.values(upgradeEligibleProducts).every(v =>
    Array.isArray(v) ? v.length === 0 : !v,
  );

  const handleUpgrade = formValues => {
    if (isSubmitting) {
      // noop - we're submitting already
      return;
    }
    setUpgradeDealer(formValues);
    setShowConfirmation(true);
    try {
      setIsSubmitting(true);
      createDealerProductUpgradeRequest(formValues);
    } catch (err) {
      setShowConfirmation(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const shouldUpdate = isDealerHasNoContractEffective(dealerDetails);

  let editEl;
  if (edit && shouldUpdate)
    editEl = (
      <UpdateDealerForm onDealerUpdated={onSubmitConfirmation} dealerDetails={dealerDetails} />
    );
  else if (edit)
    editEl = <UpgradeDealerForm onSubmit={handleUpgrade} dealerDetails={dealerDetails} />;

  return (
    <div>
      <DealerDetailCurrentProducts dealerDetails={dealerDetails} />
      <hr />
      {editEl}
      {!editEl && (
        <div style={{ width: '100%' }}>
          <Button
            block
            bsStyle={shouldUpdate ? 'warning' : 'success'}
            onClick={() => setEdit(true)}
            disabled={!shouldUpdate && hasNoUpgrades}
          >
            {shouldUpdate ? 'Update Dealer' : 'Upgrade Dealer Products'}
          </Button>
        </div>
      )}
      <NotificationModal
        show={showConfirmation}
        title={`${upgradeDealer?.dealershipName} Submitted`}
        confirmText={'Continue to Dashboard'}
        message={
          <div>
            Forms will be sent via email from One Span Sign for signatures
            <div>
              <ul>
                <li>
                  Dealer Contact: {upgradeDealer?.documentSigner} (
                  {upgradeDealer?.dealerContactEmail})
                </li>
                <li>
                  Dealer Owner: {upgradeDealer?.ownerFirstName}
                  {upgradeDealer?.ownerLastName} ({upgradeDealer?.ownerEmail})
                </li>
              </ul>
            </div>
          </div>
        }
        onConfirm={() => {
          setShowConfirmation(false);
          onSubmitConfirmation();
        }}
      />
    </div>
  );
};

const DealerDetails = ({ dealerDetails, createDealerProductUpgradeRequest, onClose }) => (
  <Modal
    show={dealerDetails != null}
    aria-labelledby="dealer-details-modal"
    bsSize="lg"
    onHide={() => {
      onClose();
    }}
    backdrop="static"
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title">{get(dealerDetails, 'dlCompany')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {dealerDetails != null && (
        <ModalInner
          dealerDetails={dealerDetails}
          createDealerProductUpgradeRequest={createDealerProductUpgradeRequest}
          onSubmitConfirmation={onClose}
        />
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button
        bsStyle="danger"
        onClick={() => {
          onClose();
        }}
      >
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default connect(toState, {
  onClose: toggleDealerDetailsModal,
  createDealerProductUpgradeRequest,
})(DealerDetails);
