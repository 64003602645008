import React from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Grid } from 'react-bootstrap';
import { yesNoOptions } from '../constants';
import ValidationMultiCheckbox from '../../../components/forms/inputs/ValidationMultiCheckbox';
import ValidationSelect from '../../../components/forms/inputs/ValidationSelect';
import { UpgradeDealerProductValues } from '../../forms/UpgradeDealer/UpgradeDealerProducts';
import { products } from '../validationSchemas';
import { DealerProduct } from '../../../services/dealerService';

export const ncbValidators: Record<'includeNCBAddendum' | 'ncbAddendumProducts', Yup.BaseSchema> = {
  includeNCBAddendum: Yup.boolean()
    .notRequired()
    .label('NCB Addendum'),
  ncbAddendumProducts: products()
    .label('NCB Addendum Products')
    .notRequired()
    .when('includeNCBAddendum', {
      is: true,
      then: products()
        .min(1)
        .required('You must select an NCB product'),
    }),
};

export type NCBFields = 'includeNCBAddendum' | 'ncbAddendumProducts';

const useShowFields = (): {
  showFields: Set<NCBFields>;
} => {
  const { values } = useFormikContext<UpgradeDealerProductValues>();

  const showFields = new Set<NCBFields>();

  if (values.includeNCBAddendum) {
    showFields.add('ncbAddendumProducts');
  }

  return { showFields };
};

// note: for existing dealers only
export default function NCBAddendum({
  ncbProductOptions = [DealerProduct.VSC],
}: {
  ncbProductOptions?: DealerProduct.VSC[];
}): React.ReactElement {
  const { showFields } = useShowFields();
  return (
    <Grid fluid>
      <Field
        name="includeNCBAddendum"
        component={ValidationSelect}
        options={yesNoOptions}
        label="Request NCB Addendum?"
        placeholder="Please choose an option"
      />
      {showFields.has('ncbAddendumProducts') && (
        <FieldArray
          name="ncbAddendumProducts"
          render={props => (
            // @ts-expect-error TS2322
            <ValidationMultiCheckbox
              {...props}
              options={ncbProductOptions}
              label="What products will the NCB Addendum be appointed for?"
            />
          )}
        />
      )}
    </Grid>
  );
}

// export default function NCBAddendum(){ return <div>sadasd</div>}
