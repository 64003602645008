import { memoize } from 'lodash';
import { find, flow, pick } from 'lodash/fp';
import { EMPTY_DATE } from './constants';
import { DealerDetails } from './types';

export const getDealerName = memoize(dlid => flow(find({ dlid }), pick(['dlid', 'dlCompany'])));

export const isVSCAppointed = ({ vscEff, vscExp }: DealerDetails): boolean => {
  return vscEff !== EMPTY_DATE && vscExp === EMPTY_DATE;
};

export const isGAPAppointed = ({ gapEff, gapExp }: DealerDetails): boolean => {
  return gapEff !== EMPTY_DATE && gapExp === EMPTY_DATE;
};

export const isTWPAppointed = ({ twpEff, twpExp }: DealerDetails): boolean => {
  return twpEff !== EMPTY_DATE && twpExp === EMPTY_DATE;
};

export const getCityStateZipFromDetails = (
  cityStateZipString?: string | null,
): { city?: string; state?: string; zip?: string } => {
  if (cityStateZipString == null) {
    return {};
  }
  const [city, stateZip] = cityStateZipString.split(',').map(s => s.trim()); // "Pacoima, CA 91331"
  const [state, zip] = stateZip.split(' ');
  return { city, state, zip };
};

export const isNullOrZeroOrFalse = (value: null | 0 | '' | number | boolean): boolean =>
  value == null || value === 0 || !value;
