import React from 'react';
import PropTypes from 'prop-types';

const ValidationCheckbox = ({ input, label, disabled, meta: { touched, error } }) => (
  <div className={`form-group ${error && touched ? 'has-error' : ''}`}>
    <div className="form-check">
      <label className="form-check-label col-sm-3" htmlFor="defaultCheck1">
        {label}
      </label>
      <div className="col-sm-9">
        <input
          {...input}
          checked={input.value}
          className="form-check-input"
          type="checkbox"
          disabled={disabled}
        />
        {error && touched && (
          <span className="glyphicon glyphicon-remove form-control-feedback"></span>
        )}
        {error && touched && (
          <div className="text-danger">
            <strong>{error}</strong>
          </div>
        )}
      </div>
    </div>
  </div>
);

ValidationCheckbox.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

ValidationCheckbox.defaultProps = {
  input: {},
  disabled: false,
};

export default ValidationCheckbox;
