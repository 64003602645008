import React from 'react';
import { isEmpty, map, startCase } from 'lodash';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { FormikErrors } from 'formik';

export default function FormikWizardButtons({
  onBack,
  onNext,
  nextButtonText = 'Next',
  disableNext,
  errors,
}: {
  onBack?: () => void;
  onNext?: () => void;
  nextButtonText?: string;
  disableNext?: boolean;
  errors?: FormikErrors<{ [key: string]: string }>;
}): React.ReactElement {
  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col md={4} xs={12}>
            <Button block bsStyle="primary" disabled={onBack == null} onClick={onBack}>
              Previous
            </Button>
          </Col>
          <Col md={8} xs={12}>
            <Button block bsStyle="primary" type="submit" onClick={onNext} disabled={disableNext}>
              {nextButtonText}
            </Button>
          </Col>
        </Row>
        {/* we're showing the errors here mostly for dev-ex, since some field errors might be hidden (which is likely a bug) */}
        {process.env.NODE_ENV === 'development' && errors != null && !isEmpty(errors) && (
          <Row style={{ paddingTop: 8 }}>
            <Col xs={12}>
              <Alert className="alert-danger">
                Errors{' '}
                <ul>
                  {map(errors, (error, field) => (
                    <li key={field}>
                      {startCase(field)}: {error}
                    </li>
                  ))}
                </ul>
              </Alert>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}
