import { PRCO_PRODUCT_UPGRADE_OPTIONS } from '../constants';
import {
  DealerDetails,
  DealerProduct,
  ProductField,
  ProductUpgradeOptions,
  SupplementalProduct,
} from '../types';
import {
  isGAPAppointed,
  isNullOrZeroOrFalse,
  isTWPAppointed,
  isVSCAppointed,
} from '../dealerDetails';

const determineProductOptions = (
  productField: ProductField.INCENTIVES | ProductField.RETAIL_PACK | ProductField.NCB_ADDENDUM,
  appointedProductOptions: DealerProduct[],
  {
    vscValue,
    gapValue,
    twpValue,
    dealerHasAllAppointedProductOptionsAsActive,
    dealerHasAllAppointedProductOptionsAsExpired,
  }: {
    vscValue?: number | boolean | null;
    gapValue?: number | boolean | null;
    twpValue?: number | boolean | null;
    dealerHasAllAppointedProductOptionsAsActive: boolean;
    dealerHasAllAppointedProductOptionsAsExpired: boolean;
  },
): DealerProduct[] => {
  if (dealerHasAllAppointedProductOptionsAsExpired) {
    return appointedProductOptions;
  }

  if (
    isNullOrZeroOrFalse(vscValue) &&
    isNullOrZeroOrFalse(gapValue) &&
    isNullOrZeroOrFalse(twpValue) &&
    dealerHasAllAppointedProductOptionsAsActive
  ) {
    // show all defaults
    return PRCO_PRODUCT_UPGRADE_OPTIONS[productField];
  }
  const eligibleProducts = [];

  if (isNullOrZeroOrFalse(vscValue)) {
    eligibleProducts.push(DealerProduct.VSC);
  }
  if (isNullOrZeroOrFalse(gapValue)) {
    eligibleProducts.push(DealerProduct.GAP);
  }
  if (isNullOrZeroOrFalse(twpValue)) {
    eligibleProducts.push(DealerProduct.TWP);
  }

  // default to no products
  return eligibleProducts;
};

/**
 * converts dealerDetails into an object of products that dealer can upgrade
 */
export default function getDealerUpgradeProducts(
  dealerDetails?: DealerDetails,
): ProductUpgradeOptions | null {
  if (dealerDetails == null) {
    return null;
  }
  const hasVSC = isVSCAppointed(dealerDetails);
  const hasGAP = isGAPAppointed(dealerDetails);
  const hasTWP = isTWPAppointed(dealerDetails);

  const allowedProductTypes = PRCO_PRODUCT_UPGRADE_OPTIONS.APPOINTED.filter(product => {
    if (product === DealerProduct.VSC && !hasVSC) {
      return true;
    }
    if (product === DealerProduct.GAP && !hasGAP) {
      return true;
    }

    return product === DealerProduct.TWP && !hasTWP;
  });

  const dealerHasAllAppointedProductOptionsAsActive = allowedProductTypes.length === 0;
  const dealerHasAllAppointedProductOptionsAsExpired =
    allowedProductTypes.length === PRCO_PRODUCT_UPGRADE_OPTIONS.APPOINTED.length;

  // note: some products are applied via the GAP form,
  //  if the dealer already has GAP, we don't override (provide a new form) -
  //  so the products are unavailable ATM
  return {
    [ProductField.APPOINTED]: allowedProductTypes,
    [ProductField.BHPH]: !dealerDetails.gapbhph || !hasGAP,
    [ProductField.GAP_PLUS]: !dealerDetails.gapPlus || !hasGAP,
    [ProductField.RETAIL_PACK]: determineProductOptions(
      ProductField.RETAIL_PACK,
      allowedProductTypes,
      {
        vscValue: dealerDetails.vscPack,
        gapValue: dealerDetails.gapPack,
        twpValue: dealerDetails.twpPack,
        dealerHasAllAppointedProductOptionsAsActive,
        dealerHasAllAppointedProductOptionsAsExpired,
      },
    ),
    [ProductField.INCENTIVES]: determineProductOptions(
      ProductField.INCENTIVES,
      allowedProductTypes,
      {
        vscValue: dealerDetails.gaprsvAmt,
        gapValue: dealerDetails.vscrsvAmt,
        twpValue: dealerDetails.twprsvAmt,
        dealerHasAllAppointedProductOptionsAsActive,
        dealerHasAllAppointedProductOptionsAsExpired,
      },
    ),
    [ProductField.NCB_ADDENDUM]: determineProductOptions(
      ProductField.NCB_ADDENDUM,
      allowedProductTypes,
      {
        vscValue: dealerDetails.gapncb,
        gapValue: dealerDetails.vscncb,
        twpValue: dealerDetails.twpncb,
        dealerHasAllAppointedProductOptionsAsActive,
        dealerHasAllAppointedProductOptionsAsExpired,
      },
      // ensure only VSC is present here
    ).flatMap(product => (product === DealerProduct.VSC ? [product] : [])),
    [ProductField.SUPPLEMENTAL]: [
      SupplementalProduct.PRCO_POWER,
      ...(hasVSC ? [SupplementalProduct.VSC_SLUDGE] : []),
    ],
  };
}
