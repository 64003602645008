import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { startCase, map, memoize, chain } from 'lodash';
import { pick } from 'lodash/fp';
import { Grid, Row, Table, Button } from 'react-bootstrap';
import TemplateWizard from './TemplateWizzard';
import TemplateSearchForm from './components/TemplateSearchForm';
import { getTemplates, deleteTemplate, viewTemplate } from '../../redux/modules/formTemplateAdmin';

const Confirm = ({ onConfirm, onClose, header, confirmText }) => (
  <React.Fragment>
    <div>{header}</div>
    <Button bsStyle="default" onClick={onClose}>
      Cancel
    </Button>
    {'  '}
    <Button bsStyle="primary" onClick={onConfirm}>
      {confirmText}
    </Button>
  </React.Fragment>
);
const toState = ({ formTemplateAdmin }) => ({
  templateList: formTemplateAdmin.templateList,
});

const FIELDS = ['templateType', 'name', 'created'];
const pickFields = memoize(pick(FIELDS));

class Templates extends PureComponent {
  state = {
    confirm: false,
    downloadInfo: null,
    deleteInfo: null,
  };

  componentWillMount() {
    this.props.getTemplates();
  }

  valueFormatter = (value = '', key) => {
    switch (key) {
      case 'created':
        return moment(new Date(value)).format('MMMM Do, YYYY h:mm a');
      default:
        return value;
    }
  };

  handleConfirmDownload = () => {
    const { downloadInfo } = this.state;
    this.props.viewTemplate(downloadInfo.id, downloadInfo.name);
    this.setState({ confirm: false, downloadInfo: null });
  };

  handleConfirmDelete = () => {
    const { deleteInfo } = this.state;
    this.props.deleteTemplate(deleteInfo.id);
    this.setState({ confirm: false, deleteInfo: null });
  };

  render() {
    const { templateList } = this.props;
    const { confirm, downloadInfo, deleteInfo } = this.state;
    return (
      <React.Fragment>
        <Grid>
          <Row>
            <TemplateSearchForm />
          </Row>
          {confirm && (
            <Row style={{ margin: 8 }}>
              {downloadInfo && (
                <Confirm
                  onClose={() => this.setState({ confirm: false, downloadInfo: null })}
                  onConfirm={this.handleConfirmDownload}
                  confirmText="Download"
                  header={`Download ${downloadInfo.name}?`}
                />
              )}
              {deleteInfo && (
                <Confirm
                  onClose={() => this.setState({ confirm: false, deleteInfo: null })}
                  onConfirm={this.handleConfirmDelete}
                  confirmText="Delete"
                  header={`Delete Template ${deleteInfo.name}?`}
                />
              )}
            </Row>
          )}
          <Row>
            <Table striped bordered condensed responsive hover>
              <thead>
                <tr>
                  {FIELDS.map(f => (
                    <td key={f}>{startCase(f)}</td>
                  ))}
                  <td>{/* Delete column has no border on header without this */}</td>
                </tr>
              </thead>
              <tbody>
                {chain(templateList)
                  .map((row, k) => (
                    <tr
                      key={k}
                      title="click to view"
                      onClick={() =>
                        this.setState({
                          confirm: true,
                          downloadInfo: { id: row._id, name: row.name },
                        })
                      }
                    >
                      {map(pickFields(row), (v, key) => (
                        <td key={v}>{this.valueFormatter(v, key)}</td>
                      ))}
                      <td title="">
                        <Button
                          bsStyle="danger"
                          className="delete-template"
                          onClick={e => {
                            e.stopPropagation();
                            this.setState({
                              confirm: true,
                              deleteInfo: { id: row._id, name: row.name },
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))
                  .value()}
              </tbody>
            </Table>
          </Row>
          <TemplateWizard />
        </Grid>
      </React.Fragment>
    );
  }
}

export default connect(toState, { getTemplates, deleteTemplate, viewTemplate })(Templates);
