import { DealerDetails, ProductField, DealerProduct } from '../types';
import {
  isGAPAppointed,
  isNullOrZeroOrFalse,
  isTWPAppointed,
  isVSCAppointed,
} from '../dealerDetails';

export type CurrentlyActiveProduct = Record<DealerProduct, boolean>;
export type CurrentlyActiveNcbProduct = Record<DealerProduct.VSC, boolean>;

type CurrentlyActiveAppointed = Record<ProductField.APPOINTED, CurrentlyActiveProduct>;
type CurrentlyActiveRetailPack = Record<ProductField.RETAIL_PACK, CurrentlyActiveProduct>;
type CurrentlyActiveIncentives = Record<ProductField.INCENTIVES, CurrentlyActiveProduct>;
type CurrentlyActiveNcbAddendum = Record<ProductField.NCB_ADDENDUM, CurrentlyActiveNcbProduct>;

export type CurrentlyActiveProducts = CurrentlyActiveAppointed &
  CurrentlyActiveRetailPack &
  CurrentlyActiveIncentives &
  CurrentlyActiveNcbAddendum &
  Record<ProductField.BHPH, boolean> &
  Record<ProductField.GAP_PLUS, boolean>;

/**
 * converts dealer details into a map of products that are currently active
 * @param dealerDetails
 */
export default function useCurrentlyActiveProducts(
  dealerDetails: DealerDetails,
): CurrentlyActiveProducts {
  const hasVSC = isVSCAppointed(dealerDetails);
  const hasGAP = isGAPAppointed(dealerDetails);
  const hasTWP = isTWPAppointed(dealerDetails);

  return {
    [ProductField.APPOINTED]: {
      [DealerProduct.VSC]: hasVSC,
      [DealerProduct.GAP]: hasGAP,
      [DealerProduct.TWP]: hasTWP,
    },
    [ProductField.BHPH]: dealerDetails.gapbhph && hasGAP,
    [ProductField.GAP_PLUS]: dealerDetails.gapPlus && hasGAP,
    [ProductField.RETAIL_PACK]: {
      [DealerProduct.VSC]: !isNullOrZeroOrFalse(dealerDetails.vscPack),
      [DealerProduct.GAP]: !isNullOrZeroOrFalse(dealerDetails.gapPack),
      [DealerProduct.TWP]: !isNullOrZeroOrFalse(dealerDetails.twpPack),
    },
    [ProductField.INCENTIVES]: {
      [DealerProduct.VSC]: !isNullOrZeroOrFalse(dealerDetails.gaprsvAmt),
      [DealerProduct.GAP]: !isNullOrZeroOrFalse(dealerDetails.vscrsvAmt),
      [DealerProduct.TWP]: !isNullOrZeroOrFalse(dealerDetails.twprsvAmt),
    },
    [ProductField.NCB_ADDENDUM]: {
      [DealerProduct.VSC]: !isNullOrZeroOrFalse(dealerDetails.gapncb),
      // [DealerProduct.GAP]: !isNullOrZeroOrFalse(dealerDetails.vscncb),
      // [DealerProduct.TWP]: !isNullOrZeroOrFalse(dealerDetails.twpncb),
    },
  };
}
