import React, { Component } from 'react';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { compose, withState, withProps } from 'recompose';
import {
  Grid,
  Row,
  Col,
  ButtonToolbar,
  Button,
  Glyphicon,
  DropdownButton,
  MenuItem,
  ProgressBar,
} from 'react-bootstrap';
import numeral from 'numeral';
import ConfirmStatementRequests from './ConfirmStatement';
import DealerDetails from './DealerDetails';
import {
  selectDealerForStatement,
  selectAllDealersForStatement,
  toggleConfirmStatementSendModal,
  toggleDealerDetailsModal,
} from '../../redux/modules/statements';
import { getSalesRepsDealerList } from '../../redux/modules/data';

const check = _.memoize(v => <Glyphicon glyph={v ? 'check' : 'unchecked'} />);

const DEALER_DETAILS_COLUMN = 'dealerDetails';

const BOOLEAN_COLUMN_FORMAT_MAPPINGS = {
  statementRequested: check,
  pdfCreated: check,
  emailSent: check,
};

const COLUMN_FORMAT_MAPPINGS = {
  ...BOOLEAN_COLUMN_FORMAT_MAPPINGS,
  [DEALER_DETAILS_COLUMN]: (cell, row, rowIndex, formatExtraData) => {
    // Prevents row selection
    const onCellClick = e => {
      e.stopPropagation();
    };

    const onDetailsClick = () => {
      formatExtraData.onDealerDetails(row);
    };

    return (
      <div style={{ whiteSpace: 'nowrap' }} onClick={onCellClick}>
        <Button onClick={onDetailsClick} bsStyle="link">
          Details
        </Button>
        <Button
          disabled={row.dealerBalance === '$0.00'}
          Component="a"
          bsStyle="link"
          href={`dealers/${row.dlid}/statements?dealerName=${row.dlCompany}`}
          target="_blank"
        >
          Statements
        </Button>
      </div>
    );
  },
};

export class ActiveDealers extends Component {
  static defaultProps = {
    dealers: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      columns: this.defaultColumns,
    };
  }

  componentDidMount() {
    const { getSalesRepsDealerList } = this.props;
    getSalesRepsDealerList();
  }

  allColumns = [
    DEALER_DETAILS_COLUMN,
    'repPortalRequestId',
    'dealerBalance',
    'dlid',
    'dlCompany',
    'dlOwner',
    'dlAddress',
    'dlCityStzip',
    'dlEmail',
    'dlPhone',
    'dlFax',
    'vscEff',
    'vscExp',
    'gapEff',
    'gapExp',
    // 'statementRequested', // note: hidden unless PRco team requests otherwise
    // 'pdfCreated',
    // 'emailSent',
    'myUuid',
  ];

  defaultColumns = [
    DEALER_DETAILS_COLUMN,
    'dealerBalance',
    'dlid',
    'dlCompany',
    'vscEff',
    'vscExp',
    'gapEff',
    'gapExp',
    'twpEff',
    'twpExp',
  ];

  selectRow = {
    mode: 'checkbox',
    style: { backgroundColor: '#d8cdcd' },
    clickToSelect: true,
    onSelect: (row, isSelect) => this.props.selectDealerForStatement({ isSelect, id: row.dlid }),
    onSelectAll: (isSelect, rows) =>
      this.props.selectAllDealersForStatement({ isSelect, ids: _.map(rows, 'dlid') }),
  };

  render() {
    const {
      dealers,
      selectedDealers: selected,
      currentSort,
      toggleConfirmStatementSendModal,
      updateCurrentSort,
      toggleDealerDetailsModal,
    } = this.props;

    const handleDealerDetailsClick = dealerDetails => {
      toggleDealerDetailsModal(dealerDetails);
    };

    const { columns } = this.state;

    const defaultColumnMapper = (col, formatExtraData) => ({
      dataField: col,
      text: _.upperCase(col),
      sort: !BOOLEAN_COLUMN_FORMAT_MAPPINGS[col],
      style: { overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' },
      filter: !BOOLEAN_COLUMN_FORMAT_MAPPINGS[col]
        ? textFilter({ placeholder: 'Filter..', delay: 500 })
        : null,
      formatter: COLUMN_FORMAT_MAPPINGS[col],
      formatExtraData,
      headerAttrs: {
        onClick: () => {
          if (_.get(currentSort, 'dataField') === col) {
            updateCurrentSort({
              dataField: col,
              order: _.get(currentSort, 'order') === 'desc' ? 'asc' : 'desc',
            });
          } else {
            updateCurrentSort({
              dataField: col,
              order: 'asc',
            });
          }
        },
      },
    });

    const mapFormattedBalance = dealer => ({
      ...dealer,
      dealerBalance: numeral(dealer.dealerBalance || 0).format('$0,0.00'),
    });

    const sortedDealers =
      currentSort.order === 'asc'
        ? _.chain(dealers)
            .map(mapFormattedBalance)
            .sortBy(currentSort.dataField)
            .value()
        : _.chain(dealers)
            .map(mapFormattedBalance)
            .sortBy(currentSort.dataField)
            .reverse()
            .value();

    return (
      <div>
        <Helmet title="Active Dealers" />
        <ConfirmStatementRequests />
        <DealerDetails onClose={() => toggleDealerDetailsModal(null)} />
        <Grid>
          <Row>
            <Col xs={12}>
              <h3>Active Dealers</h3>
              <h6>Select rows to email dealers their statement</h6>
              <ButtonToolbar>
                <Button
                  bsStyle="primary"
                  disabled={selected.length === 0}
                  onClick={() => toggleConfirmStatementSendModal(true)}
                >
                  <Glyphicon glyph="envelope" /> Send Statement
                </Button>
                <DropdownButton bsStyle="primary" title="Columns" id="column-selection">
                  {this.allColumns.map(col => {
                    const active = columns.includes(col);
                    return (
                      <MenuItem
                        key={col}
                        active={active}
                        onSelect={() =>
                          this.setState({
                            columns: active ? columns.filter(c => c !== col) : [...columns, col],
                          })
                        }
                      >
                        {_.upperCase(col)}
                      </MenuItem>
                    );
                  })}
                </DropdownButton>
              </ButtonToolbar>
            </Col>
          </Row>
          <Row className="form-list-gap">
            <Col xs={12}>
              {dealers.length ? (
                <BootstrapTable
                  keyField="dlid"
                  hover
                  striped
                  condensed
                  bordered={false}
                  rowStyle={{ cursor: 'pointer' }}
                  data={sortedDealers}
                  columns={_.map(columns, col =>
                    defaultColumnMapper(col, { onDealerDetails: handleDealerDetailsClick }),
                  )}
                  selectRow={{
                    ...this.selectRow,
                    selected,
                  }}
                  filter={filterFactory()}
                  pagination={dealers.length > 10 ? paginationFactory() : null}
                  {...this.props}
                />
              ) : (
                <ProgressBar now={100} active={true} />
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withState('columnFilters', 'updateColumnFilters', {}),
  withState('currentSort', 'updateCurrentSort', {}),
  connect(({ statements, data }) => ({ dealers: data.dealers, ...statements }), {
    selectDealerForStatement,
    selectAllDealersForStatement,
    getSalesRepsDealerList,
    toggleConfirmStatementSendModal,
    toggleDealerDetailsModal,
  }),
  withProps(_.noop),
)(ActiveDealers);
