import _ from 'lodash';
import { parse } from 'query-string';
import { STATES } from '../constants/general';
import * as dealerService from '../services/dealerService';

export const getEmailOverride = (plus = 'local') => {
  if (process.env.REACT_APP_DEALER_EMAILS_OVERRIDE) {
    return process.env.REACT_APP_DEALER_EMAILS_OVERRIDE.replace('local', plus);
  }
  return null;
};

export const getNameOverride = (includeFist = true, includeLast = true) => {
  const override = process.env.REACT_APP_NEW_DEALER_NAME_OVERRIDE;
  if (process.env.REACT_APP_NEW_DEALER_NAME_OVERRIDE != null) {
    if (includeFist && includeLast) {
      return override;
    }
    const [first, last] = override.split(' ');
    if (includeFist && !includeLast) {
      return first;
    }
    if (includeLast && !includeFist) {
      return last;
    }
  }
  return null;
};

export const ACCOUNT_NUMBER_BANK = [
  28033062,
  89733529,
  65130762,
  16260679,
  66970730,
  88519093,
  88985532,
  95471647,
  34873310,
  43476537,
  57571556,
];
export const ROUTING_NUMBER_BANK = [
  252984520,
  223947429,
  519976804,
  857168141,
  373482575,
  399123218,
  210940551,
  507612563,
  309620007,
  536097649,
  262928167,
];
export const DEALER_ID_BANK = [1820, 7739, 1113, 5018];
export const BANK_NAME_BANK = [
  'Bank of America',
  'Chase',
  'Wells Fargo',
  'Capital One',
  'Citibank',
];
export const NAME_BANK = [
  'Syreeta Spraggins',
  'Alden Tomlin',
  'Leora Matheney',
  'Keenan Paolucci',
  'Ivette Pigeon',
  'Thanh Cyphers',
  'Elisa Bowens',
  'Daniela Beers',
  'Adelia Hazen',
  'Flor Archer',
];
export const FIRST_NAME_BANK = _.map(NAME_BANK, name => _.split(name, ' ')[0]);
export const LAST_NAME_BANK = _.map(NAME_BANK, name => _.split(name, ' ')[1]);
export const EMAIL_BANK = [
  'Nunc.sed@Proinvel.edu',
  'nec.urna@facilisisloremtristique.edu',
  'sem.ut.cursus@eunulla.ca',
  'mi@quamquisdiam.co.uk',
  'ac.orci.Ut@Nunclectuspede.ca',
  'Proin@dui.edu',
  'iaculis.aliquet@Crasvulputatevelit.com',
  'facilisis.magna.tellus@liberonec.net',
  'Integer@magnaLorem.ca',
  'risus@tincidunt.org',
  'erat@Donecnibh.co.uk',
  'luctus.Curabitur@consectetueripsum.com',
  'adipiscing.fringilla.porttitor@quis.org',
  'senectus.et.netus@Nulla.co.uk',
  'cubilia.Curae.Donec@molestie.net',
  'euismod.enim.Etiam@semmollisdui.co.uk',
  'dignissim.lacus.Aliquam@magnaLorem.com',
  'augue.scelerisque.mollis@Nuncullamcorpervelit.co.uk',
  'ultricies.ornare.elit@elit.org',
  'nisl.sem@inceptoshymenaeosMauris.edu',
  'a.auctor.non@dolor.com',
  'euismod@Suspendissenonleo.net',
  'vitae@temporerat.co.uk',
  'Mauris.vestibulum.neque@ornarefacilisis.com',
  'turpis.vitae.purus@Etiambibendum.ca',
  'et@Nuncsollicitudin.co.uk',
  'massa@pulvinararcu.edu',
  'feugiat.tellus@semperpretium.org',
  'aliquet.metus.urna@ultriciesdignissim.org',
  'cursus.purus@risus.ca',
];
export const ADDRESS_BANK = [
  '526-3499 Ullamcorper, Rd.',
  '4387 Sed Avenue',
  'P.O. Box 467, 471 Eu Avenue',
];
export const ADDRESS2_BANK = ['suite 3', 'apt 2', 'lot 6', '2nd floor', 'unit 16'];
export const CITY_BANK = [
  'Austin',
  'Springfield',
  'Hawthorne',
  'Paris',
  'Heidelberg',
  'Southend',
  'Erie',
];
export const ZIP_BANK = ['11101', '60202', '38270', '64889'];
const SENTENCE_BANK = [
  'Cherubfish, salamanderfish, dartfish dealfish gombessa slimy mackerel bangus, ocean perch.',
  'Driftfish greeneye mackerel shark dartfish Razorback sucker roughy snook lampfish.',
  'African glass catfish silverside.',
  'Burrowing goby blue eye Chinook salmon gianttail Colorado squawfish; blenny; whale catfish hawkfish.',
  'Riffle dace tailor tadpole cod knifefish Red salmon, medaka, yellow weaver ling cod.',
  'Trumpetfish channel catfish bandfish trevally roach yellowfin skilfish, ribbonbearer.',
];
export const WORD_BANK = [
  'diam',
  'erat',
  'nunc',
  'lectus',
  'condimentum.',
  'Nullam',
  'at',
  'eget',
  'velit',
  'Nam',
  'aliquet',
  'augue.',
  'dolor.',
  'enim',
  'nisi.',
  'sem',
  'nulla.',
  'auctor',
  'urna.',
  'eget',
  'eros',
  'magna',
  'tincidunt',
  'Aliquam',
  'accumsan',
  'facilisis',
  'mauris',
  'nisl.',
  'ornare',
  'enim.',
  'aliquet',
  'nibh',
  'Curabitur',
  'sollicitudin',
  'vel',
  'dui',
  'justo',
  'non',
  'Donec',
  'lorem',
  'ultrices.',
  'luctus',
  'ipsum',
  'Aenean',
  'pede,',
  'Donec',
  'viverra.',
  'mauris.',
  'quis',
  'vitae',
  'arcu.',
  'lobortis',
  'magna,',
  'vitae',
  'Suspendisse',
  'Mauris',
  'vehicula.',
  'sollicitudin',
  'et,',
  'dolor.',
  'In',
  'ligula',
  'urna.',
  'sed',
  'convallis',
  'faucibus.',
  'urna.',
  'interdum',
  'Class',
  'Vivamus',
  'non',
  'nisl',
  'Aliquam',
  'Fusce',
  'nec',
  'penatibus',
  'nisi.',
  'Curabitur',
  'et',
  'Nullam',
  'Quisque',
  'In',
  'inceptos',
  'sociosqu',
  'Quisque',
  'augue',
  'lacinia',
  'Suspendisse',
  'sem',
  'torquent',
  'felis',
  'mauris',
  'euismod',
  'ridiculus',
  'purus',
  'ornare',
  'vitae',
  'eu',
  'ut',
  'risus.',
];
export const DEALERSHIP_NAME_BANK = [
  'Ford valley Ford',
  '6 Towns Chrystler',
  '3 Towns Toyota',
  'Peter Pan BMW',
  'Harrison County Ford',
  'Sals Used Auto',
  'Jack Frost Honda',
  'Kia of Tokyo',
];
export const DEALER_STATE_BANK = ['AZ', 'NV', 'CA', 'OR', 'ID', 'IN', 'WA'];
export const CONTRACT_TYPE_BANK = [
  dealerService.DealerProduct.VSC,
  dealerService.DealerProduct.GAP,
];

const buildTestDataFieldValueMapping = fields =>
  fields.filter(_.isObject).reduce((acc, field) => {
    _.set(
      acc,
      _.get(field, 'name'),
      !_.isFunction(field.custom)
        ? (() => {
            const type = _.get(field, 'type');
            switch (type) {
              case 'processorName':
                return `${_.sample(FIRST_NAME_BANK)} ${_.sample(LAST_NAME_BANK)}`;
              case 'dealershipName':
                return _.sample(DEALERSHIP_NAME_BANK);
              case 'dealershipAddress':
                return _.sample(ADDRESS_BANK);
              case 'dealershipAddress2':
                return _.sample(ADDRESS2_BANK);
              case 'dealerContactEmail':
                return getEmailOverride('dealer') || _.sample(EMAIL_BANK);
              case 'bankName':
                return _.sample(BANK_NAME_BANK);
              case 'dealerCity':
                return _.sample(CITY_BANK);
              case 'dealerZip':
                return _.sample(ZIP_BANK);
              case 'dealerId':
                return _.sample(DEALER_ID_BANK);
              case 'name':
                return `${_.sample(FIRST_NAME_BANK)} ${_.sample(LAST_NAME_BANK)}`;
              case 'companyName':
                return _.sample(DEALERSHIP_NAME_BANK);
              case 'firstName':
                return _.sample(FIRST_NAME_BANK);
              case 'lastName':
                return _.sample(LAST_NAME_BANK);
              case 'email':
                return getEmailOverride() || _.sample(EMAIL_BANK);
              case 'address':
                return _.sample(ADDRESS_BANK);
              case 'city':
                return _.sample(CITY_BANK);
              case 'state':
                return _.sample(STATES);
              case 'zipCode':
                return _.sample(ZIP_BANK);
              case 'sentence':
                return _.sample(SENTENCE_BANK);
              case 'routingNumber':
                return _.sample(ROUTING_NUMBER_BANK);
              case 'accountNumber':
                return _.sample(ACCOUNT_NUMBER_BANK);
              case 'dealerState':
                return _.sample(DEALER_STATE_BANK);
              case 'contractType':
                return _.sample(CONTRACT_TYPE_BANK);
              case 'number':
                return _.sample(_.range(1, 20)) + _.sample(_.range(0, 100)) / 100;
              default:
                return _.sample(WORD_BANK);
            }
          })()
        : field.custom(field),
    );
    return acc;
  }, {});

export function testDataIfRequested(...fields) {
  if (typeof window !== 'undefined' && _.get(parse(window.location.search), 'testData')) {
    return buildTestDataFieldValueMapping(fields);
  } else {
    return undefined;
  }
}

export const templateMetaDataIfRequested = _.partial(
  testDataIfRequested,
  {
    name: 'templateMetaData.templateType',
    custom: () =>
      _.sample([
        dealerService.DealerProduct.VSC,
        dealerService.DealerProduct.GAP,
        dealerService.DealerProduct.VSC,
      ]),
  },
  {
    name: 'templateMetaData.language',
    custom: () => _.sample(['english', 'spanish']),
  },
  {
    name: 'templateMetaData.state',
    custom: () => _.sample(STATES),
  },
  {
    name: 'templateMetaData.isGapPLus',
    custom: () => _.sample([true, false]),
  },
);
