import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import * as dealerService from '../../services/dealerService';

const renderAppointed = (isAppointed, effectiveDate) => {
  if (isAppointed) {
    return `Active, ${effectiveDate}`;
  }
  return 'Not Appointed';
};

const booleanAppointed = value => (value ? 'Appointed' : 'Not Appointed');
/**
 *
 * @param dealerDetails { DealerDetails }
 * @return {JSX.Element}
 * @constructor
 */
export default function DealerDetailUpgradeTab({ dealerDetails }) {
  return (
    <div>
      <Row>
        <Col xs={12}>
          {dealerDetails && dealerDetails != null && (
            <Table style={{ margin: 8 }}>
              <thead>
                <tr>
                  <td>
                    <b>Product</b>
                  </td>
                  <td>
                    <b>Status</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr key="DealerID">
                  <td>Dealer ID:</td>
                  <td>
                    {dealerService.isNullOrZeroOrFalse(dealerDetails.dlid)
                      ? 'Not Appointed'
                      : dealerDetails.dlid}
                  </td>
                </tr>
                <tr key="DealershipName">
                  <td>Dealership Name:</td>
                  <td>
                    {dealerService.isNullOrZeroOrFalse(dealerDetails.dlCompany)
                      ? 'Not Appointed'
                      : dealerDetails.dlCompany}
                  </td>
                </tr>
                <tr key="OwnerName">
                  <td>Owner Name:</td>
                  <td>
                    {dealerService.isNullOrZeroOrFalse(dealerDetails.dlOwner)
                      ? 'Not Appointed'
                      : dealerDetails.dlOwner}
                  </td>
                </tr>
                <tr key="Address">
                  <td>Address:</td>
                  <td>
                    {dealerService.isNullOrZeroOrFalse(
                      dealerDetails.dlAddress + dealerDetails.dlCityStzip,
                    )
                      ? 'Not Appointed'
                      : `${dealerDetails.dlAddress}   ${dealerDetails.dlCityStzip}`}
                  </td>
                </tr>
                <tr key="Email">
                  <td>Email(s):</td>
                  <td>
                    {dealerService.isNullOrZeroOrFalse(dealerDetails.dlEmail)
                      ? 'Not Appointed'
                      : dealerDetails.dlEmail}
                  </td>
                </tr>
                <tr key="dlPhone">
                  <td>Phone:</td>
                  <td>
                    {dealerService.isNullOrZeroOrFalse(dealerDetails.dlPhone)
                      ? 'Not Appointed'
                      : dealerDetails.dlPhone}
                  </td>
                </tr>
                <tr key="vsc">
                  <td>VSC</td>
                  <td>
                    {renderAppointed(
                      dealerService.isVSCAppointed(dealerDetails),
                      dealerDetails.vscEff,
                    )}
                  </td>
                </tr>
                <tr key="gap">
                  <td>GAP</td>
                  <td>
                    {renderAppointed(
                      dealerService.isGAPAppointed(dealerDetails),
                      dealerDetails.gapEff,
                    )}
                  </td>
                </tr>
                <tr key="twp">
                  <td>TWP</td>
                  <td>
                    {renderAppointed(
                      dealerService.isTWPAppointed(dealerDetails),
                      dealerDetails.twpEff,
                    )}
                  </td>
                </tr>
                <tr key="BHPH">
                  <td>BHPH</td>
                  <td>{booleanAppointed(dealerDetails.gapbhph)}</td>
                </tr>
                <tr key="gapPlus">
                  <td>GAP Plus</td>
                  <td>{booleanAppointed(dealerDetails.gapPlus)}</td>
                </tr>
                <tr key="VSCreserve">
                  <td>VSC Production Incentive</td>
                  <td>
                    {dealerService.isNullOrZeroOrFalse(dealerDetails.vscrsvAmt)
                      ? 'Not Appointed'
                      : dealerDetails.vscrsvAmt}
                  </td>
                </tr>
                <tr key="GAPreserve">
                  <td>GAP Production Incentive</td>
                  <td>
                    {dealerService.isNullOrZeroOrFalse(dealerDetails.gaprsvAmt)
                      ? 'Not Appointed'
                      : dealerDetails.gaprsvAmt}
                  </td>
                </tr>
                <tr key="vscncb">
                  <td>VSC NCB</td>
                  <td>{booleanAppointed(dealerDetails.vscncb)}</td>
                </tr>
                <tr key="gapncb">
                  <td>GAP NCB</td>
                  <td>{booleanAppointed(dealerDetails.gapncb)}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </div>
  );
}
