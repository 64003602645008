import React from 'react';
import { Field } from 'formik';
import { Col, FormGroup } from 'react-bootstrap';
import ValidationInput, { InputProps } from '../../../components/forms/inputs/ValidationInput';

export default function FirstLastNameInputs<V, Values>({
  label,
  firstNameProps,
  lastNameProps,
}: {
  label: string;
  firstNameProps: { name: string } & Partial<InputProps<V, Values>>;
  lastNameProps: { name: string } & Partial<InputProps<V, Values>>;
}) {
  return (
    <FormGroup>
      <Col xs={3} style={{ padding: 0 }}>
        <label htmlFor={firstNameProps.name}>{label}</label>
      </Col>
      <Col xs={9} style={{ padding: '0px 8px' }}>
        <Field
          label="First Name"
          placeholder="Name"
          {...firstNameProps}
          type="text"
          topLabel
          component={ValidationInput}
        />

        <Field
          label="Last Name"
          placeholder="Name"
          {...lastNameProps}
          type="text"
          topLabel
          component={ValidationInput}
        />
      </Col>
    </FormGroup>
  );
}
