const INFO_SEND = 'redux/info/INFO_SEND';
const INFO_DISMISS = 'redux/info/INFO_DISMISS';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INFO_SEND:
      return {
        ...state,
        message: action.payload.message,
        onDismiss: action.payload.onDismiss,
      };
    case INFO_DISMISS:
      return {
        ...state,
        message: null,
        onDismiss: null,
      };
    default:
      return state;
  }
}

export function infoSend(message, onDismiss) {
  return {
    type: INFO_SEND,
    payload: {
      message,
      onDismiss,
    },
  };
}

export function infoDismiss() {
  return {
    type: INFO_DISMISS,
  };
}
