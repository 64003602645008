import { useState, useEffect, createContext, useMemo, useContext } from 'react';
import * as dealerService from '../../services/dealerService';
import { getCityStateZipFromDetails } from '../../services/dealerService';

export interface IDealerContext {
  dealershipName?: string;
  dealerDetails?: dealerService.DealerDetails;
  dealerState?: string;
  upgradeEligibleProducts: dealerService.ProductUpgradeOptions | null;
  setDealerDetails(details: dealerService.DealerDetails): void;
  setDealershipName(dealershipName: string): void;
}

export const DealerContext = createContext<IDealerContext | undefined>(undefined);

// note: fun fact: react + TS cannot handle a tuple return type [T, (v:T) => void]
function useStateWithInitialValue<T>(initialValue: T): { value; setValue } {
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    if (value == null || value !== initialValue) {
      setValue(initialValue);
    }
  }, [value, initialValue]);

  return { value, setValue };
}

export function useDealerContextDefaultValue({
  dealerDetails: initialDealerDetails,
  dealershipName: initialDealershipName,
  dealerState: initialDealerState,
}: {
  dealershipName?: string;
  dealerState?: string;
  dealerDetails?: IDealerContext['dealerDetails'];
}): IDealerContext {
  const { value: dealerDetails, setValue: setDealerDetails } = useStateWithInitialValue<
    IDealerContext['dealerDetails']
  >(initialDealerDetails);

  const { value: dealershipName, setValue: setDealershipName } = useStateWithInitialValue<string>(
    initialDealershipName,
  );

  const { state: stateFromDetails } = getCityStateZipFromDetails(dealerDetails?.dlCityStzip);
  const { value: dealerState } = useStateWithInitialValue<string>(
    initialDealerState ?? stateFromDetails,
  );

  const upgradeEligibleProducts = dealerService.hooks.useEligibleUpgradeProducts(dealerDetails);

  return useMemo(
    () => ({
      dealershipName,
      dealerDetails,
      dealerState,
      upgradeEligibleProducts,
      setDealerDetails,
      setDealershipName,
    }),
    [
      dealershipName,
      dealerDetails,
      dealerState,
      upgradeEligibleProducts,
      setDealerDetails,
      setDealershipName,
    ],
  );
}

export default function useDealerContext() {
  const postsContext = useContext(DealerContext);
  if (!postsContext) {
    throw new Error('useDealerContext must be used within the PostsContext.Provider');
  }
  return postsContext;
}
