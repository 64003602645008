import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

function FieldGroup({ id, label, error, touched, ...props }) {
  return (
    <FormGroup controlId={id} validationState={error && touched ? 'error' : null}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {error && touched && <span className="glyphicon glyphicon-remove form-control-feedback" />}
      {error && touched && (
        <div className="text-danger">
          <strong>{error}</strong>
        </div>
      )}
    </FormGroup>
  );
}

const ValidationInput = ({
  input,
  label,
  type,
  placeholder,
  disabled,
  meta: { touched, error },
  topLabel = false,
}) => {
  if (topLabel) {
    return (
      <FieldGroup
        {...input}
        id={input.name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
        touched={touched}
        label={label}
      />
    );
  }
  return (
    <div className={`form-group ${error && touched ? 'has-error' : ''}`}>
      <label htmlFor={input.name} className="col-sm-3">
        {label}
      </label>
      <div className="col-sm-9">
        <input
          {...input}
          id={input.name}
          type={type}
          className="form-control"
          placeholder={placeholder}
          disabled={disabled}
        />
        {error && touched && <span className="glyphicon glyphicon-remove form-control-feedback" />}
        {error && touched && (
          <div className="text-danger">
            <strong>{error}</strong>
          </div>
        )}
      </div>
    </div>
  );
};

ValidationInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  topLabel: PropTypes.bool,
};

ValidationInput.defaultProps = {
  input: {},
  placeholder: '',
  disabled: false,
  topLabel: false,
};

export default ValidationInput;
