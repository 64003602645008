export const getPasswordRequirements = password => {
  const requirements = [];
  if (password.length < 8) {
    requirements.push('at least 8 characters');
  }

  if (!/[A-Z]/.test(password)) {
    requirements.push('at least one upper-case character');
  }

  if (!/[a-z]/.test(password)) {
    requirements.push('at least one lower-case character');
  }

  if (!/\d/.test(password)) {
    requirements.push('at least one digit');
  }

  return requirements;
};

export const validatePasswordStrength = password => {
  if (password && getPasswordRequirements(password).length) {
    return 'Password is too weak';
  }
};
