import React from 'react';
import { map, startCase } from 'lodash';
import { Table } from 'react-bootstrap';

export default ({ headers = [], data = [], onClick, ...props }) => (
  <Table responsive {...props}>
    <thead>
      <tr>
        {headers.map(f => (
          <td key={f}>{startCase(f)}</td>
        ))}
      </tr>
    </thead>
    <tbody>
      {map(data, (row, idx) => (
        <tr key={idx} onClick={() => onClick && onClick(row)}>
          {map(row, (v, i) => (
            <td key={v || i}>{v}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </Table>
);
