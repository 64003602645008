import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import NewDealerForm from '../NewDealer/NewDealerForm';
import { NewDealerPage } from '../NewDealer/types';
import { createDealerUpdateRequest } from '../../../redux/modules/dealer';

const dlCityStzipParse = dlCityStzip => {
  // example: "Sherman Oaks, CA 91423"
  const match = /^(.*?),? ?([A-Z]{2,2})? ?(\d+)?$/.exec(dlCityStzip || '');
  if (match)
    return {
      city: match[1],
      state: match[2],
      zip: match[3],
    };
  else return {};
};

const dlOwnerParse = dlOwner => ({
  ownerFirstName: (dlOwner || '').split(' ')[0],
  ownerLastName: (dlOwner || '')
    .split(' ')
    .slice(1)
    .join(' '),
});

export const DealerUpdateFormComponent = props => {
  const { dealerDetails, createDealerUpdateRequest, onDealerUpdated } = props;

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!success) return undefined;
    const timeout = setTimeout(() => {
      onDealerUpdated(dealerDetails.dlid);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [dealerDetails.dlid, success, onDealerUpdated]);

  const handleSubmit = useCallback(
    data => {
      createDealerUpdateRequest({
        prcoDealerId: dealerDetails.dlid,
        ...data,
      }).then(() => {
        setSuccess(true);
      });
    },
    [createDealerUpdateRequest, dealerDetails.dlid],
  );

  const initialFormValues = useMemo(
    () =>
      dealerDetails && {
        [NewDealerPage.DEALER_INFORMATION]: {
          dealershipName: dealerDetails.dlCompany,
          dealerContactEmail: dealerDetails.dlEmail,
          appointmentDate: new Date(),
        },
        [NewDealerPage.DEALER_CONTACT]: {
          streetAddress: dealerDetails.dlAddress,

          // city,
          // state,
          // zip,
          ...dlCityStzipParse(dealerDetails.dlCityStzip),

          telephone: dealerDetails.dlPhone,
          fax: dealerDetails.dlFax,

          // ownerFirstName,
          // ownerLastName,
          ...dlOwnerParse(dealerDetails.dlOwner),
          ePrcoAccountEmail: '',
        },
        [NewDealerPage.DEALER_CONTEXT]: {},
        [NewDealerPage.DEALER_PRODUCTS]: {
          appointedProducts: [],
          supplementalProducts: [],
          isGapPlus: false,
          isBHPH: false,
          retailPack: false,
          retailPackProducts: [],
          rateType: 'STANDARD',
          includeNCBAddendum: false,
          ncbAddendumProducts: [],
          productionIncentive: false,
          productionIncentiveProducts: [],
          hasPENIntegration: false,
          dealerW9Forms: [],
        },
      },
    [dealerDetails],
  );

  if (success)
    return (
      <Alert color="success">
        <p>Thank you!</p>
        <p>Dealer has been updated.</p>
      </Alert>
    );

  return <NewDealerForm initialFormValues={initialFormValues} onSubmit={handleSubmit} />;
};

export default connect(() => ({}), { createDealerUpdateRequest })(DealerUpdateFormComponent);
