import _ from 'lodash';
import api from '../../utils/service';

const GET_DEPLOYMENT_SUCCESS = 'redux/deployment/GET_DEPLOYMENT_SUCCESS';
const GET_DEPLOYMENT_FAIL = 'redux/deployment/GET_DEPLOYMENT_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DEPLOYMENT_SUCCESS:
      return {
        ...state,
        ...action.data,
        error: null,
      };
    case GET_DEPLOYMENT_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getDeployment() {
  return dispatch =>
    api
      .get('/deployment')
      .then(({ data }) => dispatch(getDeploymentSuccess(data)))
      .catch(error => dispatch(getDeploymentFail(error)));
}

export function getDeploymentSuccess(data) {
  return {
    type: GET_DEPLOYMENT_SUCCESS,
    data,
  };
}

export function getDeploymentFail(error) {
  return {
    type: GET_DEPLOYMENT_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}
