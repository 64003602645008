import _ from 'lodash';
import { reset } from 'redux-form';

export const PROFILE_FORM = 'PROFILE_FORM';
export const PROFILE_CHANGE_PASSWORD_FORM = 'PROFILE_CHANGE_PASSWORD_FORM';
export const NEW_USER_FORM = 'newUser';
export const NEW_DEALER_INFO_FORM = 'newDealerInfo';
export const NEW_DEALER_CONTACT_INFO_FORM = 'newDealerContactInfo';
export const NEW_DEALER_CONTEXT_FORM = 'newDealerContext';
export const NEW_DEALER_PRODUCTS_FORM = 'newDealerProducts';
export const TEMPLATE_META_DATA_FORM = 'templateMetaData';
export const UPGRADE_DEALER_DEALER_INFORMATION_FORM = 'upgradeDealerDealerInformation';
export const UPGRADE_DEALER_PRODUCTS_FORM = 'upgradeDealerProducts';

const ALL_FORMS = [
  PROFILE_FORM,
  PROFILE_CHANGE_PASSWORD_FORM,
  NEW_USER_FORM,
  // TEMPLATE_META_DATA_FORM,
  UPGRADE_DEALER_DEALER_INFORMATION_FORM,
  UPGRADE_DEALER_PRODUCTS_FORM,
];

export function clearForms() {
  return dispatch => _.each(ALL_FORMS, form => dispatch(reset(form)));
}

export function clearForm(form) {
  return dispatch => dispatch(reset(form));
}
