import React from 'react';
import { connect } from 'react-redux';
import { Checkbox, Form, FormGroup } from 'react-bootstrap';
import {
  toggleAllFieldsAreExpected,
  toggleAllFieldsAreRequired,
} from '../../../redux/modules/formTemplateAdmin';

const toState = ({ formTemplateAdmin }) => ({
  ...formTemplateAdmin,
});

const TemplateFieldSelectionOptions = ({
  toggleAllFieldsAreExpected,
  toggleAllFieldsAreRequired,
  allTemplateFieldsAreExpected,
  allTemplateFieldsAreRequired,
}) => (
  <Form>
    <FormGroup>
      <Checkbox
        inline
        onChange={() => toggleAllFieldsAreExpected(!allTemplateFieldsAreExpected)}
        checked={allTemplateFieldsAreExpected}
      >
        All selected fields are expected to be present
      </Checkbox>
      <Checkbox
        inline
        onChange={() => toggleAllFieldsAreRequired(!allTemplateFieldsAreRequired)}
        checked={allTemplateFieldsAreRequired}
      >
        All selected fields are required
      </Checkbox>
    </FormGroup>
  </Form>
);

export default connect(toState, {
  toggleAllFieldsAreExpected,
  toggleAllFieldsAreRequired,
})(TemplateFieldSelectionOptions);
