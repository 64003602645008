export enum DealerOwnershipStructure {
  INDIVIDUAL = 'INDIVIDUAL',
  PARTNERSHIP = 'PARTNERSHIP',
  CORPORATION = 'CORPORATION',
  JOINT_VENTURE = 'JOINT_VENTURE',
  OTHER = 'OTHER',
}

export enum RateType {
  STANDARD = 'STANDARD',
  SPECIAL = 'SPECIAL',
}

export enum DealerSoftware {
  ASN = 'ASN',
  PRCO_POWER = 'PRCO_POWER',
  NO_SOFTWARE = 'NO_SOFTWARE',
}

export enum ProductionIncentiveSplitType {
  DEALER_ONLY = 'DEALER_ONLY',
  SPLIT_DEALER_INDIVIDUAL = 'SPLIT_DEALER_INDIVIDUAL',
  INDIVIDUAL_ONLY = 'INDIVIDUAL_ONLY',
  SPLIT_INDIVIDUALS = 'SPLIT_INDIVIDUALS',
}

export const dealerTypeOptions = [
  {
    value: null,
    label: 'Please Select a Type',
  },
  {
    value: DealerOwnershipStructure.INDIVIDUAL,
    label: 'Individual',
  },
  {
    value: DealerOwnershipStructure.PARTNERSHIP,
    label: 'Partnership',
  },
  {
    value: DealerOwnershipStructure.CORPORATION,
    label: 'Corporation',
  },
  {
    value: DealerOwnershipStructure.JOINT_VENTURE,
    label: 'Joint Venture',
  },
  {
    value: DealerOwnershipStructure.OTHER,
    label: 'Other',
  },
];

export const yesNoOptions = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const asnSoftwareOptions = [
  {
    value: null,
    label: 'Please Select a Software',
  },
  {
    value: DealerSoftware.PRCO_POWER,
    label: 'PRcoPower',
  },
  {
    value: DealerSoftware.ASN,
    label: 'ASN',
  },
  {
    value: DealerSoftware.NO_SOFTWARE,
    label: 'No Software',
  },
];

export const rateTypeOptions = [
  {
    value: RateType.STANDARD,
    label: 'Standard Rates',
  },
  {
    value: RateType.SPECIAL,
    label: 'Special Rates',
  },
];

export const productionIncentiveSplitOptions = [
  { value: null, label: 'Select Type' },
  { value: ProductionIncentiveSplitType.DEALER_ONLY, label: 'DEALER_ONLY' },
  { value: ProductionIncentiveSplitType.INDIVIDUAL_ONLY, label: 'INDIVIDUAL_ONLY' },
  {
    value: ProductionIncentiveSplitType.SPLIT_DEALER_INDIVIDUAL,
    label: 'SPLIT_DEALER_INDIVIDUAL',
  },
  { value: ProductionIncentiveSplitType.SPLIT_INDIVIDUALS, label: 'SPLIT_INDIVIDUALS' },
];
