import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';

export default function FormBreadcrumb<T = string>({
  items,
  current,
  onClick,
  disableLastItem = true,
}: {
  items: T[];
  current: T;
  onClick: (activePage: T) => void;
  disableLastItem?: boolean;
}): React.ReactElement {
  return (
    <Breadcrumb>
      {items.map((item, index) => (
        <BreadcrumbItem
          active={item === current}
          key={index}
          // @ts-expect-error
          disabled={disableLastItem && index === items.length - 1}
          onClick={() => onClick && onClick(item)}
        >
          {item}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}
