import React from 'react';
import FormBreadcrumb from '../FormBreadcrumb';
import UpgradeDealerInformation, {
  UpgradeDealerInformationValues,
  upgradeDealerInformationSchema,
} from './UpgradeDealerInformation';
import UpgradeDealerProducts, {
  UpgradeDealerProductValues,
  upgradeDealerProductSchema,
} from './UpgradeDealerProducts';
import { UpgradeDealerPage, UpgradeDealerFormInputsByPage, UpgradeDealerFormInputs } from './types';
import DealerInfoCheck from '../DealerInfoCheck';
import { DealerContext, IDealerContext, useDealerContextDefaultValue } from '../DealerContext';
import * as dealerService from '../../../services/dealerService';
import FormikWizardButtons from '../FormikWizardButtons';
import useDealerTestData from '../useDealerTestData';
import { FinalDealerInput } from '../../../services/dealerService/hooks';

const renderActiveFormPage = ({
  activePage,
  pageValues,
  combinedValues,
  onPageSubmit,
  onBack,
}: {
  onBack: onPageChange;
} & Pick<FormData, 'activePage' | 'pageValues' | 'onPageSubmit' | 'combinedValues'>) => {
  switch (activePage) {
    case UpgradeDealerPage.DEALER_INFORMATION:
    default:
      return (
        <UpgradeDealerInformation
          initialValues={pageValues && pageValues[UpgradeDealerPage.DEALER_INFORMATION]}
          onSubmit={values =>
            onPageSubmit({
              pageName: UpgradeDealerPage.DEALER_INFORMATION,
              values,
              nextPage: UpgradeDealerPage.DEALER_PRODUCTS,
            })
          }
        >
          {({ errors, isValid }) => <FormikWizardButtons disableNext={!isValid} errors={errors} />}
        </UpgradeDealerInformation>
      );
    case UpgradeDealerPage.DEALER_PRODUCTS:
      return (
        <UpgradeDealerProducts
          initialValues={pageValues && pageValues[UpgradeDealerPage.DEALER_PRODUCTS]}
          onSubmit={values =>
            onPageSubmit({
              pageName: UpgradeDealerPage.DEALER_PRODUCTS,
              values,
              nextPage: UpgradeDealerPage.DEALER_CHECK,
            })
          }
        >
          {({ values, isValid, errors }) => (
            <FormikWizardButtons
              errors={errors}
              disableNext={!isValid}
              onBack={() =>
                onBack({
                  from: UpgradeDealerPage.DEALER_PRODUCTS,
                  to: UpgradeDealerPage.DEALER_INFORMATION,
                  values,
                })
              }
            />
          )}
        </UpgradeDealerProducts>
      );
    case UpgradeDealerPage.DEALER_CHECK:
      const combinedSchema = upgradeDealerInformationSchema.concat(upgradeDealerProductSchema);

      return (
        <DealerInfoCheck<UpgradeDealerFormInputs, typeof combinedSchema>
          dealerFormInputs={combinedValues}
          combinedSchema={combinedSchema}
          displayName="Upgrade Dealer form"
        >
          {({ isValid }) => (
            <FormikWizardButtons
              disableNext={!isValid}
              nextButtonText="Submit"
              onBack={() => onBack({ to: UpgradeDealerPage.DEALER_PRODUCTS })}
              onNext={() =>
                onPageSubmit({
                  pageName: UpgradeDealerPage.DEALER_CHECK,
                  values: null,
                  nextPage: null,
                })
              }
            />
          )}
        </DealerInfoCheck>
      );
  }
};

interface UpgradeDealerProps {
  dealerDetails: dealerService.DealerDetails;
  initialFormValues?: UpgradeDealerFormInputsByPage;
  initialActivePage?: UpgradeDealerPage;
  onSubmit: (formInputs: FinalDealerInput) => Promise<void>;
}

type PageSubmit = (
  info:
    | {
        pageName: UpgradeDealerPage.DEALER_INFORMATION;
        values: UpgradeDealerInformationValues;
        nextPage: UpgradeDealerPage;
      }
    | {
        pageName: UpgradeDealerPage.DEALER_PRODUCTS;
        values: UpgradeDealerProductValues;
        nextPage: UpgradeDealerPage;
      }
    | {
        pageName: UpgradeDealerPage.DEALER_CHECK;
        values: null;
        nextPage: null;
      },
) => Promise<void>;

type onPageChange = (backArgs: {
  from?: UpgradeDealerPage;
  to: UpgradeDealerPage;
  values?: UpgradeDealerFormInputs;
}) => void;

interface FormData {
  dealerContext: IDealerContext;
  pageValues: UpgradeDealerFormInputsByPage;
  combinedValues: UpgradeDealerInformationValues & UpgradeDealerProductValues;
  activePage: UpgradeDealerPage;
  onPageSubmit: PageSubmit;
  onActivePageChange: onPageChange;
}

const useFormData = ({
  dealerDetails,
  initialFormValues,
  initialActivePage,
  onSubmit,
}: UpgradeDealerProps): FormData => {
  const dealerContext = useDealerContextDefaultValue({ dealerDetails });

  const hasSpecialRateProducts = dealerService.hooks.useUpgradesHaveSpecialRatesProducts(
    dealerContext.upgradeEligibleProducts,
  );
  const { getUpgradeDealerTestData } = useDealerTestData();

  const [pageValues, setPageValues] = React.useState<UpgradeDealerFormInputsByPage>(
    initialFormValues ??
      getUpgradeDealerTestData({ dealerContactEmail: dealerDetails.dlEmail }) ?? {
        [UpgradeDealerPage.DEALER_INFORMATION]: {
          dealerContactEmail: dealerDetails.dlEmail,
          appointmentDate: new Date(),
          hasPENIntegration: false,
        },
        [UpgradeDealerPage.DEALER_PRODUCTS]: {
          appointedProducts: [],
          supplementalProducts: [],
          isGapPlus: false,
          isBHPH: false,
          retailPack: false,
          // asn: DealerSoftware.NO_SOFTWARE,
          retailPackProducts: [],
          rateType: hasSpecialRateProducts ? 'SPECIAL' : 'STANDARD',
          includeNCBAddendum: false,
          ncbAddendumProducts: [],
          productionIncentive: false,
          productionIncentiveProducts: [],
          dealerW9Forms: [],
        },
      },
  );
  const [activePage, setActivePage] = React.useState<UpgradeDealerPage>(
    initialActivePage ?? UpgradeDealerPage.DEALER_INFORMATION,
  );

  const dealerDetailInformation = dealerService.hooks.useDealerDetailsAsFormInputs(
    dealerDetails,
    pageValues[UpgradeDealerPage.DEALER_PRODUCTS].appointedProducts,
  );

  const combinedValues = Object.values(pageValues).reduce(
    (combined, values) => ({ ...combined, ...values }),
    dealerDetailInformation,
  );

  const dealer = dealerService.hooks.useTrimUnusedDealerFields(combinedValues);
  const finalDealer = dealerService.hooks.useConvertedValues(combinedValues);

  const onPageSubmit: PageSubmit = async ({ pageName, values, nextPage }) => {
    setPageValues({ ...pageValues, [pageName]: values });
    if (nextPage) {
      setActivePage(nextPage);
    }

    if (nextPage == null && pageValues) {
      // if no next page, then its a final submittal
      // combine the form inputs with values we derived from the dealerDetails
      await onSubmit(finalDealer);
    }
  };

  const handleActivePageChange: onPageChange = ({ from, to, values }) => {
    // here we are storing partial inputs to the form - to better UX
    if (values != null && from != null) {
      setPageValues({ ...pageValues, [from]: values });
    }
    setActivePage(to);
  };

  return {
    dealerContext,
    pageValues,
    combinedValues: dealer,
    activePage,
    onPageSubmit,
    onActivePageChange: handleActivePageChange,
  };
};

export default function UpgradeDealerForm(props: UpgradeDealerProps) {
  const {
    dealerContext,
    pageValues,
    combinedValues,
    activePage,
    onActivePageChange,
    onPageSubmit,
  } = useFormData(props);

  return (
    <div>
      <DealerContext.Provider value={dealerContext}>
        <FormBreadcrumb
          items={Object.values(UpgradeDealerPage)}
          current={activePage}
          onClick={nextPage => onActivePageChange({ to: nextPage })}
          disableLastItem={process.env.NODE_ENV !== 'development'}
        />
        {renderActiveFormPage({
          pageValues,
          combinedValues,
          activePage,
          onPageSubmit,
          onBack: onActivePageChange,
        })}
      </DealerContext.Provider>
    </div>
  );
}
