import _ from 'lodash';
import moment from 'moment';
import { splitStringsIntoArray } from './string';
import { ProductionIncentiveSplitType } from '../containers/forms/constants';
import * as dealerService from '../services/dealerService';

const isEmpty = value => value === undefined || value === null || value === '';
const join = rules => (value, data, params) =>
  rules.map(rule => rule(value, data, params)).filter(error => !!error)[0];

export function email(value) {
  // Let's not start a debate on email regex. This is just for an example app!
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address';
  }
}

export function required(value) {
  if (isEmpty(value)) {
    return 'Required';
  }
}

export function minLength(min) {
  return value => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max) {
  return value => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }
  };
}

export function minNumber(min) {
  return value => {
    if (!isEmpty(value) && parseFloat(value) < min) {
      return `Must be no lower than ${min}`;
    }
  };
}

export function maxNumber(max) {
  return value => {
    if (!isEmpty(value) && parseFloat(value) > max) {
      return `Must be no higher than ${max}`;
    }
  };
}

export function exactLength(len) {
  return value => {
    if (!isEmpty(value) && (value.length > len || value.length < len)) {
      return `Must be exactly ${len} characters`;
    }
  };
}

export function integer(value) {
  if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return 'Must be a rounded number';
  }
}

export function wholeDollarAmount(value) {
  if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return 'Must be a Must be a whole dollar amount, no partial dollar amounts permitted.';
  }
}

export function number(value) {
  const parsed = parseFloat(value);
  if (isNaN(parsed)) {
    return 'Must be a number';
  }
}

export function money(value) {
  if (!isEmpty(value) && (!/^\d+(\.\d{2})?$/.test(value) || parseFloat(value) <= 0)) {
    return 'Must be a valid US currency value (more than 0.00).';
  }
}

export function phoneNumber(value) {
  if (isEmpty(value)) {
    return 'Phone number must be provided';
  }

  if (integer(value) != null) {
    return 'Phone number format should be numbers only (no special characters), example: 8001234567';
  }

  if (value.length > 10) {
    return 'Phone number should be exactly 10 digits';
  }
}

export function words(value) {
  if (!_.isEmpty(value) && !/^[\w\s]+$/.test(value)) {
    return 'Must be valid text (words and spaces only)';
  }
}

export function fullName(value) {
  if (!_.isEmpty(value) && !/^\w+\s(\w+\s)?\w+$/.test(value)) {
    return 'Must be valid full name (First and Last, ex: John Doe)';
  }
}

export function oneOf(enumeration) {
  return value => {
    if (!~enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(', ')}`;
    }
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== _.get(data, field)) {
        return 'Confirmation must match';
      }
    }
  };
}

/**
 * @param minDate? {moment.Moment}
 * @param maxDate? {momont.Moment}
 * @param dateFormat? {string}
 * @return {function(...[*]=)}
 */
export function isWithinDateRange({ minDate, maxDate, dateFormat = 'MM/DD/YYYY' } = {}) {
  if (minDate == null && maxDate == null) {
    throw new Error('isWithinDateRange must have either a minDate/maxDate value or both');
  }
  return value => {
    if (value != null) {
      const momentValue = moment(value, dateFormat);
      if (minDate != null && maxDate == null) {
        return momentValue.isAfter(minDate)
          ? undefined
          : `Date must be after ${minDate.format(dateFormat)}`;
      }
      if (minDate == null && maxDate != null) {
        return momentValue.isBefore(maxDate)
          ? undefined
          : `Date must be before ${maxDate.format(dateFormat)}`;
      }
      if (!(momentValue.isAfter(minDate) && momentValue.isBefore(maxDate))) {
        return `Date must be before ${maxDate.format(dateFormat)} and after ${minDate.format(
          dateFormat,
        )}`;
      }
    }
  };
}

export function stringArrayHasFirstLastName(value) {
  if (value != null) {
    const eachHasAFirstLastName = splitStringsIntoArray([value]).every(
      name => name.split(' ').length >= 2,
    );
    if (!eachHasAFirstLastName) {
      return 'Each name entry must have a first and last';
    }
  }
}

export function ensureFileNumberMatchesNames(value, values) {
  const w9Names = _.uniq(
    splitStringsIntoArray([values.incentivePayeeGAP, values.incentivePayeeVSC]),
  );
  if (w9Names.length > 0 && value == null) {
    return 'You must provide 1 w9 form per incentive payee name';
  }

  if (value != null && Array.isArray(value) && value.length !== w9Names.length) {
    return `You must provide 1 w9 form per incentive payee name, missing ${w9Names.length -
      value.length}`;
  }
}

export function ensureSpecialRateNotesExist(value, values) {
  const w9Names = _.uniq(
    splitStringsIntoArray([values.incentivePayeeGAP, values.incentivePayeeVSC]),
  );

  if (w9Names.length > 0 && isEmpty(value)) {
    return `You must provide an amount breakdown for each special rate recipient(${w9Names.length})`;
  }
}

/**
 *
 * @param alreadyAppointedProducts { DealerProducts[] | undefined } - account for already appointed products
 * @return {function(DealerProducts[] | undefined, *): (string|undefined)}
 */
export function ensureProductMatchesAppointedProduct({ alreadyAppointedProducts } = {}) {
  return (selectedProducts, values) => {
    const { appointedProducts } = values;

    if (selectedProducts != null) {
      const productsCannotBeSelected = selectedProducts.filter(selectedProduct => {
        const productIsNotSelectedForAppointment =
          appointedProducts != null && !appointedProducts.includes(selectedProduct);
        const productIsNotAppointed =
          alreadyAppointedProducts != null && !alreadyAppointedProducts.includes(selectedProduct);
        return productIsNotSelectedForAppointment && productIsNotAppointed;
      });

      if (productsCannotBeSelected.length > 0) {
        return `Dealer is not appointed for (or is not upgrading to) ${productsCannotBeSelected} product; addition not permitted.`;
      }
    }
  };
}

export function ensureNumberOfNamesMatchPayType(value, values) {
  const { productionIncentivePayType } = values;
  if (value != null && productionIncentivePayType != null) {
    const numberOfNames = value.split(',').length;
    if (
      numberOfNames > 1 &&
      [
        ProductionIncentiveSplitType.INDIVIDUAL_ONLY,
        ProductionIncentiveSplitType.DEALER_ONLY,
      ].includes(productionIncentivePayType)
    ) {
      return `${productionIncentivePayType} pay type requires only 1 payee`;
    }
    if (
      numberOfNames > 2 &&
      productionIncentivePayType === ProductionIncentiveSplitType.SPLIT_DEALER_INDIVIDUAL
    ) {
      return `${productionIncentivePayType} pay type requires 1 individual name and the dealer name`;
    }
  }
}

export function ensurePayeesMatchForPayType(value, values, props) {
  const { key } = props || {};
  const {
    productionIncentiveProducts,
    productionIncentivePayType,
    incentivePayeeVSC,
    incentivePayeeGAP,
  } = values;
  if (
    value != null &&
    productionIncentivePayType != null &&
    productionIncentivePayType === ProductionIncentiveSplitType.INDIVIDUAL_ONLY &&
    dealerService.hasVSCProduct(productionIncentiveProducts) &&
    dealerService.hasGAPProduct(productionIncentiveProducts)
  ) {
    if (key === 'incentivePayeeGAP' && value !== incentivePayeeVSC) {
      return `Payees must match`;
    }
    if (key === 'incentivePayeeVSC' && value !== incentivePayeeGAP) {
      return `Payees must match`;
    }
  }
}

export function createValidator(rules, params) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach(key => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(_.get(data, key), data, { key, ...params });
      if (error) {
        _.set(errors, key, error);
      }
    });
    return errors;
  };
}
