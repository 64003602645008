import { useFormikContext } from 'formik';
import { SupplementalProduct, DealerProduct } from '../types';
import * as dealerService from '../dealerProducts';

export default function useSupplementalProductOptions<
  T extends { appointedProducts: DealerProduct[] }
>(): SupplementalProduct[] {
  const { values } = useFormikContext<T>();

  if (dealerService.hasVSCProduct(values.appointedProducts)) {
    return [SupplementalProduct.PRCO_POWER, SupplementalProduct.VSC_SLUDGE];
  }
  return [SupplementalProduct.PRCO_POWER];
}
