import React from 'react';
import { Table } from 'react-bootstrap';
import { map, startCase } from 'lodash';
import * as Yup from 'yup';
import { ValidationError } from 'yup';

const renderValue = ({ key, value }: { key: string; value: any | any[] }) => {
  if (key === 'dealerW9Forms' && Array.isArray(value)) {
    return value.map(({ fileName }) => fileName).join(', ');
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (value instanceof Date) {
    return value.toDateString();
  }
  return value;
};

const renderRows = (validationErrors?: Record<string, string> | null) => (
  value: any,
  key: string,
) => {
  const rowError = validationErrors ? validationErrors[key] : null;
  return (
    <tr key={key} style={{ border: rowError != null ? '1px dashed red' : null }}>
      <td key={key}>{startCase(key)}</td>
      <td key={value}>
        {renderValue({ key, value })}
        <div>{rowError}</div>
      </td>
    </tr>
  );
};

const useDealerDetailsValidation = <T extends object, Schema>({
  dealerFormInputs,
  combinedSchema,
}: {
  dealerFormInputs: T;
  combinedSchema?: Yup.SchemaOf<Schema>;
}): { validationErrors?: Record<string, string> } => {
  const [validationError, setValidationError] = React.useState<ValidationError>();
  React.useEffect(() => {
    const validate = async () => {
      await combinedSchema.validate(dealerFormInputs, { abortEarly: false }).catch(errors => {
        setValidationError(errors);
      });
    };
    if (combinedSchema) {
      validate();
    }
  }, [dealerFormInputs, combinedSchema]);

  const allErrors = validationError?.inner.reduce<Record<string, string>>(
    (errors, { message, path }) => ({
      ...errors,
      [path]: message,
    }),
    {},
  );

  return {
    validationErrors: allErrors,
  };
};

const ValidationErrors = ({
  validationErrors,
}: {
  validationErrors: Record<string, string>;
}): React.ReactElement => {
  return (
    <>
      <hr />
      <legend>Validation Errors</legend>
      <div style={{ border: '1px dashed red' }}>
        <Table responsive condensed striped bordered>
          <thead>
            <tr>
              <td key="item">Item</td>
              <td key="input">Error</td>
            </tr>
          </thead>
          <tbody>{map(validationErrors, renderRows(null))}</tbody>
        </Table>
      </div>
    </>
  );
};

function FormInputTable<T extends {}>({
  dealerFormInputs,
  validationErrors,
}: {
  dealerFormInputs: T;
  validationErrors?: Record<string, string>;
}): React.ReactElement {
  return (
    <>
      <legend>Inputs</legend>
      <Table responsive condensed striped bordered>
        <thead>
          <tr>
            <td key="item">Item</td>
            <td key="input">Input</td>
          </tr>
        </thead>
        <tbody>{map<T>(dealerFormInputs, renderRows(validationErrors))}</tbody>
      </Table>
    </>
  );
}

export default function DealerInfoCheck<T extends {}, Schema>({
  dealerFormInputs,
  combinedSchema,
  displayName,
  children,
}: {
  dealerFormInputs: T;
  combinedSchema?: Yup.SchemaOf<Schema>;
  displayName: string;
  children: ({ isValid }: { isValid: boolean }) => React.ReactElement;
}) {
  // in general we should never see errors
  const { validationErrors } = useDealerDetailsValidation<T, Schema>({
    dealerFormInputs,
    combinedSchema,
  });

  return (
    <React.Fragment>
      <legend>{displayName}</legend>
      {validationErrors && <ValidationErrors validationErrors={validationErrors} />}
      <FormInputTable dealerFormInputs={dealerFormInputs} validationErrors={validationErrors} />
      {children({ isValid: validationErrors == null })}
    </React.Fragment>
  );
}
