import React from 'react';
import _ from 'lodash';
import { textFilter } from 'react-bootstrap-table2-filter';
import moment from 'moment';
import Badge from 'react-bootstrap/lib/Badge';

const memFunc = (...args) => JSON.stringify([...args]);

const COLUMN_FORMATTERS = {
  balanceDue: v => `$${v.toLocaleString()}`,
  packageStatus: v => (
    <>
      {v.map(status => (
        <Badge key={status}>{status}</Badge>
      ))}
    </>
  ),
  created: v => (v != null ? moment(v).format('ll') : null),
  updated: v => (v != null ? moment(v).format('ll') : null),
  contractCompletedAt: v => (v != null ? moment(v).format('ll') : null),
};

export const collectColumnNames = _.memoize(data =>
  _.chain(data)
    .head()
    .omit('detailId', 'depositRequestId', 'myUuid', 'recordDate', 'recordTime')
    .keys()
    .value(),
);

export const defaultColumnMapper = _.memoize(({ column, columnName, formatter }) => ({
  dataField: column,
  text: columnName || _.upperCase(column),
  sort: true,
  // align: 'center',
  style: { overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' },
  filter: textFilter({ placeholder: 'Filter..', delay: 500 }),
  formatter: formatter || COLUMN_FORMATTERS[column],
  headerStyle: {
    // backgroundColor: "rgb(183, 12, 12, .77)"
  },
}));

export const determineInvoicePaymentSum = _.memoize(
  (data, selected) =>
    _.chain(data)
      .filter(({ contract }) => _.includes(selected, contract))
      .map('balanceDue')
      .map(_.parseInt)
      .sum()
      .value(),
  memFunc,
);

export const scrollToId = id => {
  const element = document.querySelector(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

// eslint-disable-next-line
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const emailValidation = value => !EMAIL_REGEX.test(value);
