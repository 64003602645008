import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Grid, Row, Alert, Checkbox, ProgressBar, Button } from 'react-bootstrap';
import { reduce, get } from 'lodash';
import {
  disableUpload,
  getDefaultFieldListForTemplate,
  storeSelectedTemplateField,
  postTemplateForFieldValidation,
  getTemplateExampleFile,
} from '../../../redux/modules/formTemplateAdmin';
import GenericTable from '../../../components/GenericTable';
import { TEMPLATE_META_DATA_FORM } from '../../../redux/modules/form';
import TemplateFieldSelectionOptions from '../components/TemplateFieldSelectionOptions';

const templateSelector = formValueSelector(TEMPLATE_META_DATA_FORM);

const toState = state => ({
  fields: state.formTemplateAdmin.fields || [],
  selectedFields: state.formTemplateAdmin.selectedFields,
  templateType: templateSelector(state, 'templateType'),
  disableUpload: disableUpload(state),
  error: state.formTemplateAdmin.error,
  templateExampleFileName: state.formTemplateAdmin.templateValidationReturn,
});

const columns = ['fieldName', 'fieldNameAlt', 'fieldType', 'fieldFlags', 'fieldStateOption'];

const fillInMissingColumns = row =>
  reduce(
    columns,
    (acc, column) => ({
      ...acc,
      [column]: get(row, column, ''),
    }),
    {},
  );

class FieldListSelection extends PureComponent {
  componentWillMount() {
    const { getDefaultFieldListForTemplate, templateType } = this.props;
    if (templateType) {
      getDefaultFieldListForTemplate({ templateType });
    }
  }

  render() {
    const {
      goBack,
      fields,
      storeSelectedTemplateField,
      selectedFields,
      templateType,
      disableUpload,
      postTemplateForFieldValidation,
      error,
    } = this.props;

    const Select = ({ row: { fieldName } }) => (
      <Checkbox checked={selectedFields.includes(fieldName)} style={{ margin: 0 }} />
    );

    return (
      <Grid fluid>
        {error && <Alert className="alert-danger">{error}</Alert>}
        {templateType ? (
          <React.Fragment>
            <Row>
              {fields.length === 0 ? (
                <ProgressBar striped active now={100} />
              ) : (
                <TemplateFieldSelectionOptions />
              )}
            </Row>
            <Row style={{ overflowY: 'auto', height: '45vmin' }}>
              {
                <div>
                  <h4>{templateType} Fields</h4>
                  <GenericTable
                    hover
                    headers={columns}
                    onClick={storeSelectedTemplateField}
                    data={fields.map(row => ({
                      ...fillInMissingColumns(row),
                      select: <Select row={row} />,
                    }))}
                  />
                </div>
              }
            </Row>
            <Row>
              <Button
                disabled={disableUpload}
                style={{ float: 'right', marginTop: 8 }}
                bsStyle="primary"
                onClick={postTemplateForFieldValidation}
              >
                Upload & QC
              </Button>
            </Row>
          </React.Fragment>
        ) : (
          <Button bsStyle="link" onClick={goBack}>
            {' '}
            Please select a template type{' '}
          </Button>
        )}
      </Grid>
    );
  }
}

export default connect(toState, {
  getDefaultFieldListForTemplate,
  storeSelectedTemplateField,
  postTemplateForFieldValidation,
  getTemplateExampleFile,
})(FieldListSelection);
