import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Helmet from 'react-helmet';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Grid, Row, Col, Table, Button } from 'react-bootstrap';
import { compose, withState, withProps } from 'recompose';
import cn from 'classnames';
import { getAllUsers, createUser, updateUser, deleteUser } from '../redux/modules/user';
import { clearForm, NEW_USER_FORM } from '../redux/modules/form';
import NewUserForm from '../components/NewUserForm';
import { escapeForRegExp } from '../utils/string';
import { preventDefault, stopPropagation } from '../utils/click';

// NOTE - Client side filtering is fine for now
function filterByColumn(collection, filters) {
  if (!_.isEmpty(filters)) {
    const regExFilters = _.chain(filters)
      .omitBy(_.isEmpty)
      .mapValues(v => new RegExp(escapeForRegExp(v), 'i'))
      .value();
    return _.filter(collection, item => _.every(regExFilters, (v, k) => v.test(_.get(item, k))));
  } else {
    return collection;
  }
}

export class UserAdmin extends Component {
  static propTypes = {
    selectedUser: PropTypes.object,
    users: PropTypes.array,
    currentUser: PropTypes.object.isRequired,
    columnFilters: PropTypes.object.isRequired,
    getAllUsers: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    clearForm: PropTypes.func.isRequired,
    selectUser: PropTypes.func.isRequired,
    updateColumnFilters: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedUser: null,
    users: [],
  };

  componentWillMount() {
    const { getAllUsers } = this.props;
    getAllUsers();
  }

  handleColumnFilter = (property, e) => {
    const { columnFilters, updateColumnFilters } = this.props;
    const value = _.get(e, 'target.value');
    updateColumnFilters({
      ...columnFilters,
      [property]: value,
    });
  };

  handleCreateOrUpdateUser = data => {
    const { selectedUser, createUser, updateUser, getAllUsers, clearForm } = this.props;
    if (_.get(selectedUser, 'userId')) {
      updateUser(selectedUser.userId, data)
        .then(getAllUsers)
        .then(_.partial(clearForm, NEW_USER_FORM));
    } else {
      createUser(data)
        .then(getAllUsers)
        .then(_.partial(clearForm, NEW_USER_FORM));
    }
  };

  isUserSelected = user => _.get(user, 'userId') === _.get(this.props.selectedUser, 'userId');

  render() {
    const { selectedUser, users, currentUser, selectUser, deleteUser } = this.props;
    const isPrcoAdmin = _.get(currentUser, 'salesRepRel.prcoAdmin');
    return (
      <div>
        <Helmet title="User Administration" />

        <Grid>
          <Row>
            <Col xs={12}>
              <NewUserForm
                user={selectedUser}
                currentUser={currentUser}
                onSubmit={this.handleCreateOrUpdateUser}
              />
            </Col>
          </Row>
          <Row className="form-list-gap">
            <Col xs={12}>
              <legend>All Users</legend>
              <Table responsive hover>
                <tr>
                  {isPrcoAdmin && <th>Sales Rep ID</th>}
                  {isPrcoAdmin && <th>PRco Admin</th>}
                  <th>Email</th>
                  <th>Full Name</th>
                  <th>Created</th>
                  <th>&nbsp;</th>
                </tr>
                <tbody>
                  {(() => {
                    if (isPrcoAdmin) {
                      return (
                        <tr>
                          <td>
                            <input
                              className="form-control"
                              placeholder="eg. 587"
                              style={{ width: '100px' }}
                              onChange={_.partial(
                                this.handleColumnFilter,
                                'salesRepRel.salesRepId',
                              )}
                            />
                          </td>
                          <td colSpan={1}>&nbsp;</td>
                          <td>
                            <input
                              className="form-control"
                              placeholder="eg. bwright@directautosales.com"
                              onChange={_.partial(this.handleColumnFilter, 'email')}
                            />
                          </td>
                          <td colSpan={2}>&nbsp;</td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td colSpan={1}>&nbsp;</td>
                          <td>
                            <input
                              className="form-control"
                              placeholder="eg. bwright@directautosales.com"
                              onChange={_.partial(this.handleColumnFilter, 'email')}
                            />
                          </td>
                          <td colSpan={2}>&nbsp;</td>
                        </tr>
                      );
                    }
                  })()}
                  {_.map(users, user => {
                    const created = moment(user.created).format('MMM Do YYYY, h:mm:ss a');
                    const userIsPrcoAdmin = _.get(user, 'salesRepRel.prcoAdmin');
                    return (
                      <tr
                        key={user.userId}
                        className={cn({ selected: this.isUserSelected(user) })}
                        onClick={() =>
                          !this.isUserSelected(user) ? selectUser(user) : selectUser(null)
                        }
                      >
                        {isPrcoAdmin && <td>{_.get(user, 'salesRepRel.salesRepId')}</td>}
                        {isPrcoAdmin && <td>{userIsPrcoAdmin ? 'Yes' : '--'}</td>}
                        <td>{user.email}</td>
                        <td>
                          {_.get(user, 'salesRepInfo.firstName')}{' '}
                          {_.get(user, 'salesRepInfo.lastName')}
                        </td>
                        <td>{created}</td>
                        <td>
                          {isPrcoAdmin && (
                            <Button
                              bsStyle="danger"
                              onClick={_.flow(
                                preventDefault,
                                stopPropagation,
                                _.partial(deleteUser, user.userId),
                              )}
                            >
                              Delete
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withState('selectedUser', 'selectUser'),
  withState('columnFilters', 'updateColumnFilters', {}),
  connect(
    ({ auth, user }) => ({
      currentUser: auth.user,
      users: user.list,
    }),
    {
      getAllUsers,
      createUser,
      updateUser,
      deleteUser,
      clearForm,
    },
  ),
  withProps(({ users, columnFilters }) => ({
    users: filterByColumn(users, columnFilters),
  })),
)(UserAdmin);
