import * as Yup from 'yup';
import { DealerSoftware, ProductionIncentiveSplitType } from './constants';
import { verifyEmail } from '../../redux/modules/email';
import * as dealerService from '../../services/dealerService';
import { splitStringsIntoArray } from '../../utils/string';
import { email } from '../../utils/validation';

/* eslint-disable no-template-curly-in-string */
export const products = () =>
  Yup.array().of(
    Yup.mixed<dealerService.DealerProduct>().oneOf(Object.values(dealerService.DealerProduct)),
  );

export const supplementalProducts = () =>
  Yup.array().of(
    Yup.mixed<dealerService.SupplementalProduct>().oneOf(
      Object.values(dealerService.SupplementalProduct),
    ),
  );

export const asn = Yup.mixed<DealerSoftware>()
  .oneOf(Object.values(DealerSoftware))
  .label('ASN Software');

export const s3FormSchema = Yup.object()
  .shape({
    fileKey: Yup.string(),
    s3Location: Yup.string(),
    fileName: Yup.string(),
    fileLink: Yup.string(),
  })
  .defined();

export const words = Yup.string().matches(/^[\w\s]+$/);

export const validateEmail = Yup.string()
  .email()
  .test('validateEmail', '${path} email is not valid', async (value, testContext) => {
    if (value == null) {
      return false;
    }

    if (email(value) != null) {
      return false;
    }
    try {
      await verifyEmail(value);
      return true;
    } catch (err) {
      testContext.createError(err.message);
      return false;
    }
  });

export const validateW9Forms = Yup.array()
  .of(s3FormSchema)
  .required('Must provide w9 forms for selected pay type');

const ensureNumberOfNamesMatchPayType: Yup.TestFunction<string> = (value, testContext) => {
  const { productionIncentivePayType } = testContext.parent;
  const numberOfNames = value?.split(',').length;

  if (
    numberOfNames > 1 &&
    [
      ProductionIncentiveSplitType.INDIVIDUAL_ONLY,
      ProductionIncentiveSplitType.DEALER_ONLY,
    ].includes(productionIncentivePayType)
  ) {
    return testContext.createError({
      message: 'Invalid ${path}, pay type ${productionIncentivePayType} requires only 1 payee',
      params: { productionIncentivePayType },
    });
  }
  if (
    numberOfNames > 2 &&
    productionIncentivePayType === ProductionIncentiveSplitType.SPLIT_DEALER_INDIVIDUAL
  ) {
    return testContext.createError({
      message:
        '${path} invalid, pay type ${productionIncentivePayType} requires 1 individual name and the dealer name',
      params: { productionIncentivePayType },
    });
  }
  return true;
};

const ensurePayeesMatchForPayType: Yup.TestFunction<string> = (value, testContext) => {
  const { path, parent } = testContext;
  const {
    productionIncentiveProducts,
    productionIncentivePayType,
    incentivePayeeVSC,
    incentivePayeeGAP,
  } = parent;

  if (
    value != null &&
    productionIncentivePayType != null &&
    productionIncentivePayType === ProductionIncentiveSplitType.INDIVIDUAL_ONLY &&
    dealerService.hasVSCProduct(productionIncentiveProducts) &&
    dealerService.hasGAPProduct(productionIncentiveProducts)
  ) {
    if (path === 'incentivePayeeGAP' && value !== incentivePayeeVSC) {
      return testContext.createError({
        message: '${path} invalid, GAP payee must match VSC payee if pay type is individual',
        params: { productionIncentivePayType },
      });
    }
    if (path === 'incentivePayeeVSC' && value !== incentivePayeeGAP) {
      return testContext.createError({
        message: '${path} invalid, VSC payee must match GAP payee if pay type is individual',
        params: { productionIncentivePayType },
      });
    }
  }
  return true;
};

const ensureEachNameHasFirstAndLast: Yup.TestFunction<string> = value => {
  if (value != null) {
    const eachHasAFirstLastName = splitStringsIntoArray([value]).every(
      name => name.split(' ').length >= 2,
    );
    if (!eachHasAFirstLastName) {
      return false;
    }
  }
  return true;
};

export const validateIncentivePayee = Yup.string()
  .test(
    'ensureNumberOfNamesMatchPayType',
    '${path} names are invalid',
    ensureNumberOfNamesMatchPayType,
  )
  .test(
    'ensurePayeesMatchForPayType',
    '${path} incentive payees must match',
    ensurePayeesMatchForPayType,
  )
  .test(
    'ensureEachNameHasFirstAndLast',
    '${path} each name entry must have a first and last name',
    ensureEachNameHasFirstAndLast,
  )
  .defined('Please provide a payee');
