import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Alert from 'react-bootstrap/lib/Alert';

function allNotificationsAndErrors(state, namespace) {
  return _.chain(state.notifs[namespace])
    .concat(
      _.reduce(
        state,
        (acc, subState, key) => {
          if (key !== 'notifs') {
            if (_.get(subState, 'error')) {
              acc.push({
                message: subState.error,
                kind: 'danger',
              });
            }
            if (_.get(subState, 'message')) {
              acc.push({
                message: subState.message,
                kind: 'info',
              });
            }
          }
          return acc;
        },
        [],
      ),
    )
    .compact()
    .value();
}

export class Notifs extends Component {
  static propTypes = {
    notifs: PropTypes.array.isRequired,
    className: PropTypes.string.isRequired,
  };

  render() {
    const { notifs, className } = this.props;
    if (notifs && notifs.length > 0) {
      return (
        <div className={`notif-container ${className}`}>
          {notifs.map(notification => (
            <Alert key={notification.id} bsStyle={notification.kind}>
              {notification.message}
            </Alert>
          ))}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default connect((state, props) => ({
  notifs: allNotificationsAndErrors(state, props.namespace),
}))(Notifs);
