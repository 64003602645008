import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from 'react-bootstrap/lib/Alert';
import { infoDismiss } from '../redux/modules/info';

export class Info extends Component {
  static propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    className: PropTypes.string.isRequired,
    onDismissInitiate: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
  };

  static defaultProps = {
    message: null,
  };

  handleClose = () => {
    const { onDismissInitiate, onDismiss } = this.props;
    onDismissInitiate();
    onDismiss();
  };

  render() {
    const { message, className } = this.props;

    return (
      <div className={`info-container ${className}`}>
        <Alert className="alert-success">
          <div className="row">
            <div className="col-xs-11">{message}</div>
            <div className="col-xs-1">
              <span className="pointer" onClick={this.handleClose}>
                x
              </span>
            </div>
          </div>
        </Alert>
      </div>
    );
  }
}

export default connect(({ info }) => ({ ...info }), { onDismissInitiate: infoDismiss })(Info);
