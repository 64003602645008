import { isEqual } from 'lodash';
import { DealerProduct, SupplementalProduct } from '../dealerService/types';

function checkIfProduct<T>(
  products: T[],
  product: T,
  { checkType }: { checkType: 'includes' | 'excludes' } = { checkType: 'includes' },
): boolean {
  if (products == null) {
    return false;
  }
  if (products.length === 0) {
    return false;
  }
  if (checkType === 'excludes') {
    return !products.includes(product);
  }

  return products.includes(product);
}

export function hasVSCProduct(products: DealerProduct[]): boolean {
  return checkIfProduct<DealerProduct>(products, DealerProduct.VSC, { checkType: 'includes' });
}

export function hasGAPProduct(products: DealerProduct[]): boolean {
  return checkIfProduct<DealerProduct>(products, DealerProduct.GAP, { checkType: 'includes' });
}

export function hasTWPProduct(products: DealerProduct[]): boolean {
  return checkIfProduct<DealerProduct>(products, DealerProduct.TWP, { checkType: 'includes' });
}

export function hasPrcoPower(products: SupplementalProduct[]): boolean {
  return checkIfProduct<SupplementalProduct>(products, SupplementalProduct.PRCO_POWER, {
    checkType: 'includes',
  });
}

export function dealerProductArraysAreEqual(productsA, productsB) {
  return isEqual(productsA, productsB);
}
