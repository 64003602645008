import React from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Grid, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { asnSoftwareOptions, yesNoOptions } from '../constants';
import ValidationInput from '../../../components/forms/inputs/ValidationInput';
import ValidationSelect from '../../../components/forms/inputs/ValidationSelect';
import ValidationMultiCheckbox from '../../../components/forms/inputs/ValidationMultiCheckbox';
import { UpgradeDealerProductValues } from '../UpgradeDealer/UpgradeDealerProducts';
import { NewDealerProductsValues } from '../NewDealer/NewDealerProducts';
import * as dealerService from '../../../services/dealerService';
import useDealerContext from '../DealerContext';
import { products, supplementalProducts, asn } from '../validationSchemas';

export type AppointedProductFieldNames =
  | 'isGapPlus'
  | 'isBHPH'
  | 'bhphLenderName'
  | 'asn'
  | 'prcoPowerLicenseFee';

export const appointedProductValidators: Record<
  | 'appointedProducts'
  | 'isGapPlus'
  | 'isBHPH'
  | 'bhphLenderName'
  | 'asn'
  | 'supplementalProducts'
  | 'requestedPrcoPower'
  | 'prcoPowerLicenseFee',
  Yup.BaseSchema
> = {
  appointedProducts: products()
    .defined()
    .label('Appointed products'),
  supplementalProducts: supplementalProducts()
    .defined()
    .label('Supplemental products'),
  isGapPlus: Yup.boolean()
    .label('Is GAP Plus')
    .notRequired()
    .when('appointedProducts', {
      is: dealerService.hasGAPProduct,
      then: Yup.boolean().required('Please select an option'),
    }),
  isBHPH: Yup.boolean()
    .label('Is BHPH')
    .notRequired()
    .when('appointedProducts', {
      is: dealerService.hasGAPProduct,
      then: Yup.boolean().required('Please select an option'),
    }),
  bhphLenderName: Yup.string()
    .label('BHPH lender name')
    .notRequired()
    .when('isBHPH', { is: true, then: Yup.string().defined('Please provide lender name') }),
  asn: asn.defined(),
  requestedPrcoPower: Yup.boolean().notRequired(),
  prcoPowerLicenseFee: Yup.number()
    .label('PRco Power Amount Paid')
    .notRequired()
    .when('supplementalProducts', {
      is: dealerService.hasPrcoPower,
      then: Yup.number()
        .min(0)
        .required(),
    }),
};

const useShowFields = (): {
  showFields: Set<AppointedProductFieldNames>;
} => {
  const { dealerState, upgradeEligibleProducts } = useDealerContext();
  const { values } = useFormikContext<NewDealerProductsValues | UpgradeDealerProductValues>();
  const showFields = new Set<AppointedProductFieldNames>(['asn']);

  if (dealerService.hasGAPProduct(values.appointedProducts) || upgradeEligibleProducts?.GAP_PLUS) {
    showFields.add('isGapPlus');
    // prevent this field from showing if we know the dealer is in-eligible
    if (dealerState != null && ['ID', 'NV', 'OR', 'WA'].includes(dealerState)) {
      showFields.delete('isGapPlus');
    }
  }

  if (dealerService.hasGAPProduct(values.appointedProducts) || upgradeEligibleProducts?.BHPH) {
    showFields.add('isBHPH');
  }

  if (values.isBHPH) {
    showFields.add('bhphLenderName');
  }

  if (dealerService.hasPrcoPower(values.supplementalProducts)) {
    showFields.add('prcoPowerLicenseFee');
  }

  return { showFields };
};

export default function AppointedProducts({
  appointedProductOptions,
  supplementalProductOptions,
  onAppointedProductsChanged,
}: {
  appointedProductOptions: dealerService.DealerProduct[];
  supplementalProductOptions: dealerService.SupplementalProduct[];
  onAppointedProductsChanged?: (selectedProducts: dealerService.DealerProduct[]) => void;
}): React.ReactElement {
  const { showFields } = useShowFields();

  return (
    <Grid fluid>
      <Row>
        <FieldArray
          name="appointedProducts"
          render={(props): React.ReactElement => (
            /* @ts-expect-error */
            <ValidationMultiCheckbox<dealerService.DealerProduct>
              {...props}
              options={appointedProductOptions}
              label="What products will the dealer be appointed for?"
              onChange={onAppointedProductsChanged}
            />
          )}
        />
      </Row>
      <Row>
        <FieldArray
          name="supplementalProducts"
          render={(props): React.ReactElement => (
            /* @ts-expect-error */
            <ValidationMultiCheckbox<dealerService.SupplementalProduct>
              {...props}
              options={supplementalProductOptions}
              label="What supplemental products will the dealer be appointed for?"
            />
          )}
        />
      </Row>
      {showFields.has('prcoPowerLicenseFee') && (
        <Row>
          <Field
            name="prcoPowerLicenseFee"
            component={ValidationInput}
            type="number"
            min={0}
            label="What is the license fee for PRco Power?"
            placeholder="Please provide an amount"
          />
        </Row>
      )}
      {showFields.has('isGapPlus') && (
        <Row>
          <Field
            name="isGapPlus"
            component={ValidationSelect}
            options={yesNoOptions}
            label="Is the dealer a Gap Plus Dealer?"
            placeholder="Please choose an option"
          />
        </Row>
      )}
      {showFields.has('isBHPH') && (
        <Row>
          <Field
            name="isBHPH"
            component={ValidationSelect}
            options={yesNoOptions}
            label="Is the dealer a Buy Here Pay Here dealer (BHPH)?"
            placeholder="Please choose an option"
          />
        </Row>
      )}
      {showFields.has('bhphLenderName') && (
        <Row>
          <Field
            name="bhphLenderName"
            component={ValidationInput}
            type="text"
            label="Lien Holder/Lender on GAP Waiver"
            placeholder="Either a third party or dealership name"
          />
        </Row>
      )}
      {showFields.has('asn') && (
        <Row>
          <Field
            name="asn"
            component={ValidationSelect}
            options={asnSoftwareOptions}
            label="Does the dealer use an ASN software as their DMS?"
            placeholder="Please choose an option"
          />
        </Row>
      )}
    </Grid>
  );
}
