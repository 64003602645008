import React, { useEffect, useState } from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import { useApi } from '../../utils/service';

const useGetSalesReps = () => {
  const api = useApi();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const fetch = React.useCallback(async () => {
    try {
      const { data } = await api.get('/sales-reps');
      setData(data);
      setError(null);
    } catch (err) {
      setData(null);
      setError(err);
    }
  }, [api]);

  useEffect(() => {
    fetch();
  }, [fetch]);
  return { data, error, fetch };
};

const MULL_VALUE = '';

export default function SalesRepSelect({ onSelect }) {
  const { data } = useGetSalesReps();

  if (data == null) {
    return null;
  }

  const handleSelect = e => {
    const value = e.target.value;
    if (value === MULL_VALUE) {
      return onSelect(null);
    }
    onSelect(e.target.value);
  };

  return (
    <FormGroup controlId="formControlsSelectMultiple">
      <FormControl componentClass="select" onChange={handleSelect}>
        <option value={MULL_VALUE} key={'empty'}>
          Search by Sales Rep.
        </option>
        {data.salesReps.map(sr => (
          <option value={sr._id} key={sr._id}>
            {sr.firstName} {sr.lastName} ({sr.salesRepId})
          </option>
        ))}
      </FormControl>
    </FormGroup>
  );
}
