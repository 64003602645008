import React from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';

export default function IssuedContractsSearch({ helpText, onChange }) {
  return (
    <FormGroup controlId="issued-contracts-search">
      <FormControl onChange={onChange} placeholder="Search Issued Contracts" />
      {helpText && <HelpBlock>{helpText}</HelpBlock>}
    </FormGroup>
  );
}
