import _ from 'lodash';

export function objectPropHasChanged(original, next) {
  return (_.isEmpty(original) && !_.isEmpty(next)) || !_.matches(original)(next);
}

export function safeJsonParse(obj) {
  try {
    return JSON.parse(obj);
  } catch (e) {
    return {};
  }
}

export function isActuallyEmpty(obj) {
  return _.isEmpty(obj) && obj !== true && obj !== false && !_.isNumber(obj);
}
