import _ from 'lodash';

export function preventDefault(e) {
  _.result(e, 'preventDefault');
  return e;
}

export function stopPropagation(e) {
  _.result(e, 'stopPropagation');
  return e;
}
