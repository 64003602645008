import React from 'react';
import { connect } from 'react-redux';
import { Panel, Button, Row, Col } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { TEMPLATE_META_DATA_FORM } from '../../../redux/modules/form';
import templateMetaDataValidation from '../../../components/validation/templateMetaDataValidation';
import ValidationSelect from '../../../components/common/ValidationSelect';
import { templateMetaDataIfRequested } from '../../../utils/testData';

const toState = ({ formTemplateAdmin }) => ({
  templateFileData: formTemplateAdmin.templateFileData,
});

// todo: these should come from the backend
const TEMPLATE_TYPE_OPTIONS = [
  { value: 'bhph_gap_dealer_addendum', label: 'bhph_gap_dealer_addendum' },
  { value: 'commercial_insurance_application', label: 'commercial_insurance_application' },
  { value: 'dealer_memo', label: 'dealer_memo' },
  { value: 'gap_dealer_agreement', label: 'gap_dealer_agreement' },
  { value: 'gap_dealer_reserve_incentives', label: 'gap_dealer_reserve_incentives' },
  { value: 'payment_plan_dealer_addendum', label: 'payment_plan_dealer_addendum' },
  { value: 'vsc_dealer_agreement', label: 'vsc_dealer_agreement' },
  { value: 'vsc_dealer_reserve_incentives', label: 'vsc_dealer_reserve_incentives' },
  { value: 'twp_dealer_agreement', label: 'twp_dealer_agreement' },
  { value: 'twp_dealer_reserve_incentives', label: 'twp_dealer_reserve_incentives' },
  { value: 'prcopower_agreement', label: 'prcopower_agreement' },
  { value: 'vsc_sludge_addendum', label: 'vsc_sludge_addendum' },
];

const TemplateMetaDataForm = ({ goBack, handleSubmit, disabled, templateFileData = {} }) => (
  <div>
    {templateFileData.name && (
      <Panel>
        <Panel.Heading>Please describe {templateFileData.name}'s properties</Panel.Heading>
      </Panel>
    )}
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <Field
        name="templateType"
        type="text"
        label="templateType"
        placeholder="Template Type"
        component={ValidationSelect}
        options={TEMPLATE_TYPE_OPTIONS}
      />
      <Row>
        <Col xs={6}>
          <Button style={{ width: '100%' }} type="button" bsStyle="primary" onClick={goBack}>
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button style={{ width: '100%' }} disabled={disabled} type="submit" bsStyle="primary">
            Next
          </Button>
        </Col>
      </Row>
    </form>
  </div>
);

export default connect(toState)(
  reduxForm({
    form: TEMPLATE_META_DATA_FORM,
    validate: templateMetaDataValidation,
    destroyOnUnmount: false,
    initialValues: templateMetaDataIfRequested(),
  })(TemplateMetaDataForm),
);
