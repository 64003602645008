import React, { PureComponent } from 'react';
import { Alert, Button } from 'react-bootstrap';

export default class ErrorBoundary extends PureComponent {
  state = { hasError: false, errorMessage: null };

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.error(error);
    // eslint-disable-next-line no-console
    console.log(info);
    this.setState({ hasError: true, errorMessage: error.toString() });
  }

  dismissError = () => this.setState({ hasError: false, errorMessage: null });

  render() {
    const { children } = this.props;
    const { hasError, errorMessage } = this.state;

    return hasError ? (
      <Alert
        className="alert-danger"
        onDismiss={this.dismissError}
        style={{ width: 500, margin: 'auto' }}
      >
        <h4>The application has experienced an error</h4>
        {errorMessage}
        <p>You may need to refresh the application.</p>
        <Button bsStyle="danger" onClick={() => document.location.reload()}>
          Refresh
        </Button>
        <span> or </span>
        <Button onClick={this.dismissError}>Dismiss</Button>
      </Alert>
    ) : (
      children
    );
  }
}
