import { useFormikContext } from 'formik';
import { dealerProductArraysAreEqual, hasVSCProduct } from '../dealerProducts';
import { DealerProduct, SupplementalProduct } from '../types';
import { NewDealerFormInputs } from '../../../containers/forms/NewDealer/types';

function getFilteredSelectedProducts(
  selectedSpecialProducts: DealerProduct[],
  newlySelectedAppointedProducts: DealerProduct[],
) {
  if (selectedSpecialProducts.length > 0) {
    const filteredByAppointedProducts = selectedSpecialProducts.filter(p =>
      newlySelectedAppointedProducts.includes(p),
    );
    if (!dealerProductArraysAreEqual(selectedSpecialProducts, filteredByAppointedProducts)) {
      // a product was removed from appointedProducts selection, remove it
      // setFieldValue('retailPackProducts', filteredByAppointedProducts);
      return filteredByAppointedProducts;
    }
  }
  return null;
}

export default function useOnNewDealerAppointedProductsChanged(): (
  newlySelectedAppointedProducts: DealerProduct[],
) => void {
  const { values, setFieldValue } = useFormikContext<NewDealerFormInputs>();

  function setProductsFieldValue(
    field: 'retailPackProducts' | 'productionIncentiveProducts' | 'ncbAddendumProducts',
    value: DealerProduct[],
  ) {
    setFieldValue(field, value);
  }

  function setSupplementalProductsFieldValue(value: SupplementalProduct[]) {
    setFieldValue('supplementalProducts', value);
  }

  function removeVscSludgeFromSupplementalProducts() {
    setSupplementalProductsFieldValue(
      // remove product based supplemental products
      values?.supplementalProducts.filter(sp => sp !== SupplementalProduct.VSC_SLUDGE),
    );
  }

  // note: reps are only allowed to add products that are related to the appointed products,
  // if they change we need to clear them out to avoid allowing a signup for an unapproved product
  return function onAppointedProductsChanged(newlySelectedAppointedProducts): void {
    if (newlySelectedAppointedProducts.length === 0) {
      if (values?.supplementalProducts?.includes(SupplementalProduct.VSC_SLUDGE)) {
        removeVscSludgeFromSupplementalProducts();
      }
      if (values?.retailPackProducts?.length > 0) {
        setProductsFieldValue('retailPackProducts', []);
      }
      if (values?.productionIncentiveProducts?.length > 0) {
        setProductsFieldValue('productionIncentiveProducts', []);
      }
      if (values?.ncbAddendumProducts?.length > 0) {
        setProductsFieldValue('ncbAddendumProducts', []);
      }
    }

    if (
      !hasVSCProduct(newlySelectedAppointedProducts) &&
      values?.supplementalProducts?.includes(SupplementalProduct.VSC_SLUDGE)
    ) {
      removeVscSludgeFromSupplementalProducts();
    }

    if (values?.retailPackProducts?.length > 0) {
      const filteredByAppointedProducts = getFilteredSelectedProducts(
        values?.retailPackProducts ?? [],
        newlySelectedAppointedProducts,
      );
      if (filteredByAppointedProducts != null) {
        // a product was removed from appointedProducts selection, remove it
        setProductsFieldValue('retailPackProducts', filteredByAppointedProducts);
      }
    }

    if (values?.productionIncentiveProducts?.length > 0) {
      const filteredByAppointedProducts = getFilteredSelectedProducts(
        values?.productionIncentiveProducts ?? [],
        newlySelectedAppointedProducts,
      );
      if (filteredByAppointedProducts != null) {
        // a product was removed from appointedProducts selection, remove it
        setProductsFieldValue('productionIncentiveProducts', filteredByAppointedProducts);
      }
    }

    if (values?.ncbAddendumProducts?.length > 0) {
      const filteredByAppointedProducts = getFilteredSelectedProducts(
        values?.ncbAddendumProducts ?? [],
        newlySelectedAppointedProducts,
      );
      if (filteredByAppointedProducts != null) {
        // a product was removed from appointedProducts selection, remove it
        setProductsFieldValue('ncbAddendumProducts', filteredByAppointedProducts);
      }
    }

    if (
      !dealerProductArraysAreEqual(newlySelectedAppointedProducts, [
        DealerProduct.GAP,
        DealerProduct.VSC,
      ])
    ) {
      if (values.isGapPlus) {
        setFieldValue('isGapPlus', false);
      }
      if (values.isBHPH) {
        setFieldValue('isBHPH', false);
      }
    }
  };
}
