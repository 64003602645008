import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

export class OperationComplete extends Component {
  static propTypes = {
    user: PropTypes.object,
    router: PropTypes.object.isRequired,
  };

  static defaultProps = {
    user: null,
  };

  render() {
    const message = _.get(
      this.props.router,
      'location.query.message',
      'Your payment was successful!',
    );
    const buttonText = _.get(
      this.props.router,
      'location.query.buttonText',
      'Submit another payment',
    );

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="text-center">{message}</h2>
          </div>
        </div>
        <div className="row justify-content-xs-center">
          <div className="col-xs-4 col-xs-offset-4">
            <LinkContainer to={this.props.user ? '/payment' : '/guestPayment'}>
              <button className="btn btn-primary btn-block">{buttonText}</button>
            </LinkContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({ user: state.auth.user }))(OperationComplete);
