import { UpgradeDealerInformationValues } from './UpgradeDealerInformation';
import { UpgradeDealerProductValues } from './UpgradeDealerProducts';
import * as dealerService from '../../../services/dealerService';

export enum UpgradeDealerPage {
  DEALER_INFORMATION = 'Information',
  DEALER_PRODUCTS = 'Products',
  DEALER_CHECK = 'Check',
}
export type UpgradeDealerFormInputsByPage = Record<
  UpgradeDealerPage.DEALER_INFORMATION,
  UpgradeDealerInformationValues
> &
  Record<UpgradeDealerPage.DEALER_PRODUCTS, UpgradeDealerProductValues>;

export type UpgradeDealerFormInputs = UpgradeDealerInformationValues & UpgradeDealerProductValues;

export type UpgradeDealer = UpgradeDealerFormInputs & dealerService.DealerDetailsFormInputs;
