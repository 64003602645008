import { Field, Formik, FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import moment, { Moment } from 'moment';
import ErrorBoundary from '../../../components/common/ErrorBoundary';
import DocumentSigner from '../DealerFormFieldGroups/DocumentSigner';
import ValidationInput from '../../../components/forms/inputs/ValidationInput';
import ValidationDatePicker from '../../../components/forms/inputs/ValidationDatePicker';
import renderDateWithLimits from '../../../components/forms/inputs/renderDateWithLimits';
import ValidationTextarea from '../../../components/forms/inputs/ValidationTextarea';
import PENIntegration, { penIntegrationValidators } from '../DealerFormFieldGroups/PENIntegration';

export const upgradeDealerInformationSchema = Yup.object()
  .shape({
    documentSignerFirstName: Yup.string()
      .defined('Signer first name must be provided')
      .label('Document signer first name'),
    documentSignerLastName: Yup.string()
      .defined('Signer last name must be provided')
      .label('Document signer last name'),
    documentSignersPositionTitle: Yup.string()
      .defined('Signer title must be provided')
      .label('Document signers position title'),
    dealerContactEmail: Yup.string()
      .defined('Please provide a contact email')
      .label('Dealer contact email'),
    ownerEmail: Yup.string()
      .label('Owner email')
      .email()
      .defined('Owners email is required'),
    appointmentDate: Yup.date()
      .label('Appointment Date')
      .min(moment().subtract(30, 'days'), 'Date must be within 30 days')
      .max(moment().endOf('day'), 'Date must be today or within 30 days')
      .defined('Appointment Date must be provided'),
    ...penIntegrationValidators,
    notes: Yup.string()
      .notRequired()
      .label('Notes'),
  })
  .defined();

export interface UpgradeDealerInformationValues
  extends Yup.Asserts<typeof upgradeDealerInformationSchema> {}

export default function UpgradeDealerInformation({
  onSubmit,
  initialValues,
  children,
}: {
  onSubmit: (values: UpgradeDealerInformationValues) => void;
  initialValues: UpgradeDealerInformationValues;
  children: (props: FormikProps<UpgradeDealerInformationValues>) => React.ReactElement;
}) {
  const formikHandleSubmit = values => {
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={upgradeDealerInformationSchema}
      onSubmit={formikHandleSubmit}
    >
      {({ values, handleSubmit, ...props }) => (
        <form onSubmit={handleSubmit}>
          <ErrorBoundary>
            <DocumentSigner disableContactEmail={false} />
            <Field
              name="ownerEmail"
              label="Owner's Email"
              type="email"
              component={ValidationInput}
            />
            <Field
              name="appointmentDate"
              label="Change Effective Date"
              type="date"
              component={ValidationDatePicker}
              pickerProps={{
                renderDay: renderDateWithLimits({
                  dateLimiter: (currentDate: Moment): boolean => {
                    const today = moment();
                    const diff = currentDate.diff(today, 'days');
                    return (diff >= -30 && diff < 0) || currentDate.isSame(today, 'day');
                  },
                }),
              }}
            />
            <PENIntegration />
            <hr />
            <Field
              name="notes"
              label="Notes"
              type="textarea"
              placeholder="Additional information about the dealer."
              component={ValidationTextarea}
            />
          </ErrorBoundary>
          {children({ values, handleSubmit, ...props })}
        </form>
      )}
    </Formik>
  );
}
