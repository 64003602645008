import { DealerProduct, ProductField, ProductUpgradeOptions, SupplementalProduct } from '../types';

const productIsAlreadyAppointedOrIsSelected = (
  product: DealerProduct,
  selectedProducts: DealerProduct[],
  appointedProductUpgradeOptions: DealerProduct[],
) => {
  const isSelected = selectedProducts.includes(product);
  // we know its appointed if its not an option for upgrade
  const isAlreadyAppointed = !appointedProductUpgradeOptions.includes(product);
  return isSelected || isAlreadyAppointed;
};

const getProductSelectionStatus = (
  selectedProducts: DealerProduct[],
  appointedProductUpgradeOptions: DealerProduct[],
): {
  GAP: boolean;
  VSC: boolean;
  TWP: boolean;
} => {
  return {
    GAP: productIsAlreadyAppointedOrIsSelected(
      DealerProduct.GAP,
      selectedProducts,
      appointedProductUpgradeOptions,
    ),
    VSC: productIsAlreadyAppointedOrIsSelected(
      DealerProduct.VSC,
      selectedProducts,
      appointedProductUpgradeOptions,
    ),
    TWP: productIsAlreadyAppointedOrIsSelected(
      DealerProduct.TWP,
      selectedProducts,
      appointedProductUpgradeOptions,
    ),
  };
};

/**
 * this takes the default upgrade options the dealer is eligible for,
 * and merges it with what they've actually selected in the form
 * which ensures that child products
 * like retailPack, or incentives are showing options for a selected product.
 * example:
 * 1. dealer can upgrade to GAP, so that means they can upgrade to BHPH, or GAP PLUS
 * 2. GAP has __not__ been selected for upgrade in the form
 * 3. this upgrade is cannot be selected until GAP is selected as an appointed product
 */
export default function useMergeSelectedProductWithUpgradeOptions(
  selectedProducts: DealerProduct[] = [],
  productUpgradeOptions: ProductUpgradeOptions,
): ProductUpgradeOptions {
  if (productUpgradeOptions[ProductField.APPOINTED].length === 0) {
    // dealer has all products appointed and active, so they wont be selecting a product
    return productUpgradeOptions;
  }

  const isAlreadyAppointedOrHasSelected = getProductSelectionStatus(
    selectedProducts,
    productUpgradeOptions.APPOINTED ?? [],
  );

  return {
    [ProductField.APPOINTED]: productUpgradeOptions.APPOINTED,
    [ProductField.BHPH]: isAlreadyAppointedOrHasSelected.GAP,
    [ProductField.GAP_PLUS]: isAlreadyAppointedOrHasSelected.GAP,
    [ProductField.RETAIL_PACK]: [
      ...(isAlreadyAppointedOrHasSelected.VSC ? [DealerProduct.VSC] : []),
      ...(isAlreadyAppointedOrHasSelected.GAP ? [DealerProduct.GAP] : []),
      ...(isAlreadyAppointedOrHasSelected.TWP ? [DealerProduct.TWP] : []),
    ],
    [ProductField.INCENTIVES]: [
      ...(isAlreadyAppointedOrHasSelected.VSC ? [DealerProduct.VSC] : []),
      ...(isAlreadyAppointedOrHasSelected.GAP ? [DealerProduct.GAP] : []),
      ...(isAlreadyAppointedOrHasSelected.TWP ? [DealerProduct.TWP] : []),
    ],
    // only VSC is supported
    [ProductField.NCB_ADDENDUM]: isAlreadyAppointedOrHasSelected.VSC ? [DealerProduct.VSC] : [],
    [ProductField.SUPPLEMENTAL]: [
      SupplementalProduct.PRCO_POWER,
      ...(isAlreadyAppointedOrHasSelected.VSC ? [SupplementalProduct.VSC_SLUDGE] : []),
    ],
  };
}
