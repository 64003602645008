import { ProductUpgradeOptions } from '../types';

export default function useUpgradesHaveSpecialRatesProducts(
  productUpgradeOptions: ProductUpgradeOptions,
): boolean {
  if (productUpgradeOptions.INCENTIVES.length > 0) {
    return true;
  }
  return productUpgradeOptions.NCB_ADDENDUM.length > 0;
}
