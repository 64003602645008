import React from 'react';
import { connect } from 'react-redux';
import { Modal, Checkbox, Button } from 'react-bootstrap';
import {
  selectDealerForCcOnStatement,
  requestStatementForDealers,
  toggleConfirmStatementSendModal,
} from '../../redux/modules/statements';
import { getDealerName } from '../../services/dealerService';

const toState = ({ data, statements }) => ({
  dealerList: statements.selectedDealers,
  ccList: statements.ccSalesRepList,
  show: statements.showConfirmStatementSendModal,
  dealers: data.dealers,
});

const mapDealerIdToName = (dealers, dealerList) =>
  dealerList.map(dlid => getDealerName(dlid)(dealers));

const ConfirmStatementRequests = ({
  onChange,
  onSubmit,
  onClose,
  dealers,
  ccList = [],
  dealerList = [],
  show,
}) => {
  const dealerNameInfo = mapDealerIdToName(dealers, dealerList);
  return (
    <Modal show={show} aria-labelledby="confirm-statement-email-modal">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">The following statements will be sent:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {dealerNameInfo.map(({ dlid, dlCompany }) => {
          const selected = ccList.includes(dlid);
          return (
            <div key={dlid}>
              {dlCompany} | {'NO DEALER NAME'}
              <Checkbox
                style={{ display: 'inline-block' }}
                checked={selected}
                onChange={() => onChange({ isSelect: !selected, id: dlid })}
              >
                Send to dealer as well?
              </Checkbox>
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="danger" onClick={() => onClose(false)}>
          Close
        </Button>
        <Button
          bsStyle="primary"
          onClick={() => {
            onSubmit(dealerList, ccList);
            onClose(false);
          }}
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(toState, {
  onChange: selectDealerForCcOnStatement,
  onSubmit: requestStatementForDealers,
  onClose: toggleConfirmStatementSendModal,
})(ConfirmStatementRequests);
