import { get } from 'lodash';
import api from '../../utils/service';
import { notifSend } from './notifs';

export const SELECT_DEALER_FOR_STATEMENT = 'SELECT_DEALER_FOR_STATEMENT';
export const selectDealerForStatement = payload => ({
  type: SELECT_DEALER_FOR_STATEMENT,
  payload,
});

export const SELECT_ALL_DEALERS_FOR_STATEMENT = 'SELECT_ALL_DEALERS_FOR_STATEMENT';
export const selectAllDealersForStatement = payload => ({
  type: SELECT_ALL_DEALERS_FOR_STATEMENT,
  payload,
});

export const SELECT_DEALER_FOR_CC_ON_STATEMENT = 'SELECT_DEALER_FOR_CC_ON_STATEMENT';
export const selectDealerForCcOnStatement = payload => ({
  type: SELECT_DEALER_FOR_CC_ON_STATEMENT,
  payload,
});

export const TOGGLE_CONFIRM_STATEMENT_SEND_MODAL = 'TOGGLE_CONFIRM_STATEMENT_SEND_MODAL';
export const toggleConfirmStatementSendModal = payload => ({
  type: TOGGLE_CONFIRM_STATEMENT_SEND_MODAL,
  payload,
});

export const TOGGLE_DEALER_DETAILS_MODAL = 'TOGGLE_DEALER_DETAILS_MODAL';
export const toggleDealerDetailsModal = payload => ({
  type: TOGGLE_DEALER_DETAILS_MODAL,
  payload,
});

export const CLEAR_DEALER_CC_LLIST = 'CLEAR_DEALER_CC_LLIST';
export const clearDealerCcLlist = () => ({
  type: CLEAR_DEALER_CC_LLIST,
});

const handleRequestStatementError = (err, dispatch) => {
  const { response } = err || {};
  const { data = {} } = response;
  const { message, repPortalRequestId, wsRepPortalRequestId } = data;
  const errorMessage = message
    ? `${message}, request ID: ${repPortalRequestId}, portal-request ID: ${wsRepPortalRequestId}`
    : err.toString();
  dispatch(
    notifSend({
      message: `ERROR: ${errorMessage}`,
      kind: 'danger',
      dismissAfter: 4000,
    }),
  );
};

export const requestStatementForDealers = (dealerIdList = [], ccSalesRepList = []) => async (
  dispatch,
  getState,
) => {
  try {
    if (dealerIdList.length) {
      const {
        email: repEmail,
        salesRepInfo: { salesRepId },
      } = get(getState(), 'auth.user', {});
      const dealerStatementRequests = dealerIdList.map(dealerId => ({
        dealerId,
        statementRequested: true,
        copyToDealer: ccSalesRepList.includes(dealerId),
      }));
      const params = {
        salesRepId,
        repEmail,
        dealerList: dealerStatementRequests,
      };
      const { data } = await api.post('/actions/sales-reps/statements', params);
      if (data) {
        dispatch(
          notifSend({
            message: `Statements emails requested. Request ID: ${data.repPortalRequestId}, Status: ${data.stmRequestStatus}`,
            kind: 'info',
            dismissAfter: 10000,
          }),
        );
        dispatch(clearDealerCcLlist());
      } else {
        dispatch(
          notifSend({
            message: 'Something happened when submitted statement email request',
            kind: 'info',
            dismissAfter: 4000,
          }),
        );
      }
    } else {
      dispatch(
        notifSend({
          message: 'No dealer Ids were provided to send statements to',
          kind: 'warning',
          dismissAfter: 4000,
        }),
      );
    }
  } catch (err) {
    handleRequestStatementError(err, dispatch);
  }
};

export const requestImmediateStatementForOneDealer = dealerId => async (dispatch, getState) => {
  const {
    email: repEmail,
    salesRepInfo: { salesRepId },
  } = get(getState(), 'auth.user', {});
  const params = {
    salesRepId,
    repEmail,
    sendToDealerOnly: true,
    dealerList: [
      {
        dealerId,
        statementRequested: true,
        copyToDealer: true,
      },
    ],
  };
  try {
    const { data } = await api.post('/actions/sales-reps/statements', params);
    if (data) {
      dispatch(
        notifSend({
          message: `Statements email requested. Request ID: ${data.repPortalRequestId}, Status: ${data.stmRequestStatus}`,
          kind: 'info',
          dismissAfter: 5000,
        }),
      );
    }
  } catch (err) {
    handleRequestStatementError(err, dispatch);
  }
};

const initialState = {
  selectedDealers: [],
  ccSalesRepList: [],
  showConfirmStatementSendModal: false,
  showDealerDetailsModal: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECT_DEALER_FOR_STATEMENT:
      return {
        ...state,
        selectedDealers: payload.isSelect
          ? [...state.selectedDealers, payload.id]
          : state.selectedDealers.filter(dealerId => dealerId !== payload.id),
      };
    case SELECT_ALL_DEALERS_FOR_STATEMENT:
      return {
        ...state,
        selectedDealers: payload.isSelect ? payload.ids : [],
      };
    case TOGGLE_CONFIRM_STATEMENT_SEND_MODAL:
      return {
        ...state,
        showConfirmStatementSendModal: payload,
      };
    case TOGGLE_DEALER_DETAILS_MODAL:
      return {
        ...state,
        // NOTE - We also use this field to pass the ID up to the modal.
        showDealerDetailsModal: payload,
      };
    case SELECT_DEALER_FOR_CC_ON_STATEMENT:
      return {
        ...state,
        ccSalesRepList: payload.isSelect
          ? [...state.ccSalesRepList, payload.id]
          : state.ccSalesRepList.filter(dealerId => dealerId !== payload.id),
      };
    case CLEAR_DEALER_CC_LLIST:
      return {
        ...state,
        ccSalesRepList: [],
      };
    default:
      return state;
  }
};
