import { get } from 'lodash';
import api from '../../utils/service';
import { notifSend } from './notifs';

/**
 * @typedef DealerDetail {{
 *   detailId: number,
 *   repPortalRequestId: number,
 *   dlid: number,
 *   dlCompany: string,
 *   dlOwner: string,
 *   dlAddress: string,
 *   dlCityStzip: string,
 *   dlEmail: string,
 *   dlPhone: string,
 *   dlFax: string,
 *   vscEff: string,
 *   vscExp: string,
 *   gapEff: string,
 *   gapExp: string,
 *   statementRequested: boolean,
 *   pdfCreated: boolean,
 *   emailSent: boolean,
 *   processDate: string,
 *   copyToDealer: boolean,
 *   myUuid: string,
 *   vscPack: number,
 *   gapPack: number,
 *   gapbhph: boolean,
 *   gapPlus: boolean,
 *   gaprsvAmt: number,
 *   gapncb: boolean,
 *   vscrsvAmt: number,
 *   vscncb: boolean
 * }}
 * */

export const GET_SALES_REPS_DEALER_LIST = 'GET_SALES_REPS_DEALER_LIST';
export const getSalesRepsDealerList = salesRepId => async (dispatch, getState) => {
  try {
    const { email, salesRepRel } = salesRepId || get(getState(), 'auth.user', {});
    const params = { repEmail: email };
    if (salesRepRel && salesRepRel.salesRepId) {
      const { data } = await api.get(`/sales-reps/${salesRepRel.salesRepId}/dealers`, {
        params,
        headers: {
          'Cache-Control': 'no-cache, no-store, max-age=0',
        },
      });
      if (data.dealers && data.dealers.length > 0) {
        dispatch({ type: GET_SALES_REPS_DEALER_LIST, payload: data.dealers });
      } else {
        dispatch(
          notifSend({
            message: `No Dealers were returned for REP ID: ${salesRepRel.salesRepId}`,
            kind: 'info',
            dismissAfter: 4000,
          }),
        );
      }
    } else {
      dispatch(
        notifSend({
          message: `No REP ID attached to account: ${email}`,
          kind: 'warning',
          dismissAfter: 5000,
        }),
      );
    }
  } catch (err) {
    const { response } = err;
    dispatch(
      notifSend({
        message: `ERROR: ${response.data.message || err.toString()}`,
        kind: 'danger',
        dismissAfter: 5000,
      }),
    );
  }
};

const initialState = {
  dealers: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SALES_REPS_DEALER_LIST:
      return {
        ...state,
        dealers: payload,
      };
    default:
      return state;
  }
};
