import { getStore } from './getStore';

const START_LOADING = 'redux/page/START_LOADING';
const STOP_LOADING = 'redux/page/STOP_LOADING';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export function startLoading() {
  return {
    type: START_LOADING,
  };
}

export function stopLoading() {
  return {
    type: STOP_LOADING,
  };
}

export function loadingCycle(promise) {
  getStore().dispatch(startLoading());
  return promise
    .then(response => {
      getStore().dispatch(stopLoading());
      return response;
    })
    .catch(err => {
      getStore().dispatch(stopLoading());
      return Promise.reject(err);
    });
}
