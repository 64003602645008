import React from 'react';
import { startCase } from 'lodash';
import { Breadcrumb } from 'react-bootstrap';

export default ({ items = [], onClick, activePage }) => (
  <Breadcrumb>
    {items.map(({ page }) => (
      <Breadcrumb.Item key={page} active={activePage === page} onClick={() => onClick(page)}>
        {startCase(page)}
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);
