import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, Field, propTypes, change, getFormValues } from 'redux-form';
import { Alert } from 'react-bootstrap';
import { NEW_USER_FORM } from '../redux/modules/form';
import newUserValidation from './validation/newUserValidation';
import ValidationInput from './common/ValidationInput';
import ValidationCheckbox from './common/ValidationCheckbox';
import { exactLength, minLength, required } from '../utils/validation';
import { validatePasswordStrength, getPasswordRequirements } from '../utils/password';

const PasswordRequirementsAlert = ({ requirements }) => {
  if (!requirements || requirements.length === 0) return null;

  return (
    <Alert bsStyle="warning">
      Password should meet these requirements:
      <ul>
        {requirements.map(item => (
          <li>{_.capitalize(item)}</li>
        ))}
      </ul>
    </Alert>
  );
};

const NewUserFormPasswordRequirementsAlert = connect(state => {
  const values = getFormValues(NEW_USER_FORM)(state) || {};
  const password = values.password;
  const requirements = password ? getPasswordRequirements(password) : [];
  return {
    requirements,
  };
})(PasswordRequirementsAlert);

export class NewUserForm extends Component {
  static propTypes = {
    ...propTypes,
    user: PropTypes.object,
    currentUser: PropTypes.object.isRequired,
  };

  static defaultProps = {
    user: null,
  };

  componentWillReceiveProps(nextProps) {
    const { dispatch } = nextProps;
    if (!_.isEmpty(nextProps.user) && _.get(this.props.user, 'userId') !== nextProps.user.userId) {
      const { email, salesRepRel, salesRepInfo } = nextProps.user;
      dispatch(change(NEW_USER_FORM, 'email', email));
      dispatch(change(NEW_USER_FORM, 'salesRepId', _.get(salesRepRel, 'salesRepId')));
      dispatch(change(NEW_USER_FORM, 'prcoAdmin', _.get(salesRepRel, 'prcoAdmin', false)));
      dispatch(change(NEW_USER_FORM, 'firstName', _.get(salesRepInfo, 'firstName')));
      dispatch(change(NEW_USER_FORM, 'lastName', _.get(salesRepInfo, 'lastName')));
    }
  }

  render() {
    const { handleSubmit, error, user, currentUser } = this.props;
    const isPrcoAdmin = _.get(currentUser, 'salesRepRel.prcoAdmin');
    const isUpdate = !!_.get(user, 'userId');

    return (
      <form className="form-horizontal" onSubmit={handleSubmit}>
        <legend>{isUpdate ? 'Update User' : 'Create User'}</legend>
        <Field
          name="email"
          type="text"
          component={ValidationInput}
          label="Email"
          placeholder="eg. george.clinton@autoworld.com"
        />
        <Field
          name="password"
          type="text"
          component={ValidationInput}
          label="Password"
          placeholder="eg. ********"
          validate={
            !isUpdate
              ? [required, minLength(8), validatePasswordStrength]
              : [minLength(8), validatePasswordStrength]
          }
        />
        <NewUserFormPasswordRequirementsAlert />
        <Field
          name="firstName"
          type="text"
          component={ValidationInput}
          label="First Name"
          placeholder="John"
          validate={!isUpdate ? [required] : undefined}
        />
        <Field
          name="lastName"
          type="text"
          component={ValidationInput}
          label="Last Name"
          placeholder="Gibson"
          validate={!isUpdate ? [required] : undefined}
        />
        {isPrcoAdmin && (
          <Field
            name="salesRepId"
            type="text"
            component={ValidationInput}
            label="Sales Rep ID (3 Digit)"
            placeholder="eg. 571"
            validate={[required, exactLength(3)]}
          />
        )}
        {isPrcoAdmin && (
          <Field name="prcoAdmin" component={ValidationCheckbox} label="PRco Admin" />
        )}
        {error && (
          <p className="text-danger">
            <strong>{error}</strong>
          </p>
        )}
        <div className="row">
          <div className="col-md-3 col-xs-12">
            <button className="btn btn-primary btn-block" type="submit">
              {isUpdate ? 'Update User' : 'Create User'}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({ form: NEW_USER_FORM, validate: newUserValidation })(NewUserForm);
