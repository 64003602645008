import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const ValidationSelect = ({
  input,
  label,
  placeholder,
  options,
  disabled,
  meta: { touched, error },
}) => (
  <div className={`form-group ${error && touched ? 'has-error' : ''}`}>
    <label htmlFor={input.name} className="col-sm-3">
      {label || ''}
    </label>
    <div className="col-sm-9">
      <select
        {...input}
        onChange={({ target }) => {
          // note: option converts bool to string, we fix that here
          const { value } = target;
          if (value === 'true' || value === 'false') {
            input.onChange(value === 'true');
          } else {
            input.onChange(value);
          }
          // call this to ensure the value is updated on redux-form state
          input.onBlur(undefined);
        }}
        // redux-forms bug: https://github.com/redux-form/redux-form/issues/2768*
        onBlur={() => input.onBlur(undefined)}
        id={input.name}
        disabled={disabled}
        className="form-control"
      >
        {placeholder && (
          <option value="" disabled={true}>
            {placeholder}
          </option>
        )}
        {_.map(options, o => {
          if (_.isObject(o)) {
            const value = o._id || o.value;
            return (
              <option value={value} key={value || o.label}>
                {o._id || o.label}
              </option>
            );
          } else {
            return (
              <option value={o} key={o}>
                {o}
              </option>
            );
          }
        })}
      </select>
      {error && touched && <span className="glyphicon glyphicon-remove form-control-feedback" />}
      {error && touched && (
        <div className="text-danger">
          <strong>{error}</strong>
        </div>
      )}
    </div>
  </div>
);

ValidationSelect.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
};

ValidationSelect.defaultProps = {
  input: {},
  label: '',
};

export default ValidationSelect;
