import React from 'react';
import { Button, Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useApi } from '../../utils/service';
import { notifSend } from '../../redux/modules/notifs';
import IssuedContractsTable from './IssuedContractsTable';
import IssuedContractsSearch from './IssuedContractsSearch';
import SalesRepSelect from './SalesRepSelect';
import useDebounce from '../../hooks/useDebounce';

const useProcessContracts = ({ sendMessage }) => {
  const api = useApi();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const processIssuedContracts = async contract => {
    try {
      setError(null);
      const BASE_PATH = '/actions/issued-contracts/collect-contracts';
      const response = await api.post(
        contract != null ? `${BASE_PATH}/${contract._id}` : BASE_PATH,
      );
      if (response.data.packages.length === 0) {
        sendMessage({
          message: `No updates for ${contract.name}`,
          kind: 'info',
          dismissAfter: 5000,
        });
      }
      response.data.packages.forEach(pkg => {
        sendMessage({
          id: pkg.issuedContract._id,
          message: `Contract ${
            pkg.issuedContract.name
          } now has the following checkpoints completed: ${pkg.issuedContract.packageStatus.join(
            ', ',
          )}`,
          kind: 'info',
          dismissAfter: 5000,
        });
      });

      setData(response.data.packages);
      return response.data.packages;
    } catch (error) {
      sendMessage({
        message: `Unable to process pending contracts, reason: ${error.toString()}`,
        kind: 'danger',
        dismissAfter: 5000,
      });
      setData(null);
      setError(error);
    }
  };

  return { data, error, processIssuedContracts };
};

const useStoreSearchText = () => {
  const [searchText, setSearchText] = React.useState(null);
  const value = useDebounce(searchText);
  return [value, setSearchText];
};

const TableHeader = ({ onProcessAll, onSearch }) => {
  const [searchText, setSearchText] = useStoreSearchText();
  const hasSearch = searchText != null && searchText.length > 2;
  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        e.preventDefault();
        onSearch({ searchText });
      }}
    >
      <Row>
        <Col xs={12} md={7}>
          <IssuedContractsSearch
            style={{ display: 'inline-block' }}
            onChange={e => setSearchText(e.target.value)}
          />
          <SalesRepSelect
            onSelect={salesRepMongoId => {
              if (salesRepMongoId) {
                return onSearch({ salesRepMongoId });
              }
              // just use default if selection is cleared
              onSearch({ pendingOnly: true });
            }}
          />
        </Col>

        <Col xs={12} md={5} className="issued-contracts-btns">
          <Button
            type={hasSearch ? 'submit' : 'button'}
            style={{ display: 'inline-block' }}
            bsStyle="primary"
            disabled={!hasSearch}
          >
            Search
          </Button>
          <Button
            type={hasSearch ? 'button' : 'submit'}
            bsStyle="primary"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onSearch({ pendingOnly: true });
            }}
          >
            Get Pending
          </Button>
          <Button bsStyle="primary" onClick={() => onProcessAll()}>
            Process All Pending Contracts
          </Button>
        </Col>
      </Row>
    </form>
  );
};

function IssuedContracts({ sendMessage }) {
  const { processIssuedContracts } = useProcessContracts({ sendMessage });

  return (
    <Grid>
      <IssuedContractsTable
        onClick={contract => processIssuedContracts(contract)}
        renderHeader={({ onRefresh }) => (
          <TableHeader onProcessAll={processIssuedContracts} onSearch={onRefresh} />
        )}
      />
    </Grid>
  );
}

export default connect(null, { sendMessage: notifSend })(IssuedContracts);
