import React from 'react';
import { connect } from 'react-redux';
import { InjectedRouter } from 'react-router';
import NewDealerForm from './forms/NewDealer/NewDealerForm';
import { createNewDealer } from '../redux/modules/dealer';
import NotificationModal from '../components/common/NotificationModal';
import { FinalDealerInput } from '../services/dealerService/hooks';
import { Grid } from 'react-bootstrap'

interface NewDealerViewProps {
  createNewDealer(newDealer: FinalDealerInput): Promise<unknown>;
  router: InjectedRouter
}

function NewDealerView({ createNewDealer, router }: NewDealerViewProps): React.ReactElement {
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState<boolean>(false);
  const [newDealer, setNewDealer] = React.useState<FinalDealerInput>();
  const [newDealerInProgress, setNewDealerInProgress] = React.useState<boolean>(false);

  const handleSubmit = async (formValues: FinalDealerInput) => {
    if (!newDealerInProgress) {
      setNewDealerInProgress(true);
      setNewDealer(formValues);
      await createNewDealer(formValues);
      setNewDealerInProgress(false);
      setShowConfirmationDialog(true);
    }
  };

  return (
    <Grid>
      <NewDealerForm onSubmit={handleSubmit} />
      <NotificationModal
        show={showConfirmationDialog}
        title={`${newDealer?.dealershipName || ''} Submitted`}
        confirmText={'Continue to Dashboard'}
        // @ts-ignore
        message={
          <div>
            Forms will be sent via email from One Span Sign for signatures
            <div>
              <ul>
                <li>
                  Dealer Contact: {newDealer?.documentSigner} ({newDealer?.dealerContactEmail})
                </li>
                <li>
                  Dealer Owner: {newDealer?.ownerFirstName} {newDealer?.ownerLastName} (
                  {newDealer?.ownerEmail})
                </li>
              </ul>
            </div>
          </div>
        }
        onConfirm={() => {
          router.push('/');
        }}
      />
    </Grid>
  );
}

export default connect(null, { createNewDealer })(NewDealerView);
